/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, {
  memo, useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LinearProgress, useMediaQuery } from '@mui/material';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import styles from './Checklist.module.css';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import ListChecklistItems from './ListChecklistItems/ListChecklistItems';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import { ChecklistItemConstants } from '../../../../constants';
import DroppableChecklist from '../DroppableChecklist/DroppableChecklist';
import { useDelayShowHideHandler } from '../../../../hooks/HelperHooks';
import CreateChecklistItem from '../../CreateChecklistItem/CreateChecklistItem';
import EditChecklistName from './EditChecklistName/EditChecklistName';
import { FeedbackSpinnerGeneral } from '../../../../components/UI_V2/Feedback/Spinner';
import { checkIfUserAuthorizedToModifyCard } from '../../../../actions/UserActions';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';

const dragStartStyles = {
  true: styles.dragStartTrue,
  false: styles.dragStartFalse,
};

const Checklist = ({
  checklist, index,
  allowedToExpandChecklistItems,
  setAllowedToExpandChecklistItems,
  cardId,
  teamId,
  companyId,
  handleDeleteChecklist,
  handleDeleteChecklistItem,
  movedChecklistId,
  movedChecklistItemId,
}) => {
  const [{
    user, currentCard, currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const [showChecklistItems, setShowChecklistItems] = useState(true);
  const [isDragged, setIsDragged] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [
    showCreateChecklistItem,
    handleShowCreateChecklistItem,
    handleHideCreateChecklistItem,
  ] = useDelayShowHideHandler();
  const [
    showEditChecklistName,
    handleShowEditChecklistName,
    handleHideEditChecklistName,
  ] = useDelayShowHideHandler();

  const ref = useRef(null);

  useEffect(() => {
    const el = ref?.current;
    if (!el) return () => null;

    return draggable({
      element: el,
      getInitialData: () => ({
        index,
        draggedChecklistId: checklist._id,
        type: 'checklistTop',
      }),
      onDragStart: () => {
        setIsDragged(true);
        setAllowedToExpandChecklistItems(false);
      },
      onDrop: () => {
        setIsDragged(false);
        setTimeout(() => {
          setAllowedToExpandChecklistItems(true);
        }, 1000);
      },
    });
  }, [ref, checklist?._id]);

  const ref2 = useRef(null);

  useEffect(() => {
    const el = ref2?.current;
    if (!el) return () => null;

    return draggable({
      element: el,
      getInitialData: () => ({
        index,
        draggedChecklistId: checklist._id,
        type: 'checklistBottom',
      }),
    });
  }, [ref2, checklist?._id]);

  // /* Initiate list data */
  const initialStatePreviousChecklistItems = {
    data: new Array(ChecklistItemConstants.limitChecklistItem),
  };
  // const initialStateCurrentChecklistItems = {
  //   data: checklist.checklistItems || [],
  // };
  const [previousChecklistItems,
    setPreviousChecklistItems] = useState(initialStatePreviousChecklistItems);
  // const [currentChecklistItems,
  //   setCurrentChecklistItems] = useState(initialStateCurrentChecklistItems);

  const isChecklistItemsCountFieldExist = checklist.checklistItemsCount?.completed !== undefined;
  const isChecklistItemsCountCompletedZero = checklist.checklistItemsCount?.completed === 0;
  const progress = (isChecklistItemsCountFieldExist && !isChecklistItemsCountCompletedZero)
    ? Math.round(
      (checklist.checklistItemsCount?.completed / checklist.checklistItemsCount?.total) * 100,
    )
    : 0;

  const allowedShowChecklistItems = showChecklistItems && allowedToExpandChecklistItems;

  const isChecklistLoading = movedChecklistId === checklist._id;
  return (
    <div
      className={`${styles.container} ${dragStartStyles[isDragged]}`}
    >
      {isChecklistLoading && (
      <>
        <div className={styles.overlayBackground} />
        <div className={styles.overlayContent}>
          <FeedbackSpinnerGeneral color={Color.yellowAccentCicle} />
        </div>
      </>
      )}
      <DroppableChecklist
        index={index}
        checklistId={checklist._id}
        droppableType={allowedShowChecklistItems ? 'checklistTop' : 'checklistBottom'}
      >
        <div
          className={styles.header}
          ref={ref}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className={styles.iconAndTitle}>
            <div className={styles.icon}>
              <CheckCircleOutlineIcon />
            </div>
            {!showEditChecklistName && (
            <div className={styles.title}>
              <DisplayTextSubHeadline mode="16">
                {checklist.name}
              </DisplayTextSubHeadline>
            </div>
            )}
            {showEditChecklistName && (
            <EditChecklistName
              cardId={cardId}
              teamId={teamId}
              checklistId={checklist._id}
              companyId={companyId}
              onClose={handleHideEditChecklistName}
              initialChecklistName={checklist.name}
            />
            )}
          </div>
          {isHovered && checkIfUserAuthorizedToModifyCard(currentCard, user,
            currentRoleUser) && (
            <div className={styles.action}>
              <div
                className={styles.iconAction}
                onClick={
              allowedShowChecklistItems
                ? () => setShowChecklistItems(false)
                : () => setShowChecklistItems(true)
}
              >
                {allowedShowChecklistItems && <ExpandMoreIcon />}
                {!allowedShowChecklistItems && <ExpandLessIcon />}
              </div>
              <div className={styles.iconAction} onClick={handleShowEditChecklistName}>
                <EditIcon />
              </div>
              <div
                className={styles.iconAction}
                onClick={() => {
                  handleDeleteChecklist(checklist._id);
                }}
              >
                <DeleteIcon />
              </div>
            </div>
          )}
        </div>
        <div className={styles.progressBar}>
          <div className={styles.textProgressBar}>
            <DisplayTextBody>{`${progress}%`}</DisplayTextBody>
          </div>
          <div className={styles.barProgressBar}>
            <LinearProgress variant="determinate" value={progress} color={progress === 100 ? 'success' : 'primary'} />
          </div>
        </div>
      </DroppableChecklist>
      {allowedShowChecklistItems && (
      // <div className={styles.body}>
        <ListChecklistItems
          currentChecklistItems={{ data: checklist.checklistItems }}
          previousChecklistItems={previousChecklistItems}
          checklistItemsCountTotal={checklist?.checklistItemsCount?.total}
          // setCurrentChecklistItems={setCurrentChecklistItems}
          setPreviousChecklistItems={setPreviousChecklistItems}
          // initialStateCurrentChecklistItems={initialStateCurrentChecklistItems}
          initialStatePreviousChecklistItems={initialStatePreviousChecklistItems}
          cardId={checklist.card}
          checklistId={checklist._id}
          handleDeleteChecklistItem={handleDeleteChecklistItem}
          movedChecklistItemId={movedChecklistItemId}
        />
      // </div>
      )}
      {allowedShowChecklistItems && (
        <>
          {showCreateChecklistItem && (
          <CreateChecklistItem
            cardId={cardId}
            teamId={teamId}
            companyId={companyId}
            checklistId={checklist._id}
            onClose={handleHideCreateChecklistItem}
          />
          )}
          {!showCreateChecklistItem && (
          <DroppableChecklist
            index={index}
            checklistId={checklist._id}
            droppableType="checklistBottom"
          >
            <div ref={ref2} className={styles.addButton}>
              {checkIfUserAuthorizedToModifyCard(currentCard, user,
                currentRoleUser) && (
                <InputButtonMain
                  handleClick={handleShowCreateChecklistItem}
                  size="sm"
                  variant="grayLight"
                >
                  Tambah Sub Tugas
                </InputButtonMain>
              )}
            </div>
          </DroppableChecklist>
          )}
        </>
      )}
    </div>
  );
};

Checklist.propTypes = {
  checklist: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  allowedToExpandChecklistItems: PropTypes.bool,
  setAllowedToExpandChecklistItems: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  handleDeleteChecklist: PropTypes.func.isRequired,
  handleDeleteChecklistItem: PropTypes.func.isRequired,
  movedChecklistId: PropTypes.string.isRequired,
  movedChecklistItemId: PropTypes.string.isRequired,
};

Checklist.defaultProps = {
  allowedToExpandChecklistItems: true,
};

export default memo(Checklist);
