import { cloneDeep, isEmpty } from 'lodash';
import axios from 'axios';
import { ApiConstants, CompanyConstants } from '../constants';
import { actionTypes } from '../reducers/reducer';
import apiUtil from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';
import { getSessionLastOpenedCompany, setSessionLastOpenedCompany, setSessionWelcomeUserPopUp } from '../utilities/sessionStorage';
import {
  setStoreLastOpenedCompany, setStoreCompanies, setStoreWelcomeUserPopUp,
} from '../utilities/localStorage';
import { adjustServerUrl } from '../utilities/stringUtil';
import { updateListSocket } from '../utilities/arrayUtil';
import { handleAxiosDeleteRefreshToken } from './CheckLoginActions';

/*
  Dispatcher
*/

function dispatchCurrentCompany({ currentCompany }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_COMPANY,
    currentCompany,
  });
}

function dispatchCurrentCompanies({ currentCompanies }, dispatch) {
  dispatch({
    type: actionTypes.SET_COMPANIES,
    currentCompanies,
  });
}

function dispatchUpdateCurrentCompanies({ updateCurrentCompanies }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_COMPANIES,
    updateCurrentCompanies,
  });
}

function dispatchUpdateCurrentCompany({ updateCurrentCompany }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_COMPANY,
    updateCurrentCompany,
  });
}

/*
  SetterDispatcher
*/

function setCurrentCompany({ currentCompany }, dispatch) {
  if (!currentCompany) return;
  dispatchCurrentCompany({ currentCompany: cloneDeep(currentCompany) }, dispatch);
}

function setCurrentCompanies({ currentCompanies }, dispatch) {
  if (!currentCompanies) return;
  dispatchCurrentCompanies({ currentCompanies: cloneDeep(currentCompanies) }, dispatch);
}

/*
  Method
*/

async function getCompany({ companyId }) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.COMPANY({ companyId }));

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getCompanies() {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.COMPANIES());

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const extractCurrentCompanyFromCompanies = async ({ companyId, companies }) => {
  try {
    // no need to get from API, because companies has already provided companywithteam
    const currentCompany = companies.find((company) => company._id === companyId);
    return currentCompany;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const saveCompanyAndLastOpenedCompany = ({ currentCompany, userId }, dispatch) => {
  try {
    setCurrentCompany({ currentCompany }, dispatch);
    setSessionLastOpenedCompany({ company: currentCompany, userId });
    setStoreLastOpenedCompany({ company: currentCompany, userId });
  } catch (error) {
    throw new ErrorException(error);
  }
};

const saveCompanies = ({ currentCompanies }, dispatch) => {
  try {
    setCurrentCompanies({ currentCompanies }, dispatch);
    setStoreCompanies(currentCompanies);
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateCompanies = async ({}, dispatch) => {
  try {
    // VERSION LOCAL STORAGE BUT NEED ADDITIONAL SOCKET
    // let currentCompanies = getStoreCompanies();
    // if (!currentCompanies || currentCompanies?.length < 1) {
    //   const result = await getCompanies();
    //   currentCompanies = result?.data?.companies;
    // }
    const result = await getCompanies();
    const currentCompanies = result?.data?.companies;
    setCurrentCompanies({ currentCompanies }, dispatch);
    setStoreCompanies(currentCompanies);
    return currentCompanies;
  } catch (error) {
    throw new ErrorException(error);
  }
};

// eslint-disable-next-line max-len
const getAndSaveCompanyAndLastOpenedCompany = async ({ companyId, companies, userId }, dispatch) => {
  try {
    if (!companyId || !companies) {
      const emptyCompany = {
        members: [],
      };
      setCurrentCompany({ currentCompany: emptyCompany }, dispatch);
      return emptyCompany;
    }

    const currentCompany = await extractCurrentCompanyFromCompanies(
      { companyId, companies },
    );
    // VERSION LOCAL STORAGE, BUT NEED ADDITIONAL SOCKET
    // let currentCompany = getSessionLastOpenedCompany();
    // if (!currentCompany) currentCompany = getStoreLastOpenedCompany();

    // let result;
    // if (!currentCompany
    //   || currentCompany?._id !== companyId
    //   || (currentCompany?._id === companyId && teams?.length < 1)
    // ) {
    //   result = await getCurrentCompany({ companyId });
    //   currentCompany = result?.data?.company;
    // }

    setCurrentCompany({ currentCompany }, dispatch);
    setSessionLastOpenedCompany({ company: currentCompany, userId });
    setStoreLastOpenedCompany({ company: currentCompany, userId });
    return currentCompany;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const updateCompanyByKeyObjectThenUpdateCompanies = ({
  data, keyObjectProperty, currentCompany, userId,
}, dispatch) => {
  try {
    const newCompany = {
      ...currentCompany,
      [keyObjectProperty]: data,
    };

    saveCompanyAndLastOpenedCompany({ currentCompany: newCompany, userId }, dispatch);

    const updateCurrentCompanies = (currentCompanies) => {
      const newCompanies = updateListSocket({
        newData: newCompany,
        currentList: currentCompanies,
        typeAction: CompanyConstants.typeCallback.EDIT,
      });
      return cloneDeep(newCompanies);
    };

    dispatchUpdateCurrentCompanies({ updateCurrentCompanies }, dispatch);
  } catch (error) {
    throw new ErrorException(error);
  }
};

const createCompanyAndUpdateCompanies = async ({ payload }, dispatch) => {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.COMPANIES(), payload);

    const updateCurrentCompanies = (currentCompanies) => {
      const newCompanies = [...currentCompanies];
      newCompanies.push(result?.data?.newCompany);
      setStoreCompanies(newCompanies);
      return newCompanies;
    };

    dispatchUpdateCurrentCompanies({ updateCurrentCompanies }, dispatch);
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const deleteCompanyAndUpdateCompanies = async ({ companyId }, dispatch) => {
  try {
    // ada bug di apiUtil delete, jadi masih pake axios
    // ada bug kalau tidak disebut ulang localstorage.get item nya jadi kena null
    const result = await axios.delete(
      `${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.COMPANY({ companyId })}`,
      {
        withCredentials: true,
        headers: {
          Authorization: `jwt ${localStorage.getItem('token')}`,
        },
      },
    );

    const updateCurrentCompanies = (currentCompanies) => {
      const newCompanies = currentCompanies.filter((company) => company._id !== companyId);
      setStoreCompanies(newCompanies);
      return newCompanies;
    };

    dispatchUpdateCurrentCompanies({ updateCurrentCompanies }, dispatch);
    return result;
  } catch (error) {
    const newAuthToken = await handleAxiosDeleteRefreshToken(error);
    if (newAuthToken) {
      try {
        const result = await axios.delete(
          `${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.COMPANY({ companyId })}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `jwt ${newAuthToken}`,
            },
          },
        );

        const updateCurrentCompanies = (currentCompanies) => {
          const newCompanies = currentCompanies.filter((company) => company._id !== companyId);
          setStoreCompanies(newCompanies);
          return newCompanies;
        };

        dispatchUpdateCurrentCompanies({ updateCurrentCompanies }, dispatch);
        return result;
      } catch (err) {
        throw new ErrorException(err);
      }
    }
    throw new ErrorException(error);
  }
};

const deleteCompanyMemberAndUpdateCompanies = async (
  { companyId, memberId, userId },
  dispatch,
) => {
  try {
    const result = await axios.delete(
      `${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.COMPANY_MEMBER({ companyId, memberId })}`,
      {
        withCredentials: true,
        headers: {
          Authorization: `jwt ${localStorage.getItem('token')}`,
        },
      },
    );

    const updateCurrentCompany = (currentCompany) => {
      const withRemovedMembers = currentCompany.members.filter((member) => member._id !== memberId);

      const newCompany = {
        ...currentCompany,
        members: withRemovedMembers,
      };
      setStoreLastOpenedCompany({ company: newCompany, userId });
      setSessionLastOpenedCompany({ company: newCompany, userId });
      return newCompany;
    };

    dispatchUpdateCurrentCompany({ updateCurrentCompany }, dispatch);
    saveCompanies({ currentCompanies: result?.data?.companies }, dispatch);
    return result;
  } catch (error) {
    const newAuthToken = await handleAxiosDeleteRefreshToken(error);
    if (newAuthToken) {
      try {
        const result = await axios.delete(
          `${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.COMPANY_MEMBER({ companyId, memberId })}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `jwt ${newAuthToken}`,
            },
          },
        );

        const updateCurrentCompany = (currentCompany) => {
          const withRemovedMembers = currentCompany.members.filter(
            (member) => member._id !== memberId,
          );

          const newCompany = {
            ...currentCompany,
            members: withRemovedMembers,
          };
          setStoreLastOpenedCompany({ company: newCompany, userId });
          setSessionLastOpenedCompany({ company: newCompany, userId });
          return newCompany;
        };

        dispatchUpdateCurrentCompany({ updateCurrentCompany }, dispatch);
        saveCompanies({ currentCompanies: result?.data?.companies }, dispatch);
        return result;
      } catch (err) {
        throw new ErrorException(err);
      }
    }
    throw new ErrorException(error);
  }
};

const uploadLogoAndUpdateCompanies = async ({ companyId, payload, userId }, dispatch) => {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.COMPANY_LOGO({ companyId }),
      payload,
    );

    const updateCurrentCompany = (currentCompany) => {
      const logo = adjustServerUrl(result?.data?.logo);
      const newCompany = {
        ...currentCompany,
        logo,
      };
      setStoreLastOpenedCompany({ company: newCompany, userId });
      setSessionLastOpenedCompany({ company: newCompany, userId });
      return newCompany;
    };

    dispatchUpdateCurrentCompany({ updateCurrentCompany }, dispatch);
    saveCompanies({ currentCompanies: result?.data?.companies }, dispatch);
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const modifyShowWelcomeTutorialAndTrialMessage = ({
  userId, companyId, typeAction = CompanyConstants.typeCallback.EDIT,
}) => {
  try {
    if (typeAction === CompanyConstants.typeCallback.EDIT) {
      const valueStore = {
        welcomeTutorial: true,
      };

      setStoreWelcomeUserPopUp({ userId, companyId, value: valueStore });
      setSessionWelcomeUserPopUp({
        userId,
        companyId,
        value: {
          ...valueStore,
          isOpen: true,
        },
      });
    }

    if (typeAction === CompanyConstants.typeCallback.DELETE) {
      localStorage.removeItem(`welcomeUserPopUp-${userId}-${companyId}`);
      window.sessionStorage.removeItem(`welcomeUserPopUp-${userId}-${companyId}`);
    }
  } catch (error) {
    throw new ErrorException(error);
  }
};

/*
  Socket Callback Stuffs
*/

const incomingCompanyUpdate = ({ item, typeAction, typeOverview }, dispatch) => {
  // not handled yet;
};

const incomingCompanyUserUpdate = ({ item, typeAction, typeOverview }, dispatch) => {
  // not handled yet;
};

const incomingCompanyTeamUpdate = ({ item, typeAction, typeOverview }, dispatch) => {
  // not handled yet;
};

export {
  setCurrentCompany,
  initiateCompanies,
  saveCompanies,
  saveCompanyAndLastOpenedCompany,
  getAndSaveCompanyAndLastOpenedCompany,
  createCompanyAndUpdateCompanies,
  deleteCompanyAndUpdateCompanies,
  deleteCompanyMemberAndUpdateCompanies,
  uploadLogoAndUpdateCompanies,
  updateCompanyByKeyObjectThenUpdateCompanies,
  modifyShowWelcomeTutorialAndTrialMessage,
};
