import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CardLabels.module.css';
import Label from '../../../components/UI/Label/Label';
import ManageLabelsContainer from '../../ManageLabelsContainer/ManageLabelsContainer';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { checkIfUserAuthorizedToModifyCard } from '../../../actions/UserActions';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';

const CardLabels = ({ card }) => {
  const [{ user, currentRoleUser }] = useContext(GlobalContext);
  const [showManageLabels,
    handleShowManageLabels, handleHideManageLabels] = useDelayShowHideHandler();

  const handleClicked = () => {
    handleShowManageLabels();
  };

  const handleCancel = () => {
    handleHideManageLabels();
  };

  return (
    <>
      {card.labels !== undefined && card.labels.length > 0
        ? <p className={styles.CardLabels__smallTitle}>LABEL</p>
        : null}

      {card.labels !== undefined ? (
        <>
          {card.labels.map((label) => (

            <Label
              clicked={checkIfUserAuthorizedToModifyCard(card, user, currentRoleUser)
                ? () => handleClicked()
                : () => null}
              color={label.color?.name}
              name={label.name}
            />

          ))}
          {showManageLabels
            ? <ManageLabelsContainer cancel={handleCancel} left card={card} />
            : null}
        </>
      ) : null}

    </>
  );
};

CardLabels.propTypes = {
  card: PropTypes.object.isRequired,
};

export default CardLabels;
