import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TextField, useMediaQuery } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import styles from './WithDueDateSection.module.css';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../UI_V2/Input/Button';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';

const WithDueDateSection = ({
  dueDate,
  handleSetDueDate,
  handleHideDueDateSection,
  startDate,
  handleSetStartDate,
}) => {
  const [isStartChecked, setIsStartChecked] = useState(startDate);
  const handleStartDateChecked = () => {
    if (isStartChecked) {
      handleSetStartDate();
      setIsStartChecked(false);
    } else {
      setIsStartChecked(true);
      handleSetStartDate(startDate || new Date());
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.checklistAndTitle}>
          <div className={styles.checklist} onClick={handleStartDateChecked}>
            {isStartChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </div>
          <DisplayTextBody>Tanggal Mulai</DisplayTextBody>
        </div>
        {isStartChecked && (
        <>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
          >
            <DesktopDatePicker
              inputFormat="yyyy/MM/dd"
              value={startDate}
              onChange={handleSetStartDate}
              slotProps={{
                textField: {
                  variant: 'standard', // Use "standard" to remove the box outline
                  sx: {
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                    '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                    fontSize: '0.875rem', // Smaller font size
                  },
                },
              }}
            />
          </LocalizationProvider>
          <div className={styles.timePicker}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
            >
              <TimePicker
                value={startDate}
                onChange={handleSetStartDate}
                slotProps={{
                  textField: {
                    variant: 'standard', // Use "standard" to remove the box outline
                    sx: {
                      '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                      '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                      fontSize: '0.875rem', // Smaller font size
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </>
        )}
        {!isStartChecked && <div className={styles.disabledDate}><DisplayTextBody mode="16" color={Color.gray5}>YYYY/MM/DD</DisplayTextBody></div>}
      </div>
      <div className={styles.text}>
        <div className={styles.checklistAndTitle}>
          <div className={styles.checklist}>
            <CheckBoxIcon />
          </div>
          <DisplayTextBody>Tenggat</DisplayTextBody>
        </div>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
        >
          <DesktopDatePicker
            inputFormat="yyyy/MM/dd"
            value={dueDate}
            onChange={handleSetDueDate}
            slotProps={{
              textField: {
                variant: 'standard', // Use "standard" to remove the box outline
                sx: {
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                  '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                  fontSize: '0.875rem', // Smaller font size
                },
              },
            }}
          />
        </LocalizationProvider>
        <div className={styles.timePicker}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
          >
            <TimePicker
              value={dueDate}
              onChange={handleSetDueDate}
              slotProps={{
                textField: {
                  variant: 'standard', // Use "standard" to remove the box outline
                  sx: {
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                    '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                    fontSize: '0.875rem', // Smaller font size
                  },
                },
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div onClick={handleHideDueDateSection} className={styles.button}>
        <RemoveIcon />
      </div>
    </div>
  );
};

WithDueDateSection.propTypes = {
  dueDate: PropTypes.object.isRequired,
  handleSetDueDate: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  handleSetStartDate: PropTypes.func.isRequired,
  handleHideDueDateSection: PropTypes.func.isRequired,
};

WithDueDateSection.defaultProps = {};

export default memo(WithDueDateSection);
