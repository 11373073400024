import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import styles from './CompanyItem.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../../components/UI_V2/Input/Button';
import { DisplayDividerMain } from '../../../../../components/UI_V2/Display/Divider';
import BadgeMetric from './BadgeMetric/BadgeMetric';
import { limitChar } from '../../../../../utilities/stringUtil';
import TextInfo from '../../../CompanyDetailPage/CompanyInfo/DetailInfoBox/TextInfo/TextInfo';
import AdditionalInfoAcquisition from './AdditionalInfoAcquisition/AdditionalInfoAcquisition';
import AdditionalInfoActivation from './AdditionalInfoActivation/AdditionalInfoActivation';
import AdditionalInfoRetention from './AdditionalInfoRetention/AdditionalInfoRetention';
import AdditionalInfoRevenue from './AdditionalInfoRevenue/AdditionalInfoRevenue';
import AdditionalInfoChurn from './AdditionalInfoChurn/AdditionalInfoChurn';
import BadgeTrialCategory from './BadgeTrialCategory/BadgeTrialCategory';
import AdditionalInfoVisitor from './AdditionalInfoVisitor/AdditionalInfoVisitor';
import LinkNoDecor from '../../../../../components/LinkNoDecor/LinkNoDecor';

const CompanyItem = ({
  item,
  baseUrl,
}) => {
  const isVisitor = item?.category === 'visitor';
  const isAcquisition = item?.category === 'acquisition';
  const isActivation = item?.category === 'activation';
  const isRetention = item?.category === 'retention';
  const isRevenue = item?.category === 'revenue';
  const isReferral = item?.category === 'referral';
  const isChurn = item?.category === 'churn';

  const isItemOnTrial = item?.isOnTrial;
  const isVisitorButNotTrial = isVisitor && !isItemOnTrial;

  return (
    <div className={styles.container}>
      {(!isVisitor || isVisitorButNotTrial) && (
      <LinkNoDecor to={item?.company?._id && `${baseUrl}/${item.company._id}`}>
        <div className={styles.cta}>
          <OpenInNewIcon />
        </div>
      </LinkNoDecor>
      )}
      <div className={styles.badgeSection}>
        <div className={styles.category}>
          <BadgeMetric category={item?.category} />
        </div>
        <div className={styles.category}>
          <BadgeTrialCategory category={item?.isOnTrial} />
        </div>
      </div>
      <div className={styles.titleSection}>
        {item?.company?.name && (
        <div className={styles.icon}>
          <MapsHomeWorkOutlinedIcon />
        </div>
        )}
        <DisplayTextSubHeadline
          decoration="bold"
        >
          {limitChar(item?.company?.name, 20)}
        </DisplayTextSubHeadline>
      </div>
      <DisplayDividerMain />
      <div className={styles.additionalInfoSection}>
        <div className={styles.additionalInfo}>
          <div className={styles.infoTitle}>
            <DisplayTextBody
              fontFamily="OpenSans"
            >
              Additional Info :
            </DisplayTextBody>
          </div>
          {isVisitor && <AdditionalInfoVisitor item={item} />}
          {isAcquisition && <AdditionalInfoAcquisition item={item} />}
          {isActivation && <AdditionalInfoActivation item={item} />}
          {isRetention && <AdditionalInfoRetention item={item} />}
          {isRevenue && <AdditionalInfoRevenue item={item} />}
          {isChurn && <AdditionalInfoChurn item={item} />}
        </div>
        {!isVisitor && <DisplayDividerMain borderColor={Color.gray6} />}
        {!isVisitor && (
          <div className={styles.picInfo}>
            <div className={styles.infoTitle}>
              <DisplayTextBody
                fontFamily="OpenSans"
              >
                PIC Info :
              </DisplayTextBody>
            </div>
            <TextInfo
              title="Name"
              text={item?.company?.creator?.fullName}
            />
            <TextInfo
              title="Title"
              text={item?.company?.creator?.status}
            />
            <TextInfo
              title="Phone"
              text={item?.company?.creator?.phoneNumber}
            />
            <TextInfo
              title="Email"
              text={item?.company?.creator?.email}
            />
          </div>
        )}
      </div>
    </div>
  );
};

CompanyItem.propTypes = {
  item: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default memo(CompanyItem);
