import React from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import styles from './ShowLimitMembers.module.css';
import ImageMember from '../UI/ImageMember/ImageMember';
import ButtonDiv from '../UI/Button/ButtonDiv/ButtonDiv';
import { DisplayAvatarMember, DisplayAvatarMemberWithOnline } from '../UI_V2/Display/Avatar';
import colors from '../../themes/colors';

const variantStyles = {
  primary: styles.primary,
  secondary: styles.secondary,
};

const wrapperSizeStyles = {
  sm: styles.wrapper,
  xs: styles.wrapperXs,
};

const ShowLimitMembers = ({
  members,
  limit,
  handleClickPhoto,
  handleClickButton,
  handleClickExceededMember,
  noPlusButton,
  additionalPlusButton,
  companyId,
  buttonVariant,
  enableOnClickCustomOnPhoto,
  positionPopUpOnlineOnPhoto,
  enableDiffClickOnExceededMember,
  disableMemberOnlinePhoto,
  idAddButton,
  sizePhotoMember,
}) => (
  <div className={styles.container}>
    {additionalPlusButton
      ? (
        <div id={idAddButton || 'member_toggle_add_button'} className={styles.additionalPlusButton}>
          <ButtonDiv onClick={handleClickButton} className={`${styles.plusButton} ${variantStyles[buttonVariant]}`}>
            <AddIcon />
          </ButtonDiv>
        </div>
      ) : null}
    {members.map((member, index) => {
      if (index > limit) return null;
      if (index === limit) {
        return (
          <div
            className={wrapperSizeStyles[sizePhotoMember]}
            onClick={
              enableDiffClickOnExceededMember ? handleClickExceededMember : handleClickButton
}
          >
            <p>{members?.length - index}</p>
            <AddIcon />
          </div>
        );
      }

      if (disableMemberOnlinePhoto) {
        return (
          <DisplayAvatarMember
            marginTop="2px"
            marginBottom="2px"
            key={member?._id}
            size={sizePhotoMember}
            src={member?.photoUrl}
            alt={member?.fullName}
            onClick={handleClickPhoto}
          />
        );
      }
      return (
        <DisplayAvatarMemberWithOnline
          marginTop="2px"
          marginBottom="2px"
          key={member?._id}
          size={sizePhotoMember}
          userId={member?._id}
          src={member?.photoUrl}
          alt={member?.fullName}
          onClick={handleClickPhoto}
          enableOnClickCustom={enableOnClickCustomOnPhoto}
          positionPopUp={positionPopUpOnlineOnPhoto}
        />
      );
    })}

    {members?.length < limit && !noPlusButton
      ? (
        <ButtonDiv onClick={handleClickButton} className={`${styles.plusButton} ${variantStyles[buttonVariant]}`}>
          <AddIcon />
        </ButtonDiv>
      )
      : null}

  </div>
);

ShowLimitMembers.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  limit: PropTypes.number.isRequired,
  handleClickPhoto: PropTypes.func,
  handleClickButton: PropTypes.func,
  noPlusButton: PropTypes.bool,
  additionalPlusButton: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
  buttonVariant: PropTypes.oneOf(['primary', 'secondary']),
  enableOnClickCustomOnPhoto: PropTypes.bool,
  positionPopUpOnlineOnPhoto: PropTypes.oneOf(['topRight', 'topLeft', 'bottomRight', 'bottomLeft']),
  enableDiffClickOnExceededMember: PropTypes.bool,
  handleClickExceededMember: PropTypes.func,
  disableMemberOnlinePhoto: PropTypes.bool,
  idAddButton: PropTypes.string,
  sizePhotoMember: PropTypes.oneOf(['sm', 'xs']),
};

ShowLimitMembers.defaultProps = {
  handleClickPhoto: () => null,
  handleClickButton: () => null,
  handleClickExceededMember: () => null,
  noPlusButton: false,
  additionalPlusButton: false,
  buttonVariant: 'primary',
  enableOnClickCustomOnPhoto: false,
  positionPopUpOnlineOnPhoto: 'bottomLeft',
  enableDiffClickOnExceededMember: false,
  disableMemberOnlinePhoto: false,
  idAddButton: undefined,
  sizePhotoMember: 'sm',
};

export default ShowLimitMembers;
