/* eslint-disable jsx-a11y/media-has-caption */
import React, { memo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import styles from './AudioThumbnail.module.css';

const AudioThumbnail = ({
  audioUrl,
  audioType,
}) => (
  <div key={audioUrl} style={{ width: '300px', height: '50px', overflow: 'hidden' }}>
    <audio controls style={{ width: '100%' }} controlsList="nodownload noplaybackrate">
      <source src={audioUrl} type={audioType} />
      Your browser does not support the audio element.
    </audio>
  </div>
);

AudioThumbnail.propTypes = {
  audioUrl: PropTypes.string,
  audioType: PropTypes.string,
};

AudioThumbnail.defaultProps = {
  audioUrl: undefined,
  audioType: 'audio/wav',
};

export default memo(AudioThumbnail);
