import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import Form from 'react-bootstrap/Form';
import { getDay, getWeekOfMonth } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ManageSubscribersContainer from '../ManageSubscribersContainer/ManageSubscribersContainer';
import Title from '../../components/Title/Title';
import Button from '../../components/UI/Button/Button';
import styles from './CreateEventPage.module.css';
import Paper from '../../components/UI/Paper/Paper';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import EditorFull from '../FroalaEditor/EditorFull/EditorFull';
import extractElementBySelectorFromString from '../../utilities/extractElementBySelectorFromString';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import SwitchComponent from '../../components/UI/Switch/SwitchComponent';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { ScheduleActions, SearchJumpToActions, TeamActions } from '../../actions';
import { ApiConstants, PageConstants } from '../../constants';
import apiUtil from '../../utilities/apiUtil';
import { InputSwitchMain } from '../../components/UI_V2/Input/Switch';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import { LayoutMain } from '../../components/UI_V2/Layout';

function CreateEventPage() {
  const [{
    user, currentTeam, currentSchedule, currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const [subscribers, setSubscribers] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [showAddSubscriber, setShowAddSubscriber] = useState(false);

  const [newEvent, setNewEvent] = useState();
  const [postNewEvent, setPostNewEvent] = useState();
  const [editorModel, setEditorModel] = useState();
  const [trigger, setTrigger] = useState();
  const [isAccessPrivate, setAccessPrivate] = useState(false);

  // Recurring event related
  const [repeatCode, setRepeatCode] = useState('NONE');
  const [repeatUntil, setRepeatUntil] = useState('FOREVER');
  const [weekOfMonth, setWeekOfMonth] = useState('');

  const initialStartDate = new Date();
  initialStartDate.setHours(initialStartDate.getHours() + 1);
  initialStartDate.setMinutes(0);
  const initialEndDate = new Date(initialStartDate);
  initialEndDate.setHours(initialEndDate.getHours() + 1);

  const [selectedDateStart, setSelectedDateStart] = useState(initialStartDate);
  const [selectedDateEnd, setSelectedDateEnd] = useState(initialEndDate);
  const [selectedDateUntil, setSelectedDateUntil] = useState(initialStartDate);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, scheduleId } = params;

  const handleCancel = () => {
    history.push(`/companies/${companyId}/teams/${teamId}/schedules/${scheduleId}`);
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (currentSchedule && currentSchedule?._id === scheduleId) return;

    const startLoadings = handleLoadings('createEventPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiScheduleDetail = async () => {
      try {
        const result = await ScheduleActions.initiateScheduleV2({
          scheduleId,
          companyId,
          fromDate: new Date(),
          untilDate: new Date(),
          teamId,
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('createEventPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiScheduleDetail();
  }, []);

  useEffect(() => {
    if (!user?._id) return;
    if (!currentSchedule._id) return;
    setSubscribers([user]);
  }, [currentSchedule]);

  useEffect(() => {
    if (postNewEvent === undefined) return;

    const postApiEvent = async () => {
      let url;

      if (postNewEvent.isRecurring) {
        url = ApiConstants.URL_V1.SCHEDULE_OCCURRENCES({ scheduleId });
      } else {
        url = ApiConstants.URL_V1.SCHEDULE_EVENTS({ scheduleId });
      }

      try {
        const startLoadings = handleLoadings('createEvent', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.post(url, postNewEvent, {
          params: {
            companyId,
            teamId,
          },
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('createEvent', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiEvent();
  }, [postNewEvent]);

  const handleDateChangeStart = (date) => {
    if (date >= selectedDateEnd) {
      const endDate = new Date(date);
      endDate.setHours(endDate.getHours() + 1);
      setSelectedDateEnd(endDate);

      if (date >= selectedDateUntil) {
        setSelectedDateUntil(endDate);
      }
    }
    setSelectedDateStart(date);
  };

  const handleDateChangeEnd = (date) => {
    if (date < selectedDateStart) {
      enqueueSnackbar('Cannot choose end date time before start date time', {
        variant: 'error',
      });
      return;
    }
    setSelectedDateEnd(date);
  };

  const handleChanged = (event) => {
    const { value } = event.target;
    setNewEvent((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleTriggerPostEvent = () => {
    setTrigger(Math.random());
  };

  const handlePostEvent = () => {
    if (newEvent === undefined || newEvent.value === undefined || newEvent.value.length < 1) {
      enqueueSnackbar('Event title cannot be empty', {
        variant: 'error',
      });
      return;
    }

    const mentionedUsers = extractElementBySelectorFromString(editorModel, '#mentioned-user');

    const subscriberIds = [];

    subscribers.forEach((subscriber) => {
      subscriberIds.push(subscriber._id);
    });

    const payload = {
      title: newEvent.value,
      content: editorModel,
      startDate: selectedDateStart,
      endDate: selectedDateEnd,
      subscribers: [...subscriberIds],
      mentionedUsers,
      isPublic: !isAccessPrivate,
    };

    if (repeatCode !== 'NONE') {
      payload.isRecurring = true;
      payload.recurrence = {
        code: repeatCode,
        startDate: selectedDateStart,
      };

      if (repeatUntil === 'UNTIL') {
        payload.recurrence.endDate = selectedDateUntil;
      }
    }

    setPostNewEvent(payload);
  };

  useEffect(() => {
    if (trigger === undefined) return;
    handlePostEvent();
  }, [trigger]);

  useEffect(() => {
    const convertNumberWeekday = (number) => {
      let result = '';
      switch (number) {
        case 1:
          result = 'Monday';
          break;
        case 2:
          result = 'Tuesday';
          break;
        case 3:
          result = 'Wednesday';
          break;
        case 4:
          result = 'Thursday';
          break;
        case 5:
          result = 'Friday';
          break;
        case 6:
          result = 'Saturday';
          break;
        case 0:
          result = 'Sunday';
          break;
        default:
          break;
      }

      return result;
    };

    const convertFormatingOrdinal = (number) => {
      let result = '';
      switch (number) {
        case 1:
          result = '1st';
          break;
        case 2:
          result = '2nd';
          break;
        case 3:
          result = '3rd';
          break;
        case 4:
          result = '4th';
          break;
        case 5:
          result = '5th';
          break;
        case 6:
          result = '6th';
          break;
        default:
          break;
      }

      return result;
    };

    const weekOfMonthData = getWeekOfMonth(selectedDateStart);
    const weekString = convertFormatingOrdinal(weekOfMonthData);
    const dayOfWeek = getDay(selectedDateStart);
    const dayString = convertNumberWeekday(dayOfWeek);

    setWeekOfMonth(`${weekString} ${dayString}`);
  }, [selectedDateStart]);

  const handleCancelShowAddSubscriber = () => {
    setShowAddSubscriber(false);
  };

  const handleShowAddSubscriber = () => {
    setShowAddSubscriber(true);
  };

  const handleSetSubscribers = (incomingSubscribers) => {
    setSubscribers([...incomingSubscribers]);
  };

  const handleEditorModelChange = (model) => {
    setEditorModel(model);
  };

  const customConfig = {
    heightMin: 200,
  };

  const handleChangeRepeatCode = (event) => {
    setRepeatCode(event.target.value);
  };

  const handleChangeRepeatUntil = (event) => {
    setRepeatUntil(event.target.value);
  };

  const handleDateChangeUntil = (date) => {
    const compareDate = new Date(date);
    compareDate.setHours(selectedDateStart.getHours() + 2, 0, 0, 0);
    if (compareDate < selectedDateStart) {
      enqueueSnackbar('Cannot choose end date before start date', {
        variant: 'error',
      });
      return;
    }
    setSelectedDateUntil(date);
  };

  const handleChangeAccess = useCallback((access) => {
    setAccessPrivate(access);
  }, []);

  const handleOpenDatePickerUntil = () => {
    setRepeatUntil('UNTIL');
  };
  const pageType = PageConstants.pageType.schedulesCreate;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: { companyId, currentTeam, currentSchedule },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${scheduleId}-create`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title="Buat Jadwal Baru" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="schedules"
        withBottomNavBar={false}
      />
      <LayoutMain>
        <PageLoading wait="createEventPage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.eventsSection}>
                <div className={styles.eventsSection__title}>
                  <Form.Control as="input" placeholder="Ketik nama jadwal..." onChange={(event) => handleChanged(event)} />
                </div>
                <div className={styles.eventsSection__schedule}>
                  <div className={styles.schedule__start}>
                    <div className={styles.schedule__titleMenu}>
                      <p>Mulai</p>
                    </div>
                    <div className={styles.dueDate}>
                      <div className={styles.datePicker}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                        >
                          <DesktopDatePicker
                            inputFormat="yyyy/MM/dd"
                            value={selectedDateStart}
                            onChange={handleDateChangeStart}
                            slotProps={{
                              textField: {
                                variant: 'standard', // Use "standard" to remove the box outline
                                sx: {
                                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                                  '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                  fontSize: '0.875rem', // Smaller font size
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className={styles.timePicker}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                        >
                          <TimePicker
                            value={selectedDateStart}
                            onChange={handleDateChangeStart}
                            slotProps={{
                              textField: {
                                variant: 'standard', // Use "standard" to remove the box outline
                                sx: {
                                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                                  '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                  fontSize: '0.875rem', // Smaller font size
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>

                  </div>
                  <div className={styles.schedule__end}>
                    <div className={styles.schedule__titleMenu}>
                      <p>Akhir</p>
                    </div>
                    <div className={styles.dueDate}>
                      <div className={styles.datePicker}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                        >
                          <DesktopDatePicker
                            inputFormat="yyyy/MM/dd"
                            value={selectedDateEnd}
                            onChange={handleDateChangeEnd}
                            slotProps={{
                              textField: {
                                variant: 'standard', // Use "standard" to remove the box outline
                                sx: {
                                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                                  '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                  fontSize: '0.875rem', // Smaller font size
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className={styles.timePicker}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                        >
                          <TimePicker
                            value={selectedDateEnd}
                            onChange={handleDateChangeEnd}
                            slotProps={{
                              textField: {
                                variant: 'standard', // Use "standard" to remove the box outline
                                sx: {
                                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                                  '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                  fontSize: '0.875rem', // Smaller font size
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>

                  <div className={styles.schedule__repeat}>
                    <div className={styles.schedule__titleMenu}>
                      <p>Ulangi</p>
                    </div>
                    <div>
                      <FormControl variant="standard">
                        <Select
                          value={repeatCode}
                          onChange={handleChangeRepeatCode}
                        >
                          <MenuItem value="NONE">Jangan ulangi</MenuItem>
                          <MenuItem value="DAILY">Setiap hari</MenuItem>
                          <MenuItem value="WEEKLY">Setiap minggu</MenuItem>
                          <MenuItem value="QUARTERLY">Setiap kuartal</MenuItem>
                          <MenuItem value="YEARLY">Setiap tahun</MenuItem>
                          <SeparatorLine />
                          <MenuItem value="WEEKLY_WEEKDAYS">Setiap hari kerja (Sen - Jum)</MenuItem>
                          <MenuItem value="MONTHLY">
                            Setiap Bulan (pada
                            {' '}
                            {weekOfMonth}
                            )
                          </MenuItem>
                        </Select>
                        {repeatCode !== 'NONE' ? (
                          <div className={styles.selectRepeatUntilSection}>
                            <RadioGroup value={repeatUntil} onChange={handleChangeRepeatUntil}>
                              <FormControlLabel value="FOREVER" control={<Radio size="small" />} label="Selamanya" />
                              <div>
                                <FormControlLabel value="UNTIL" control={<Radio size="small" />} label="Sampai" />
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopDatePicker
                                    value={selectedDateUntil}
                                    onChange={handleDateChangeUntil}
                                    onOpen={handleOpenDatePickerUntil}
                                    slotProps={{
                                      textField: {
                                        variant: 'standard', // Use "standard" to remove the box outline
                                        sx: {
                                          '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                                          '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                          fontSize: '0.875rem', // Smaller font size
                                        },
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </RadioGroup>
                          </div>
                        )
                          : null}
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className={styles.manageSubscribers}>
                  <ManageSubscribersContainer
                    type="event"
                    initialSubscribers={subscribers}
                    handleSetSubscribers={handleSetSubscribers}
                    labelPrimary="Peserta :"
                    labelSecondary="Tambah/Hapus Peserta"
                  />
                </div>

                <InputSwitchMain
                  title="Apakah Jadwal ini Rahasia untuk Peserta aja?"
                  labelSwitch="Rahasia"
                  handleChange={handleChangeAccess}
                />

                <div className={styles.eventsSection__desc}>
                  <h1>Catatan</h1>
                  {currentTeam.members ? <EditorFull companyId={companyId} model={editorModel} onModelChange={handleEditorModelChange} triggerLists={currentTeam.members} type="event" handleAction={handleTriggerPostEvent} customConfig={customConfig} /> : null }
                </div>
              </div>
              <div className={styles.actionSection}>
                <Button handleClick={handlePostEvent} variant="success" wait="createEvent" spinnerSize="sm" loadings={loadings}>Publikasikan</Button>
                <Button handleClick={handleCancel} variant="danger">Batal</Button>
              </div>
            </div>
          </SurfacePaperMain>
        </PageLoading>
      </LayoutMain>
    </>
  );
}

export default CreateEventPage;
