import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import styles from './ScrollToBottomButton.module.css';

const variantStyles = {
  chat: styles.chat,
  groupChat: styles.groupChat,
};

const ScrollToBottomButton = forwardRef(({ isFirstItemShown, variant }, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isFirstItemShown) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [isFirstItemShown]);

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={`${styles.container} ${variantStyles[variant]}`}>
      {isVisible && (
        // eslint-disable-next-line react/button-has-type
        <div className={styles.button} onClick={scrollToBottom}>
          <KeyboardDoubleArrowDownIcon />
        </div>
      )}
    </div>
  );
});

ScrollToBottomButton.propTypes = {
  isFirstItemShown: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['groupChat, chat']),
};

ScrollToBottomButton.defaultProps = {
  variant: 'chat',
};

export default ScrollToBottomButton;
