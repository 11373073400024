import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import Form from 'react-bootstrap/Form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import Paper from '../../components/UI/Paper/Paper';
import styles from './EditQuestionPage.module.css';
import { actionTypes } from '../../reducers/reducer';
import Button from '../../components/UI/Button/Button';
import Title from '../../components/Title/Title';
import ManageSubscribersContainer from '../ManageSubscribersContainer/ManageSubscribersContainer';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import ButtonDiv from '../../components/UI/Button/ButtonDiv/ButtonDiv';
import { QuestionActions, SearchJumpToActions, TeamActions } from '../../actions';
import { ApiConstants, PageConstants } from '../../constants';
import DayBox from '../../components/UI/DayBox/DayBox';
import SwitchComponent from '../../components/UI/Switch/SwitchComponent';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import apiUtil from '../../utilities/apiUtil';
import { InputSwitchMain } from '../../components/UI_V2/Input/Switch';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';

function EditQuestionPage() {
  const [{
    user, currentCheckInQuestion, currentCheckIn, currentTeam, currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [daysSelected, setDaysSelected] = useState(['Mon']);
  const [showAddSubscriber, setShowAddSubscriber] = useState(false);
  const [subscribers, setSubscribers] = useState([]);

  const [newQuestion, setNewQuestion] = useState({ value: '' });
  const [postNewQuestion, setPostNewQuestion] = useState();
  const [isAccessPrivate, setAccessPrivate] = useState(false);

  let initialDate = new Date();
  initialDate = initialDate.setHours(9, 0);
  const [selectedTime, setSelectedTime] = useState(initialDate);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, questionId } = params;

  const handleCancel = () => {
    history.push(`/companies/${companyId}/teams/${teamId}/questions/${questionId}`);
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('editQuestionPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiQuestionDetail = async () => {
      try {
        const result = await QuestionActions.initiateQuestion(
          {
            questionId,
            checkInId: currentCheckIn?._id,
            companyId,
            teamId,
          }, dispatch,
        );
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('editQuestionPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiQuestionDetail();
  }, []);

  useEffect(() => {
    if (postNewQuestion === undefined) return;

    const postApiQuestion = async () => {
      try {
        const startLoadings = handleLoadings('editQuestion', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.patch(
          ApiConstants.URL_V1.QUESTION_DETAIL({ questionId }),
          postNewQuestion,
          {
            params: {
              companyId,
              teamId,
            },
          },
        );

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('editQuestion', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiQuestion();
  }, [postNewQuestion]);

  const days = [
    {
      name: 'Mon',
      id: 1,
    },
    {
      name: 'Tue',
      id: 2,
    },
    {
      name: 'Wed',
      id: 3,
    },
    {
      name: 'Thu',
      id: 4,
    },
    {
      name: 'Fri',
      id: 5,
    },
    {
      name: 'Sat',
      id: 6,
    },
    {
      name: 'Sun',
      id: 0,
    },
  ];
  const handlePickDay = (name) => {
    let tempDays = [...daysSelected];

    if (tempDays.includes(name)) {
      tempDays = tempDays.filter((day) => day !== name);
      setDaysSelected([...tempDays]);
    } else {
      tempDays.push(name);
      setDaysSelected([...tempDays]);
    }
  };

  const handleTimeChange = (date) => {
    setSelectedTime(date);
  };

  const handleChanged = (event) => {
    const { value } = event.target;
    setNewQuestion((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handlePostQuestion = () => {
    // send subscriber ids
    const subscriberIds = [];

    subscribers.forEach((subscriber) => {
      subscriberIds.push(subscriber._id);
    });

    let hour;
    let minute;

    if (selectedTime.getUTCHours && typeof selectedTime.getUTCHours === 'function') {
      hour = selectedTime.getUTCHours();
      minute = selectedTime.getUTCMinutes();
    } else {
      hour = new Date(selectedTime);
      hour = hour.getUTCHours();
      minute = new Date(selectedTime);
      minute = minute.getUTCMinutes();
    }

    // remove old data that is not string (number type)
    const reg = new RegExp('^[0-9]+$');
    const filteredDays = daysSelected.filter((day) => !day.match(reg));

    if (!newQuestion || !newQuestion?.value || newQuestion?.value?.length < 1) {
      enqueueSnackbar('Question cannot be empty', {
        variant: 'error',
      });

      return;
    }

    setPostNewQuestion({
      title: newQuestion.value,
      subscribers: [...subscriberIds],
      schedule: {
        days: [...filteredDays],
        hour,
        minute,
      },
      isPublic: !isAccessPrivate,
    });
  };

  // change initial value based on currentCheckInQuestion
  useEffect(() => {
    if (isEmpty(currentCheckInQuestion)) {
      return;
    }

    setNewQuestion({ value: currentCheckInQuestion.title });

    setDaysSelected([...currentCheckInQuestion.schedule.days]);

    initialDate = new Date();
    initialDate = initialDate.setUTCHours(
      currentCheckInQuestion.schedule.hour,
      currentCheckInQuestion.schedule.minute,
    );
    setSelectedTime(initialDate);

    if (currentCheckInQuestion?.isPublic !== undefined) {
      setAccessPrivate(!currentCheckInQuestion?.isPublic);
    }

    // set subscribers
    const tempSubscribers = [];
    currentCheckInQuestion.subscribers.forEach((subscriber) => {
      tempSubscribers.push(subscriber);
    });

    setSubscribers([...tempSubscribers]);
  }, [currentCheckInQuestion]);

  const handleCancelShowAddSubscriber = () => {
    setShowAddSubscriber(false);
  };

  const handleShowAddSubscriber = () => {
    setShowAddSubscriber(true);
  };

  const handleSetSubscribers = (incomingSubscribers) => {
    setSubscribers([...incomingSubscribers]);
  };

  const handleChangeAccess = useCallback((access) => {
    setAccessPrivate(access);
  }, []);
  const pageType = PageConstants.pageType.questionsEdit;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentCheckIn, currentCheckInQuestion,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${questionId}-edit`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={currentCheckInQuestion.title === undefined ? 'Ubah Pertanyaan' : `Ubah ${currentCheckInQuestion.title}`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="check-ins"
        withBottomNavBar={false}
      />
      <LayoutMain>
        <PageLoading wait="editQuestionPage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.questionsSection}>
                <div className={styles.questionsSection__title}>
                  <h1>Pertanyaan rutin apa yang ingin kamu tanyakan?</h1>
                  <Form.Control as="input" placeholder="Berapa data penjualan hari ini? Apa yang kamu kerjakan minggu ini?, dll" onChange={(event) => handleChanged(event)} value={newQuestion.value} />
                </div>
                <div className={styles.questionsSection__schedule}>
                  <h1>Pada hari apa aja pertanyaan ini dikirim?</h1>
                  <div>
                    <div className={styles.schedule__detail}>
                      <div className={styles.schedule__detail__picker}>
                        {days.map((day) => (
                          <DayBox
                            name={day.name}
                            key={day.id}
                            clicked={() => handlePickDay(day.name)}
                            selected={!!daysSelected.includes(day.name)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.questionsSection__time}>
                  <h1>Jam berapa?</h1>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                  >
                    <TimePicker
                      value={selectedTime}
                      onChange={handleTimeChange}
                      slotProps={{
                        textField: {
                          variant: 'standard', // Use "standard" to remove the box outline
                          sx: {
                            '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                            '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                            fontSize: '0.875rem', // Smaller font size
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className={styles.manageSubscribers}>
                  <ManageSubscribersContainer
                    type="question"
                    initialSubscribers={subscribers}
                    handleSetSubscribers={handleSetSubscribers}
                  />
                </div>
              </div>

              <InputSwitchMain
                title="Apakah pertanyaan ini Rahasia untuk Penerima aja?"
                labelSwitch="Rahasia"
                handleChange={handleChangeAccess}
                defaultValue={isAccessPrivate}
              />

              <div className={styles.actionSection}>
                <Button handleClick={handlePostQuestion} variant="success" wait="editQuestion" spinnerSize="sm" loadings={loadings}>Simpan Perubahan</Button>
                <Button handleClick={handleCancel} variant="danger">Batal</Button>
              </div>
            </div>
          </SurfacePaperMain>
        </PageLoading>
      </LayoutMain>
    </>
  );
}

export default EditQuestionPage;
