/* eslint-disable jsx-a11y/media-has-caption */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './AudioPlayerOnCreateMessage.module.css';

const AudioPlayerOnCreateMessage = ({
  audioSrc,
  onDeleteAudio,
}) => (
  <div className={styles.container}>
    <div className={styles.deleteIcon} onClick={onDeleteAudio}>
      <DeleteIcon />
    </div>
    <audio controls style={{ width: '100%' }} controlsList="nodownload noplaybackrate">
      <source src={audioSrc} type="audio/wav" />
      Your browser does not support the audio element.
    </audio>
  </div>
);

AudioPlayerOnCreateMessage.propTypes = {
  audioSrc: PropTypes.string,
  onDeleteAudio: PropTypes.func,
};

AudioPlayerOnCreateMessage.defaultProps = {
  audioSrc: undefined,
  onDeleteAudio: () => null,
};

export default memo(AudioPlayerOnCreateMessage);
