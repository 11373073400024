import React, {
  memo, useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/AttachFile';
import draftToHtml from 'draftjs-to-html';
import styles from './RepliedMessageOnCreateMessage.module.css';
import EditorView from '../../../FroalaEditor/EditorView/EditorView';
import { checkIsJson } from '../../../../utilities/stringUtil';
import { DisplayAvatarMember } from '../../../../components/UI_V2/Display/Avatar';

const RepliedMessageOnCreateMessage = ({
  handleOnCloseReplyMessageSection, replyMessage,
}) => {
  const [editorModel, setEditorModel] = useState();
  const [fileName, setFileName] = useState('');

  const {
    creator,
    content,
    type,
    url,
    name,
    mimeType,
  } = replyMessage;

  useEffect(() => {
    switch (type) {
      case 'message': {
        let initialContent = content;
        if (checkIsJson(content)) {
          initialContent = draftToHtml(JSON.parse(content));
        }
        setEditorModel(initialContent);
        break;
      }
      case 'attachment': {
        const tempName = name.length > 40 ? `${name.slice(0, 40)}...` : name;
        setFileName(tempName);
        break;
      }
      default:
        break;
    }
  }, [replyMessage]);

  return (
    <div className={styles.container}>
      <div className={styles.replyCommentHeader}>
        <div className={styles.replyCommentTitle}>
          <p>
            Balas pesan
            {type === 'attachment' && (
              <DescriptionIcon />
            )}
          </p>
        </div>
        <button
          type="button"
          className={styles.replyCommentCancel}
          onClick={handleOnCloseReplyMessageSection}
        >
          <CloseIcon />
        </button>
      </div>
      { replyMessage && (
        <div className={styles.replyCommentContainer}>
          <div className={styles.replyCommentPhoto}>
            <DisplayAvatarMember src={`${creator?.photoUrl}`} />
          </div>
          <div className={styles.replyCommentMessage}>
            <h5>{creator?.fullName}</h5>
            {type === 'message' && (
              <p>
                <EditorView model={editorModel} />
              </p>
            )}
            <div className={styles.attachmentTitle}>
              <p>
                <EditorView model={fileName} />
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

RepliedMessageOnCreateMessage.propTypes = {
  handleOnCloseReplyMessageSection: PropTypes.func.isRequired,
  replyMessage: PropTypes.func.isRequired,
};

export default memo(RepliedMessageOnCreateMessage);
