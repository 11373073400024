import React, { useState, useContext, useEffect } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { NavigationMenuBasic } from '../../../components/UI_V2/Navigation/Menu';
import styles from './ManageCopyCard.module.css';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import handleLoadings from '../../../utilities/handleLoadings';
import { ListActions } from '../../../actions';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import ListNameOption from './ListNameOption/ListNameOption';
import ListPositionOption from './ListPositionOption/ListPositionOption';
import TeamNameOption from './TeamNameOption/TeamNameOption';
import { validateTitle } from '../../../utilities/inputValidatorUtil';
import { createCopyCard } from '../../../actions/CardActions';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';

const ManageCopyCard = ({
  cancel, card, list, bottom,
}) => {
  const [{ currentBoard, teams }] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [showCreateListForm] = useDelayShowHideHandler();
  const [showTeamName, setShowTeamName] = useState(false);
  const [showListName, setShowListName] = useState(false);
  // const [showListPosition, setShowListPosition] = useState(false);
  const [lists, setLists] = useState([]);
  const params = useParams();
  const { companyId } = params;
  const boardId = params.boardId
    || card.boards?.[0]?._id
    || card.boards?.[0]
    || currentBoard?._id;
  const teamId = params.teamId || card.team?._id || card.team;
  const [newCardName, setNewCardName] = useState(`${card?.name} copy`);
  const [selectedBoardId, setSelectedBoardId] = useState(boardId);
  const [isKeepLabelChecked, setIsKeepLabelChecked] = useState(true);
  const [isKeepAttachmentChecked, setIsKeepAttachmentChecked] = useState(true);
  const [isKeepMemberChecked, setIsKeepMemberChecked] = useState(true);
  const [isKeepChecklistChecked, setIsKeepChecklistChecked] = useState(true);

  const [destinationData, setDestinationData] = useState({
    source: {
      droppableId: list._id,
    },
    destination: {
      droppableId: list._id,
      index: 0,
    },
  });

  const currentTeam = teams.find((team) => team._id === teamId);
  const [viewListData, setViewListData] = useState({
    teamName: currentTeam?.name,
    listName: list.name,
    listPosition: 1,
  });

  const fetchAllListsByTeamApi = async (selectedTeamId) => {
    try {
      const startLoadings = handleLoadings(
        'copyCardLists',
        [...loadings],
        'start',
      );
      setLoadings([...startLoadings]);
      const result = await ListActions.getListsByCompanyAndTeam({
        companyId,
        teamId: selectedTeamId,
      });

      setLists(result?.lists);

      if (result?.lists?.length) {
        const sameList = result?.lists.find(
          (newList) => newList._id === list._id,
        );
        setViewListData((prevValue) => ({
          ...prevValue,
          listName: sameList ? sameList.name : result?.lists[0]?.name,
          listPosition: 1,
        }));

        setDestinationData((prevValue) => ({
          ...prevValue,
          destination: {
            ...prevValue.destination,
            droppableId: sameList ? sameList._id : result?.lists[0]?._id,
            index: 0,
          },
        }));

        setSelectedBoardId(result?.lists[0]?.board);
      } else {
        setViewListData((prevValue) => ({
          ...prevValue,
          listName: '-',
          listPosition: '-',
        }));
      }
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings('copyCardLists', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    fetchAllListsByTeamApi(teamId);
  }, [teamId]);

  const handleChanged = (event) => {
    const { value } = event.target;
    setNewCardName(() => value);
  };

  const handleSelectedBoardId = (value) => {
    setSelectedBoardId(value);
  };

  const handleShowCopyTeamName = () => {
    setShowTeamName(!showTeamName);
  };

  const handleShowCopyListName = () => {
    setShowListName(!showListName);
  };

  // const handleShowCopyListPosition = () => {
  //   setShowListPosition(!showListPosition);
  // };

  const handleFetchAllLists = (id) => {
    fetchAllListsByTeamApi(id);
  };

  const handleUpdateDestTeamId = (value) => {
    setDestinationData(() => ({
      ...value,
    }));
  };

  const handleKeepLabelChecked = () => {
    setIsKeepLabelChecked(() => !isKeepLabelChecked);
  };

  const handleKeepAttachmentChecked = () => {
    setIsKeepAttachmentChecked(() => !isKeepAttachmentChecked);
  };

  const handleKeepMemberChecked = () => {
    setIsKeepMemberChecked(() => !isKeepMemberChecked);
  };

  const handleKeepChecklistChecked = () => {
    setIsKeepChecklistChecked(() => !isKeepChecklistChecked);
  };

  const handleUpdateDestinationList = (value) => {
    setDestinationData((prevValue) => ({
      ...prevValue,
      destination: {
        ...prevValue.destination,
        ...value,
      },
    }));
  };

  const handleUpdateViewList = (value) => {
    setViewListData((prevValue) => ({
      ...prevValue,
      ...value,
    }));
  };

  const handleSaveCopyCard = async () => {
    const payload = {
      cardId: card?._id,
      companyId,
      teamId,
      destListId: destinationData.destination.droppableId,
      destListIndex: destinationData.destination.index,
      boardId: selectedBoardId,
      newCardName,
      labelChecked: isKeepLabelChecked,
      memberChecked: isKeepMemberChecked,
      checklistChecked: isKeepChecklistChecked,
      attachmentChecked: isKeepAttachmentChecked,
    };

    if (!payload.destListId) {
      enqueueSnackbar('List destination not found', {
        variant: 'error',
      });
      return;
    }

    try {
      const startLoadings = handleLoadings(
        'createCopyCard',
        [...loadings],
        'start',
      );
      setLoadings([...startLoadings]);

      const { isValid, errorMsg } = validateTitle(newCardName);

      if (!isValid) {
        enqueueSnackbar(errorMsg, {
          variant: 'error',
        });
        return;
      }

      const result = await createCopyCard(payload);
      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      cancel();
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings(
        'createCopyCard',
        [...loadings],
        'end',
      );
      setLoadings([...endLoadings]);
    }
  };

  return (
    <NavigationMenuBasic
      size="mdWider"
      position={bottom ? 'centerBottom' : 'topRight'}
      disabled={showTeamName || showListName}
      onClose={cancel}
    >
      {!showCreateListForm && (
        <div className={styles.container}>
          <div className={styles.ManageCopyCard__Header}>
            <h1 className="mr-auto">Salin Tugas</h1>
          </div>
          <SeparatorLine />
          <div className={styles.body}>
            <p className={styles.ManageCopyCard__smallTitle}>NAMA TUGAS</p>
            <div className={`${styles.listSection} mb-3`}>
              <Form>
                <Form.Control
                  autoFocus
                  onChange={(event) => handleChanged(event)}
                  as="input"
                  placeholder="..."
                  value={newCardName}
                />
              </Form>
            </div>
            <p className={styles.ManageCopyCard__smallTitle}>PERTAHANKAN...</p>
            <div className={`${styles.listSection}`}>
              <div className={styles.checklistAndTitle}>
                <button
                  type="button"
                  className={styles.checklist}
                  onClick={handleKeepLabelChecked}
                >
                  {isKeepLabelChecked ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </button>
                <DisplayTextBody>
                  Label (
                  {card?.labels?.length || 0}
                  )
                </DisplayTextBody>
              </div>
            </div>
            <div className={`${styles.listSection}`}>
              <div className={styles.checklistAndTitle}>
                <button
                  type="button"
                  className={styles.checklist}
                  onClick={handleKeepMemberChecked}
                >
                  {isKeepMemberChecked ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </button>
                <DisplayTextBody>
                  Anggota (
                  {card?.members?.length || 0}
                  )
                </DisplayTextBody>
              </div>
            </div>
            <div className={`${styles.listSection}`}>
              <div className={styles.checklistAndTitle}>
                <button
                  type="button"
                  className={styles.checklist}
                  onClick={handleKeepChecklistChecked}
                >
                  {isKeepChecklistChecked ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </button>
                <DisplayTextBody>
                  Ceklis (
                  {card?.checklists?.length || 0}
                  )
                </DisplayTextBody>
              </div>
            </div>
            <div className={`${styles.listSection} mb-3`}>
              <div className={styles.checklistAndTitle}>
                <button
                  type="button"
                  className={styles.checklist}
                  onClick={handleKeepAttachmentChecked}
                >
                  {isKeepAttachmentChecked ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </button>
                <DisplayTextBody>
                  Lampiran (
                  {card?.attachments?.length || 0}
                  )
                </DisplayTextBody>
              </div>
            </div>
            <p className={styles.ManageCopyCard__smallTitle}>
              SALIN TUGAS KE...
            </p>
            <div className={`${styles.listSection} mb-3`}>
              <ButtonDiv
                onClick={handleShowCopyTeamName}
                className={styles.listSection__teamName}
              >
                <a>PILIH TIM TUJUAN</a>
                <p>{viewListData.teamName}</p>
              </ButtonDiv>
              {showTeamName ? (
                <TeamNameOption
                  handleUpdateDestTeamId={handleUpdateDestTeamId}
                  handleUpdateViewTeamName={handleUpdateViewList}
                  cancel={handleShowCopyTeamName}
                  teams={teams}
                  handleFetchAllLists={handleFetchAllLists}
                />
              ) : (
                <></>
              )}
            </div>
            <p className={styles.ManageCopyCard__smallTitle}>
              PILIH LIST TUJUAN
            </p>
            <div className={styles.listSection}>
              <ButtonDiv
                onClick={handleShowCopyListName}
                className={styles.listSection__listName}
              >
                <a>List</a>
                <p>{viewListData.listName}</p>
              </ButtonDiv>
              {showListName && (
                <ListNameOption
                  handleUpdateDestListId={handleUpdateDestinationList}
                  handleUpdateViewListName={handleUpdateViewList}
                  cancel={handleShowCopyListName}
                  handleSelectedBoardId={handleSelectedBoardId}
                  lists={lists}
                />
              )}
              {/* <ButtonDiv
                onClick={handleShowCopyListPosition}
                className={styles.listSection__listPosition}
              >
                <a>Posisi</a>
                <p>{viewListData.listPosition}</p>
                {showListPosition && (
                  <ListPositionOption
                    handleUpdateDestListIndex={handleUpdateDestinationList}
                    handleUpdateViewListPosition={handleUpdateViewList}
                    destData={destinationData}
                    lists={lists}
                    cancel={handleShowCopyListPosition}
                  />
                )}
              </ButtonDiv> */}
            </div>
          </div>
          <div className={styles.footer}>
            <InputButtonMain
              handleClick={handleSaveCopyCard}
              variant="success"
              size="sm"
              loadings={loadings}
              wait="createCopyCard"
            >
              Copy
            </InputButtonMain>
          </div>
        </div>
      )}
    </NavigationMenuBasic>
  );
};

ManageCopyCard.propTypes = {
  cancel: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  bottom: PropTypes.bool,
};

ManageCopyCard.defaultProps = {
  bottom: false,
};

export default ManageCopyCard;
