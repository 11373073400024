import React, {
  useCallback, useState, useContext, useEffect, memo,
} from 'react';
import { useParams } from 'react-router-dom';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { isNumber, isEqual, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import styles from './ListCard.module.css';
import handleLoadings from '../../../utilities/handleLoadings';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import SmallCard from '../SmallCard/SmallCard';
import EditCardMenu from '../EditCardMenu/EditCardMenu';
import { CardConstants } from '../../../constants';
import { CardActions } from '../../../actions';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import {
  getItemByIDFromList, reverseSortListByDateValue,
  reverseSortListByStringValue, sortListByDateValue, sortListByStringValue,
} from '../../../utilities/arrayUtil';
import { getStore } from '../../../utilities/localStorage';
import { checkIfUserHaveAccessToDataV3 } from '../../../actions/UserActions';

function ListCard(props) {
  const {
    list, cardUpdateListPos, loadings, showEdit,
    handleShowEditCardMenu, handleCancelEditCardMenu, handleCancelAllCardMenu,
    handleShowMove, handleShowCopy, handleShowLabel,
    handleShowDueDate, handleShowMember, handleShowNote, handleShowName, indexListCard,
    handleShowQuickComment, allConfigListBoard, setConfigListBoard, shouldFetch,
    handleAddListCompleteFetchCards,
  } = props;
  const [{
    user, currentBoard, currentCompany, currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const [isErrorLoadMore, setStatusErrorLoadMore] = useState(false);
  const [localLoadings, setLocalLoadings] = useState([]);
  const [localList, setLocalList] = useState(list);
  const [checkLoadMore, setCheckLoadMore] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const idContainer = `listcard_container-${indexListCard}`;
  const idContent = `listcard_content-${indexListCard}`;
  const params = useParams();
  const { companyId, teamId, boardId } = params;

  const isCardsLengthAtTheLimitOrAbove = useCallback(
    (cards) => isNumber(cards?.length) && cards?.length >= CardConstants.limitCard,
    [],
  );

  const triggerLoadMore = useCallback((value) => {
    setCheckLoadMore(value);
  }, []);

  const stopEntirelyLoadMore = useCallback(() => {
    handleAddListCompleteFetchCards(list);
    triggerLoadMore();
    const endLoadings = handleLoadings(`listCards-${list._id}`, [...loadings], 'end');
    setLocalLoadings([...endLoadings]);
  }, []);

  const isListExistOnShouldFetch = useCallback((lists) => {
    if (lists?.length < 1) return false;
    const foundList = getItemByIDFromList(lists, list?._id);
    if (foundList) return true;
    return false;
  }, []);

  const handleUpdateLocalList = useCallback((newList) => {
    setLocalList(newList);
  }, []);

  const endLoadings = useCallback(() => {
    const array = handleLoadings(`listCards-${list._id}`, [...loadings], 'end');
    setLocalLoadings([...array]);
  }, []);

  useEffect(() => {
    if (!list?._id) return;
    if (!checkLoadMore) return;
    if (!isListExistOnShouldFetch(shouldFetch)) return;
    if (!currentBoard?._id || currentBoard?._id !== boardId) return;
    const startLoadings = handleLoadings(`listCards-${list?._id}`, [...loadings], 'start');
    setLocalLoadings([...startLoadings]);
    if (list?.cards?.length === list?.totalCard) {
      stopEntirelyLoadMore();
      return;
    }

    const fetchCardsApi = async () => {
      try {
        const result = await CardActions.loadMoreCards({
          handleUpdateList: handleUpdateLocalList,
          isCardsLengthAtTheLimitOrAbove,
          triggerLoadMore,
          handleAddListCompleteFetchCards,
          list: localList,
          companyId,
          teamId,
          endLoadings,
        }, dispatch);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');
        if (status?.message) {
          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
        setStatusErrorLoadMore(true);
      }
    };
    fetchCardsApi();
  }, [isErrorLoadMore, shouldFetch, checkLoadMore]);

  useEffect(() => {
    if (isListExistOnShouldFetch(shouldFetch) && !checkLoadMore) {
      handleUpdateLocalList(list);
      triggerLoadMore(1);
    }
  }, [shouldFetch]);

  const getListWithCardSorted = useCallback(() => {
    const configListBoard = allConfigListBoard[list._id];
    if (configListBoard && !isEmpty(configListBoard)) {
      const items = list;

      if (configListBoard?.sortAlphabetic) {
        if (configListBoard?.sortAlphabetic === 'obverse') {
          items.cards = sortListByStringValue(items.cards, 'name');
        } else {
          items.cards = reverseSortListByStringValue(items.cards, 'name');
        }
      }

      if (configListBoard?.sortDueDate) {
        if (configListBoard?.sortDueDate === 'obverse') {
          items.cards = sortListByDateValue(items.cards, 'dueDate');
        } else {
          items.cards = reverseSortListByDateValue(items.cards, 'dueDate');
        }
      }

      if (configListBoard?.sortCreateDate) {
        if (configListBoard?.sortCreateDate === 'obverse') {
          items.cards = sortListByDateValue(items.cards, 'createdAt');
        } else {
          items.cards = reverseSortListByDateValue(items.cards, 'createdAt');
        }
      }

      return items;
    }
    return list;
  }, [list, allConfigListBoard]);
  const listWithCardDisplayed = getListWithCardSorted();

  useEffect(() => {
    const currentListBoardConfig = getStore(
      `confListKanbanCheckSorting-${list._id}`,
      { parse: true },
    );
    if (currentListBoardConfig && !isEmpty(currentListBoardConfig)) {
      setConfigListBoard({
        [list._id]: currentListBoardConfig,
      });
    }
  }, []);

  return (
    <div
      id={idContainer}
      className={styles.ListContainer__listCardsSection}
    >
      <OverlayButton wait={`listCards-${list._id}`} loadings={localLoadings}>
        <Droppable key={list._id} droppableId={list._id} direction="vertical" type="card">
          {(providedDroppable) => (
            <div
              id={idContent}
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
              className={styles.ListDroppable}
            >
              {listWithCardDisplayed.cards.map((card, index) => {
                if (!checkIfUserHaveAccessToDataV3(card, user, currentRoleUser)) {
                  return null;
                }
                return (
                  <>
                    {card.notDisplayed ? null : (
                      <div className={styles.card}>
                        <Draggable key={card._id} draggableId={card._id} index={index}>
                          {(provided) => (
                            <>
                              <OverlayButton wait={`moveCard-${card._id}`} anchorId={cardUpdateListPos ? `moveCard-${cardUpdateListPos?.draggableId}` : `moveCard-${card._id}`} loadings={loadings}>
                                <SmallCard
                                  provided={provided}
                                  innerRef={provided.innerRef}
                                  name={card.name}
                                  desc={card.desc}
                                  labels={card.labels}
                                  members={card.members}
                                  dueDate={card.dueDate}
                                  startDate={card.startDate}
                                  comments={card.comments}
                                  allChecklistItemsCount={card.allChecklistItemsCount}
                                  attachments={card.attachments}
                                  archived={card.archived.status}
                                  complete={card.complete}
                                  isCardPublic={card?.isPublic}
                                  cardId={card?._id}
                                  boardId={card?.board}
                                  handleShowEdit={handleShowEditCardMenu}
                                  handleCancelEditCardMenu={handleCancelEditCardMenu}
                                  handleCancelAllCardMenu={handleCancelAllCardMenu}
                                />
                              </OverlayButton>
                              {showEdit === card._id
                                ? (
                                  <EditCardMenu
                                    handleShowMove={handleShowMove}
                                    handleShowCopy={handleShowCopy}
                                    handleShowLabel={handleShowLabel}
                                    handleShowDueDate={handleShowDueDate}
                                    handleShowMember={handleShowMember}
                                    handleShowNote={handleShowNote}
                                    handleShowName={handleShowName}
                                    handleShowQuickComment={handleShowQuickComment}
                                    handleCancelAllCardMenu={handleCancelAllCardMenu}
                                    card={card}
                                    list={list}
                                  />
                                ) : null}
                            </>
                          )}
                        </Draggable>
                      </div>
                    )}
                  </>
                );
              })}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </OverlayButton>
    </div>
  );
}

ListCard.propTypes = {
  indexListCard: PropTypes.number.isRequired,
  showEdit: PropTypes.string.isRequired,
  list: PropTypes.object.isRequired,
  cardUpdateListPos: PropTypes.object.isRequired,
  allConfigListBoard: PropTypes.object,
  loadings: PropTypes.array.isRequired,
  handleShowEditCardMenu: PropTypes.func.isRequired,
  handleCancelEditCardMenu: PropTypes.func.isRequired,
  handleCancelAllCardMenu: PropTypes.func.isRequired,
  handleShowMove: PropTypes.func.isRequired,
  handleShowCopy: PropTypes.func.isRequired,
  handleShowLabel: PropTypes.func.isRequired,
  handleShowDueDate: PropTypes.func.isRequired,
  handleShowMember: PropTypes.func.isRequired,
  handleShowNote: PropTypes.func.isRequired,
  handleShowName: PropTypes.func.isRequired,
  handleShowQuickComment: PropTypes.func.isRequired,
  setConfigListBoard: PropTypes.func.isRequired,
  shouldFetch: PropTypes.number.isRequired,
  handleAddListCompleteFetchCards: PropTypes.func.isRequired,
};

ListCard.defaultProps = {
  allConfigListBoard: {},
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const {
    indexListCard, showEdit, list, cardUpdateListPos,
    loadings, allConfigListBoard, shouldFetch, handleAddListCompleteFetchCards,
  } = nextProps;
  const isStringNumberTheSame = prevProps.indexListCard === indexListCard
  && prevProps.showEdit === showEdit;
  const isObjectTheSame = isEqual(prevProps.list, list)
  && isEqual(prevProps.cardUpdateListPos, cardUpdateListPos)
  && isEqual(
    prevProps.allConfigListBoard?.[list?._id],
    allConfigListBoard?.[list?._id],
  );
  const isArrayTheSame = isEqual(prevProps.loadings, loadings);
  const isShouldFetchTheSame = isEqual(prevProps.shouldFetch, shouldFetch);
  const isHandleAddListCompleteFetchCardsTheSame = prevProps.handleAddListCompleteFetchCards
  === handleAddListCompleteFetchCards;
  const isTheSame = isStringNumberTheSame
  && isObjectTheSame
  && isArrayTheSame
  && isHandleAddListCompleteFetchCardsTheSame
  && isShouldFetchTheSame;
  return isTheSame;
};

export default memo(ListCard, isComponentDataEqual);
