import React, {
  useState, useContext, useEffect, useCallback, memo,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isNumber } from 'lodash';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../reducers/reducer';
import styles from './MainGroupChatSection.module.css';
import { ChatActions, NotificationActions, TeamActions } from '../../../actions';
import { ApiConstants, ChatConstants, NotificationConstants } from '../../../constants';
import { useDropZoneFile } from '../../../hooks/DropFileHook';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import CreateMessage from '../../PrivateChatPage/MainChatSection/CreateMessage/CreateMessage';
import FetchGroupMessages from './FetchGroupMessages/FetchGroupMessages';
import { initiateTeamMembers } from '../../../actions/TeamActions';
import UserTyping from '../../../components/Container/UserTyping/UserTyping';
import { useUserTypingGroupChatSocket } from '../../../hooks/UserTypingHooks';
import { useMarkReadNotificationOnPageOpen, useNotificationCounterSocket, useNotificationUnreadForGroupChatSocket } from '../../../hooks/NotificationHooks';
import { markReadGroupChatNotifications, markReadSingleNotification } from '../../../actions/NotificationActions';
import { useUploadForm } from '../../../hooks/HelperHooks';
import { NOTIF_ACTIVITY_API_URL } from '../../../constants/ApiConstants';
import RepliedMessageOnCreateMessage from '../../PrivateChatPage/MainChatSection/RepliedMessageOnCreateMessage/RepliedMessageOnCreateMessage';
import { blobToFile } from '../../../actions/AttachmentActions';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const MainGroupChatSection = () => {
  const [{
    user, currentTeam,
    currentCompany,
    allUserTypingGroupChat,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showReply, setShowReply] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isNeedCheckLoadMore, setCheckLoadMore] = useState(false);
  const [groupChatNotifications, setGroupChatNotifications] = useState([]);
  const [chatType, setChatType] = useState('groupChat');
  const [triggerFocusEditor, setTriggerFocusEditor] = useState();
  const [repliedGroupMessage, setRepliedGroupMessage] = useState({});

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const { companyId, teamId, groupChatId } = params;

  const { socket } = useUserTypingGroupChatSocket({
    companyId,
    groupChatId,
    teamId,
    userId: user?._id,
  }, dispatch);

  useNotificationUnreadForGroupChatSocket({
    userId: user?._id,
    companyId,
    teamId,
    setGroupChatNotifications,
  }, dispatch);

  useEffect(() => {
    if (groupChatNotifications.length < 1) return;
    if (!groupChatId) return;
    if (isLoading) return;

    const updateApiNotification = () => {
      try {
        markReadGroupChatNotifications({
          groupChatId, companyId, notifications: groupChatNotifications,
        });
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    updateApiNotification();
  }, [groupChatNotifications, isLoading]);

  useEffect(() => {
    if (loadings === undefined) return;
    const checkWait = loadings.filter((loading) => loading === 'listGroupChatMessages');

    if (checkWait.length > 0) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [loadings]);

  const isGroupChatsLengthAtTheLimitOrAbove = useCallback(
    (chats) => isNumber(chats?.groupChats?.length)
      && chats?.groupChats?.length >= ChatConstants.limitChat, [],
  );

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('listGroupChatMessages', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiGroupChatDetail = async () => {
      try {
        const resultV2 = await ChatActions.initiateGroupChatV2(
          { groupChatId, companyId, teamId }, dispatch,
        );
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        if (isGroupChatsLengthAtTheLimitOrAbove(resultV2)) setCheckLoadMore(true);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('listGroupChatMessages', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    fetchApiGroupChatDetail();
  }, [location]);

  useEffect(() => {
    const initiateNotificationsApi = async () => {
      try {
        const result = await NotificationActions.initiateUnreadNotificationsForGroupChat({
          companyId,
          teamId,
          userId: user?._id,
        });

        setGroupChatNotifications(result?.data.data);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };

    initiateNotificationsApi();
  }, [location]);

  const initiateTeamMembersApi = async () => {
    try {
      await initiateTeamMembers({
        teamId,
        companyId,
        currentTeam,
      }, dispatch);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      enqueueSnackbar(status?.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (!currentTeam?._id || currentTeam?._id !== teamId) return;
    initiateTeamMembersApi();
  }, [currentTeam]);

  const handleLoadingUpload = (value) => {
    setLoadingUpload(value);
  };

  // Change default height div create message
  const [currentHeightDivCreateMessage, setCurrentHeightDivCreateMessage] = useState(55);
  const defaultHeightDivRepliedMessage = 100;
  const [style, setStyle] = useState({
    height: `calc(100vh - (34px + 34px + ${currentHeightDivCreateMessage}px))`,
  });

  const handleChangeHeightDivMessagesSection = (height) => {
    let newStyle;
    if (showReply) {
      newStyle = {
        height: `calc(100vh - (34px + 34px + ${defaultHeightDivRepliedMessage}px + ${height}px))`,
      };
    } else {
      newStyle = {
        height: `calc(100vh - (34px + 34px + ${height}px))`,
      };
    }

    setStyle(newStyle);
    setCurrentHeightDivCreateMessage(height);
  };

  const handleOnOpenReplyMessageSection = (messageFile) => {
    // set replied group message item
    setRepliedGroupMessage(messageFile);

    // set style div create message
    const newStyle = {
      height: `calc(100vh - (34px + 34px + ${defaultHeightDivRepliedMessage}px + ${currentHeightDivCreateMessage}px))`,
    };
    setStyle(newStyle);

    // show replied message on create message
    setShowReply(true);

    // change type to add payload when postMessage to api
    setChatType('replyGroupChat');

    // trigger auto focus on create message
    setTriggerFocusEditor(Math.random());
  };

  const handleModifyOptionalParamsOnUploadAttachment = () => {
    let optionalParams;
    if (chatType === 'replyGroupChat') {
      optionalParams = {
        replyTo: repliedGroupMessage?._id,
        replyToType: repliedGroupMessage?.type,
      };
    }
    return optionalParams;
  };

  const handleOnCloseReplyMessageSection = () => {
    setShowReply(false);
    const newStyle = {
      height: `calc(100vh - (34px + 34px + ${currentHeightDivCreateMessage}px))`,
    };
    setStyle(newStyle);
    setChatType('groupChat');
    setRepliedGroupMessage({});
    // trigger auto focus on create message
    setTriggerFocusEditor(Math.random());
  };

  const {
    progressUpload,
    uploadForm,
    listFilesBeingUploaded,
  } = useUploadForm(`${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.GROUP_CHAT_ATTACHMENT({ groupChatId })}`, companyId, teamId);

  const uploadFileByDrop = useCallback(async (files) => {
    if (!files) return;

    const data = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      data.append('file', files[i]);
    }

    let optionalParams;

    if (chatType === 'replyGroupChat') {
      optionalParams = {
        replyTo: repliedGroupMessage?._id,
        replyToType: repliedGroupMessage?.type,
      };
    }

    handleLoadingUpload(true);

    try {
      const result = await uploadForm(data, false, optionalParams);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (err) {
      const getErrorMessageDropzoneFile = files?.[0]?.errors?.[0];
      const messageError = getErrorMessageDropzoneFile === undefined
        ? err : getErrorMessageDropzoneFile;
      const status = handleStatusMsg(messageError, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      handleLoadingUpload(false);
      handleOnCloseReplyMessageSection();
    }
  }, [chatType, repliedGroupMessage?._id]);

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length < 1) {
      await uploadFileByDrop(fileRejections);
    } else {
      await uploadFileByDrop(acceptedFiles);
    }
  }, [chatType, repliedGroupMessage?._id]);

  const {
    getRootProps,
    isDragActive,
  } = useDropZoneFile({
    onDrop,
  });

  const handlePostAttachmentVoiceNote = async (blobFile) => {
    const file = await blobToFile(blobFile);
    await uploadFileByDrop([file]);
  };

  return (
    <div className={containerWithSidebarStyles[isSidebarOpen]} style={style}>
      <div className={styles.dropzone} {...getRootProps()}>
        <div className={styles.box}>
          <div className={styles.messages} style={style}>
            <OverlayButton
              spinnerSize="lg"
              wait="listGroupChatMessages"
              loadings={loadings}
              disableChildrenOnWait
              noBackgroundOverlay
              style={style}
            >
              <FetchGroupMessages
                isNeedCheckLoadMore={isNeedCheckLoadMore}
                checkLengthChatToLimit={isGroupChatsLengthAtTheLimitOrAbove}
                loadingUpload={loadingUpload}
                listFilesBeingUploaded={listFilesBeingUploaded}
                progressUpload={progressUpload}
                handleOnOpenReplyMessageSection={handleOnOpenReplyMessageSection}
              />
            </OverlayButton>
          </div>
          {isDragActive ? (
            <>
              <div className={`${styles.box} ${styles.backgroundOverlay}`} />
              <div className={`${styles.box} ${styles.contentOverlay}`}>
                <h1>Lepaskan file untuk diunggah.</h1>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.typingIndicator}>
        <UserTyping
          allUserTyping={allUserTypingGroupChat}
          companyMembers={currentCompany?.members}
        />
      </div>
      {showReply && (
        <div className={styles.reply}>
          <RepliedMessageOnCreateMessage
            handleOnCloseReplyMessageSection={handleOnCloseReplyMessageSection}
            replyMessage={repliedGroupMessage}
          />
        </div>
      )}
      <div className={styles.create}>
        {isLoading ? null : (
          <CreateMessage
            handleChangeHeightDivMessagesSection={handleChangeHeightDivMessagesSection}
            handleOnCloseReplyMessageSection={handleOnCloseReplyMessageSection}
            setShowReply={setShowReply}
            showReply={showReply}
            handleLoading={handleLoadingUpload}
            type={chatType}
            triggerFocusEditor={triggerFocusEditor}
            chatWith={currentTeam.members}
            socket={socket}
            handleUploadFiles={uploadForm}
            handleModifyOptionalParamsOnUploadAttachment={
              handleModifyOptionalParamsOnUploadAttachment
            }
            repliedGroupMessage={repliedGroupMessage}
            handlePostAttachmentVoiceNote={handlePostAttachmentVoiceNote}
          />
        )}
      </div>
    </div>
  );
};

export default memo(MainGroupChatSection);
