const DEFAULT_HEADER = { Authorization: `jwt ${localStorage.getItem('token')}` };
const DEFAULT_OPTION = { withCredentials: true, headers: DEFAULT_HEADER };
const TIMEOUT_UPLOAD = { timeout: 99999999 };

// API SERVER URL
const PRIMARY_API_URL = process.env.REACT_APP_PRIMARY_API_URL ?? 'http://localhost:5070';
const METRIC_API_URL = process.env.REACT_APP_METRIC_API_URL ?? 'http://localhost:5010';
const REALTIME_API_URL = process.env.REACT_APP_REALTIME_API_URL ?? 'http://localhost:5015';
const NOTIF_ACTIVITY_API_URL = process.env.REACT_APP_NOTIF_ACTIVITY_API_URL ?? 'http://localhost:5075';

const URL_V1 = {
  SIGN_IN_CHECK: () => '/api/v1/auth/google/signin/check',
  REQUEST_USER_ONLY: () => '/api/v1/auth/google/signin/request-user-only',
  REFRESH_TOKEN: () => '/api/v1/auth/refreshToken',
  DESTROY_REFRESH_TOKEN: () => '/api/v1/auth/destroyRefreshToken',
  REGISTER_BY_EMAIL_PASSWORD: () => '/api/v1/auth/email/register',
  EMAIL_REGISTRATION_LINK_VERIFICATION: () => '/api/v1/auth/email/register/verification',
  LOGIN_BY_EMAIL_PASSWORD: () => '/api/v1/auth/email/login',
  REQUEST_RESET_PASSWORD: () => '/api/v1/auth/email/password/reset',
  RESET_THEN_CREATE_NEW_PASSWORD: () => '/api/v1/auth/email/password/reset/new',
  CHANGE_PASSWORD: () => '/api/v1/auth/email/password/change',
  ADD_NEW_PASSWORD_FOR_USER_WITHOUT_PASSWORD: () => '/api/v1/auth/email/password/new',
  REQUEST_CHANGE_EMAIL: () => '/api/v1/auth/email/change',
  CHANGE_EMAIL_LINK_VERIFICATION: () => '/api/v1/auth/email/change/verification',
  CHECK_USER_HAS_PASSWORD: ({ userId }) => `/api/v1/auth/email/password/user/${userId}`,
  CHECK_SUBSCRIPTION: ({ companyId }) => `/api/v1/companies/${companyId}/check-subscription`,
  COMPANY: ({ companyId }) => `/api/v1/companies/${companyId}`,
  COMPANY_LOGO: ({ companyId }) => `/api/v1/companies/${companyId}/logo`,
  COMPANY_MEMBER: ({ companyId, memberId }) => `/api/v1/companies/${companyId}/members/${memberId}`,
  USER: ({ userId }) => `/api/v1/users/${userId}`,
  USER_PHOTO: ({ userId }) => `/api/v1/users/${userId}/photo`,
  SCHEDULE_OCCURRENCES: ({ scheduleId }) => `/api/v1/schedules/${scheduleId}/occurrences`,
  SCHEDULE_EVENTS: ({ scheduleId }) => `/api/v1/schedules/${scheduleId}/events`,
  SCHEDULE: ({ scheduleId }) => `/api/v1/schedules/${scheduleId}`,
  SCHEDULE_PUBLIC: ({ scheduleId }) => `/api/v1/schedules/${scheduleId}/public-calendar`,
  EVENT: ({ eventId }) => `/api/v1/events/${eventId}`,
  EVENT_ARCHIVE: ({ eventId }) => `/api/v1/events/${eventId}/archived`,
  EVENT_TO_RECURRING: ({ eventId }) => `/api/v1/events/${eventId}/occurrences/single-to-recurring`,
  OCCURRENCE: ({ eventId, occurrenceId }) => `/api/v1/events/${eventId}/occurrences/${occurrenceId}`,
  EVENT_OCCURRENCES: ({ eventId }) => `/api/v1/events/${eventId}/occurrences`,
  COMPANIES: () => '/api/v1/companies/',
  QUESTION_DETAIL: ({ questionId }) => `/api/v1/questions/${questionId}`,
  QUESTION_ARCHIVE: ({ questionId }) => `/api/v1/questions/${questionId}/archived`,
  RECENT_CHATS: () => '/api/v1/chats',
  CHAT_ATTACHMENT: ({ chatId }) => `/api/v1/chats/${chatId}/attachments`,
  CHAT_MESSAGE: ({ chatId }) => `/api/v1/chats/${chatId}/messages`,
  GROUP_CHAT_ATTACHMENT: ({ groupChatId }) => `/api/v1/group-chats/${groupChatId}/attachments`,
  GROUP_CHAT_ATTACHMENT_DELETE: ({ groupChatId, messageId }) => `/api/v1/group-chats/${groupChatId}/attachments/${messageId}`,
  GROUP_CHAT_MESSAGE: ({ groupChatId }) => `/api/v1/group-chats/${groupChatId}/messages`,
  GROUP_CHAT_MESSAGE_DELETE: ({ groupChatId, messageId }) => `/api/v1/group-chats/${groupChatId}/messages/${messageId}`,
  BLAST_CREATE: ({ blastId }) => `/api/v1/blasts/${blastId}`,
  NOTIFICATIONS: () => '/api/v1/notifications',
  NOTIFICATION: ({ notificationId }) => `/api/v1/notifications/${notificationId}`,
  CHECKIN_CREATE: ({ checkInId }) => `/api/v1/check-ins/${checkInId}/questions`,
  POST_DETAIL: ({ postId }) => `/api/v1/posts/${postId}`,
  BOARD: ({ boardId }) => `/api/v1/boards/${boardId}`,
  LIST: ({ listId }) => `/api/v1/lists/${listId}`,
  LISTS: () => '/api/v1/lists/',
  LISTS_CARD: ({ cardId }) => `/api/v1/lists/cards/${cardId}`,
  LISTS_POSITION: () => '/api/v1/lists/position',
  LIST_ARCHIVE: ({ listId }) => `/api/v1/lists/${listId}/archived`,
  LIST_UNARCHIVE: ({ listId }) => `/api/v1/lists/${listId}/unarchived`,
  LIST_COMPLETE_STATUS: ({ listId }) => `/api/v1/lists/${listId}/complete`,
  CARDS: () => '/api/v1/cards/',
  CARDS_POSITION: () => '/api/v1/cards/position',
  CARD: ({ cardId }) => `/api/v1/cards/${cardId}`,
  CARD_ARCHIVE: ({ cardId }) => `/api/v1/cards/${cardId}/archived`,
  CARDS_ARCHIVE: () => '/api/v1/cards/archived',
  CARD_UNARCHIVE: ({ cardId }) => `/api/v1/cards/${cardId}/unarchived`,
  DOC: ({ docId }) => `/api/v1/docs/${docId}`,
  DOC_ARCHIVE: ({ docId }) => `/api/v1/docs/${docId}/archived`,
  DOC_UNARCHIVE: ({ docId }) => `/api/v1/docs/${docId}/unarchived`,
  FILE: ({ fileId }) => `/api/v1/files/${fileId}`,
  FILE_ARCHIVE: ({ fileId }) => `/api/v1/files/${fileId}/archived`,
  FILE_UNARCHIVE: ({ fileId }) => `/api/v1/files/${fileId}/unarchived`,
  BUCKETS: () => '/api/v1/buckets',
  BUCKET: ({ bucketId }) => `/api/v1/buckets/${bucketId}`,
  BUCKET_ARCHIVE: ({ bucketId }) => `/api/v1/buckets/${bucketId}/archived`,
  BUCKET_UNARCHIVE: ({ bucketId }) => `/api/v1/buckets/${bucketId}/unarchived`,
  BUCKET_DOCS: ({ bucketId }) => `/api/v1/buckets/${bucketId}/docs`,
  TASK: () => '/api/v1/tasks/',
  TASK_COMPLETE: () => '/api/v1/tasks/complete',
  TEAMS: () => '/api/v1/teams',
  TEAM: ({ teamId }) => `/api/v1/teams/${teamId}`,
  TEAM_UNARCHIVE: ({ teamId }) => `/api/v1/teams/${teamId}/unarchived`,
  LABELS: () => '/api/v1/labels',
  ROLE_COMPANIES: ({ companyId }) => `/api/v1/role-companies/companies/${companyId}`,
  ROLE_COMPANY_USER: ({ companyId, userId }) => `/api/v1/role-companies/companies/${companyId}/users/${userId}`,
  ROLE_COMPANIES_SUPER_USERS: ({ companyId }) => `/api/v1/role-companies/companies/${companyId}/super-users`,
  ROLE_EXCEPTION_TEAMS: ({ teamId }) => `/api/v1/role-exceptions/teams/${teamId}`,
  ROLE_EXCEPTION_TEAM_USER: ({ teamId, userId }) => `/api/v1/role-exceptions/teams/${teamId}/users/${userId}`,
  ROLE_EXCEPTION_FEATURES: ({ featureId }) => `/api/v1/role-exceptions/features/${featureId}`,
  ROLE_EXCEPTION_FEATURE_USER: ({ featureId, userId }) => `/api/v1/role-exceptions/features/${featureId}/users/${userId}`,
  COUPONS: () => '/api/v1/coupons',
  TEAM_FAVORITES: ({ userId, companyId }) => `/api/v1/team-favorites/users/${userId}/companies/${companyId}/teams`,
  CHECKLISTS: ({ cardId }) => `/api/v1/cards/${cardId}/checklists`,
  CHECKLIST_NAME: ({ cardId, checklistId }) => `/api/v1/cards/${cardId}/checklists/${checklistId}/name`,
  CHECKLIST: ({ cardId, checklistId }) => `/api/v1/cards/${cardId}/checklists/${checklistId}`,
  CHECKLIST_MOVE: ({ cardId, checklistId }) => `/api/v1/cards/${cardId}/checklists/${checklistId}/move`,
  CHECKLIST_ITEMS: ({ cardId, checklistId }) => `/api/v1/cards/${cardId}/checklists/${checklistId}/items`,
  CHECKLIST_ITEM_NAME: ({ cardId, checklistId, checklistItemId }) => `/api/v1/cards/${cardId}/checklists/${checklistId}/items/${checklistItemId}/name`,
  CHECKLIST_ITEM_COMPLETE: ({ cardId, checklistId, checklistItemId }) => `/api/v1/cards/${cardId}/checklists/${checklistId}/items/${checklistItemId}/complete`,
  CHECKLIST_ITEM_DATE: ({ cardId, checklistId, checklistItemId }) => `/api/v1/cards/${cardId}/checklists/${checklistId}/items/${checklistItemId}/date`,
  CHECKLIST_ITEM_MOVE: ({ cardId, checklistId, checklistItemId }) => `/api/v1/cards/${cardId}/checklists/${checklistId}/items/${checklistItemId}/move`,
  CHECKLIST_ITEM: ({ cardId, checklistId, checklistItemId }) => `/api/v1/cards/${cardId}/checklists/${checklistId}/items/${checklistItemId}`,
  COPY_CARD: () => '/api/v1/cards/copy',
  SET_TEAM_FAVORITE: ({ userId, companyId, teamId }) => `/api/v1/team-favorites/users/${userId}/companies/${companyId}/teams/${teamId}/set`,
  UNSET_TEAM_FAVORITE: ({ userId, companyId, teamId }) => `/api/v1/team-favorites/users/${userId}/companies/${companyId}/teams/${teamId}/unset`,
  REORDER_TEAM_FAVORITE: ({ userId, companyId, teamId }) => `/api/v1/team-favorites/users/${userId}/companies/${companyId}/teams/${teamId}/reorder`,
  METRICS: () => '/api/v1/metrics/administrators',
  METRICS_COUNT: () => '/api/v1/metrics/administrators/count',
  METRICS_COUNT_DAILY: () => '/api/v1/metrics/administrators/count/daily',
  METRICS_COMPANY_ACTIVITIES: ({ companyId }) => `/api/v1/metrics-company-activities/administrators/companies/${companyId}`,
  METRICS_PUBLIC_VISITORS: () => '/api/v1/metrics-public/visitors',
  METRICS_VISITORS: () => '/api/v1/metrics/visitors',
  TRACTION_MODELS_COUNT_DAILY: () => '/api/v1/traction-models/administrators/count/daily',
  SEEN_CHAT_MESSAGE: ({ chatId, messageId }) => `/api/v1/seen/chats/${chatId}/messages/${messageId}`,
  SEEN_CHAT_ATTACHMENT: ({ chatId, attachmentId }) => `/api/v1/seen/chats/${chatId}/attachments/${attachmentId}`,
  SEEN_GROUP_CHAT_MESSAGE: ({ groupChatId, groupMessageId }) => `/api/v1/seen/group-chats/${groupChatId}/messages/${groupMessageId}`,
  SEEN_GROUP_CHAT_ATTACHMENT: ({ groupChatId, attachmentId }) => `/api/v1/seen/group-chats/${groupChatId}/attachments/${attachmentId}`,
  SEEN_CARD: ({ cardId }) => `/api/v1/seen/cards/${cardId}`,
  SEEN_CARD_COMMENT: ({ cardId, commentId }) => `/api/v1/seen/cards/${cardId}/comments/${commentId}`,
  SEEN_CARD_DISCUSSION: ({ cardId, commentId, discussionId }) => `/api/v1/seen/cards/${cardId}/comments/${commentId}/discussions/${discussionId}`,
  SEEN_POST: ({ postId }) => `/api/v1/seen/posts/${postId}`,
  SEEN_POST_COMMENT: ({ postId, commentId }) => `/api/v1/seen/posts/${postId}/comments/${commentId}`,
  SEEN_POST_DISCUSSION: ({ postId, commentId, discussionId }) => `/api/v1/seen/posts/${postId}/comments/${commentId}/discussions/${discussionId}`,
  SEEN_DOC: ({ docId }) => `/api/v1/seen/docs/${docId}`,
  SEEN_DOC_COMMENT: ({ docId, commentId }) => `/api/v1/seen/docs/${docId}/comments/${commentId}`,
  SEEN_DOC_DISCUSSION: ({ docId, commentId, discussionId }) => `/api/v1/seen/docs/${docId}/comments/${commentId}/discussions/${discussionId}`,
  SEEN_FILE: ({ fileId }) => `/api/v1/seen/files/${fileId}`,
  SEEN_FILE_COMMENT: ({ fileId, commentId }) => `/api/v1/seen/files/${fileId}/comments/${commentId}`,
  SEEN_FILE_DISCUSSION: ({ fileId, commentId, discussionId }) => `/api/v1/seen/files/${fileId}/comments/${commentId}/discussions/${discussionId}`,
  SEEN_QUESTION_COMMENT: ({ questionId, commentId }) => `/api/v1/seen/questions/${questionId}/comments/${commentId}`,
  SEEN_QUESTION_DISCUSSION: ({ questionId, commentId, discussionId }) => `/api/v1/seen/questions/${questionId}/comments/${commentId}/discussions/${discussionId}`,
  SEEN_EVENT: ({ eventId }) => `/api/v1/seen/events/${eventId}`,
  SEEN_EVENT_COMMENT: ({ eventId, commentId }) => `/api/v1/seen/events/${eventId}/comments/${commentId}`,
  SEEN_EVENT_DISCUSSION: ({ eventId, commentId, discussionId }) => `/api/v1/seen/events/${eventId}/comments/${commentId}/discussions/${discussionId}`,
  SEEN_OCCURRENCE: ({ eventId }) => `/api/v1/seen/events/${eventId}/occurrences`,
  SEEN_OCCURRENCE_COMMENT: ({ eventId, commentId, occurrenceId }) => `/api/v1/seen/events/${eventId}/occurrences/${occurrenceId}/comments/${commentId}`,
  SEEN_OCCURRENCE_DISCUSSION: ({
    eventId, commentId, occurrenceId, discussionId,
  }) => `/api/v1/seen/events/${eventId}/occurrences/${occurrenceId}/comments/${commentId}/discussions/${discussionId}`,
  REPORTS_CARDS_TEAM_MEMBERS: ({ memberId, teamId }) => `/api/v1/reports/cards/members/${memberId}/teams/${teamId}`,
  REPORTS_CARDS_TEAM_MEMBERS_COUNT: ({ memberId, teamId }) => `/api/v1/reports/cards/members/${memberId}/teams/${teamId}/counts`,
  REPORTS_RECAP_MONTH_WEEKLY_CARDS_TEAM_MEMBERS_COUNT: ({ memberId, teamId }) => `/api/v1/reports/recap/month/weekly/cards/members/${memberId}/teams/${teamId}/counts`,
  REPORTS_RECAP_WEEK_WEEKLY_CARDS_TEAM_MEMBERS: ({ memberId, teamId }) => `/api/v1/reports/recap/week/weekly/cards/members/${memberId}/teams/${teamId}`,
  ACTIVITIES: () => '/api/v1/activities',
  ACTIVITIES_CARD: ({ cardId }) => `/api/v1/activities/cards/${cardId}`,
  ACTIVITIES_POST: ({ postId }) => `/api/v1/activities/posts/${postId}`,
  ACTIVITIES_QUESTION: ({ questionId }) => `/api/v1/activities/questions/${questionId}`,
  ACTIVITIES_EVENT: ({ eventId }) => `/api/v1/activities/events/${eventId}`,
  ACTIVITIES_OCCURRENCE: ({ eventId, occurrenceId }) => `/api/v1/activities/events/${eventId}/occurrences/${occurrenceId}`,
  ACTIVITIES_DOC: ({ docId }) => `/api/v1/activities/docs/${docId}`,
  ACTIVITIES_FILE: ({ fileId }) => `/api/v1/activities/files/${fileId}`,
  ACTIVITIES_BUCKET: ({ bucketId }) => `/api/v1/activities/buckets/${bucketId}`,
  MY_TASK_CARD_LIST_ALL: () => '/api/v1/my-tasks/cards/list/all',
  MY_TASK_CARD_LIST_STATUS: () => '/api/v1/my-tasks/cards/list/status',
  MY_TASK_CARD_LIST_TEAM: () => '/api/v1/my-tasks/cards/list/team',
  MY_TASK_CARD_CALENDAR_ALL: () => '/api/v1/my-tasks/cards/calendar/all',
  MY_TASK_CARD_KANBAN_ALL: () => '/api/v1/my-tasks/cards/kanban/status',
};

const URL_V2 = {
  CHAT: ({ chatId }) => `/v2/chats/${chatId}`,
  GROUP_CHAT: ({ groupChatId }) => `/v2/group-chats/${groupChatId}`,
  CHECK_IN: ({ checkInId }) => `/v2/check-ins/${checkInId}`,
  QUESTION_DETAIL: ({ checkInId, questionId }) => `/v2/check-ins/${checkInId}/questions/${questionId}`,
  QUESTION_COMMENT: ({ questionId }) => `/v2/questions/${questionId}/comments`,
  NOTIFICATIONS: () => '/v2/notifications',
  DISCUSSION_COMMENT_DETAIL: ({ commentId }) => `/v2/comments/${commentId}`,
  DISCUSSION_COMMENT_LIST: ({ commentId }) => `/v2/comments/${commentId}/discussions`,
  BLAST: ({ blastId }) => `/v2/blasts/${blastId}`,
  POST_COMMENT: ({ postId }) => `/v2/posts/${postId}/comments`,
  BOARD: ({ boardId }) => `/v2/boards/${boardId}`,
  BOARD_LIST: ({ boardId }) => `/v2/boards/${boardId}/lists`,
  BOARD_CARDS: ({ boardId }) => `/v2/boards/${boardId}/cards`,
  BOARD_LISTS_ONLY: ({ boardId }) => `/v2/boards/${boardId}/lists/only`,
  BOARD_CARD_LIST_ALL: ({ boardId }) => `/v2/boards/${boardId}/list/all`,
  BOARD_CARD_CALENDAR_ALL: ({ boardId }) => `/v2/boards/${boardId}/calendar/all`,
  CARD: ({ cardId }) => `/v2/cards/${cardId}`,
  CARD_LIST: ({ cardId }) => `/v2/cards/${cardId}/list`,
  LIST_CARDS: ({ listId }) => `/v2/lists/${listId}/cards`,
  CARD_COMMENT: ({ cardId }) => `/v2/cards/${cardId}/comments`,
  POST_DETAIL: ({ blastId, postId }) => `/v2/blasts/${blastId}/posts/${postId}`,
  SCHEDULE: ({ scheduleId }) => `/v2/schedules/${scheduleId}`,
  EVENT: ({ eventId }) => `/v2/events/${eventId}`,
  EVENT_NEW: ({ eventId }) => `/v2/events/${eventId}/new`,
  EVENT_COMMENT: ({ eventId }) => `/v2/events/${eventId}/comments`,
  EVENT_OCCURRENCE: ({ eventId, occurrenceId }) => `/v2/events/${eventId}/occurrences/${occurrenceId}`,
  EVENT_OCCURRENCE_NEW: ({ eventId, occurrenceId }) => `/v2/events/${eventId}/occurrences/${occurrenceId}/new`,
  EVENT_OCCURRENCE_COMMENT: ({ eventId, occurrenceId }) => `/v2/events/${eventId}/occurrences/${occurrenceId}/comments`,
  DOC: ({ docId }) => `/v2/docs/${docId}`,
  DOC_COMMENT: ({ docId }) => `/v2/docs/${docId}/comments`,
  FILE: ({ fileId }) => `/v2/files/${fileId}`,
  FILE_COMMENT: ({ fileId }) => `/v2/files/${fileId}/comments`,
  TASK: () => '/v2/tasks/',
  TASK_DUE: () => '/v2/tasks/due',
  TASK_EVENT: () => '/v2/tasks/schedules',
  TASK_COMPLETE: () => '/v2/tasks/complete',
  TASK_REPORT: () => '/v2/tasks/report',
  INVITE_COMPANY_MEMBERS: ({ companyId }) => `/v2/invites/companies/${companyId}`,
  TEAMS: () => '/v2/teams',
  TEAM_NAME: ({ teamId }) => `/v2/teams/${teamId}/name`,
  TEAM_MEMBERS: ({ teamId }) => `/v2/teams/${teamId}/members`,
  TEAM_MEMBERS_ROLE: ({ teamId }) => `/v2/teams/${teamId}/members-role`,
  TEAM_OVERVIEW_ID: ({ teamId }) => `/v2/teams/${teamId}/overview-id`,
  PLAN: () => '/v2/plans',
  SUBSCRIPTIONS: () => '/v2/subscriptions',
  SUBSCRIPTION: ({ companyId }) => `/v2/subscriptions/${companyId}`,
  INVOICES: () => '/v2/invoices',
  INVOICE_EXPIRE: ({ invoiceId }) => `/v2/invoices/${invoiceId}/expire`,
  INVOICES_COUNTER_UNPAID: () => '/v2/invoices/counter-unpaid',
  ORDERS: () => '/v2/orders',
  ORDER: ({ orderId }) => `/v2/orders/${orderId}`,
  ORDER_LIST_ADDONS: () => '/v2/orders/list-addons-with-qty',
  ORDER_COUPON: ({ orderId }) => `/v2/orders/${orderId}/coupon`,
  BUCKET_DOCS: ({ bucketId }) => `/v2/buckets/${bucketId}/docs`,
  BUCKET_FILES: ({ bucketId }) => `/v2/buckets/${bucketId}/files`,
  BUCKET_BUCKETS: ({ bucketId }) => `/v2/buckets/${bucketId}/buckets`,
  BUCKET_TITLE: ({ bucketId }) => `/v2/buckets/${bucketId}/title`,
  BUCKET_SUBSCRIBERS: ({ bucketId }) => `/v2/buckets/${bucketId}/subscribers`,
  BUCKET_NEW: ({ bucketId }) => `/v2/buckets/${bucketId}/new`,
  SEARCH_TEAMS: () => '/v2/search/teams',
  SEARCH_CARDS: () => '/v2/search/cards',
  SEARCH_POSTS: () => '/v2/search/posts',
  SEARCH_QUESTIONS: () => '/v2/search/questions',
  SEARCH_EVENTS: () => '/v2/search/events',
  SEARCH_CHATS: () => '/v2/search/chats',
  SEARCH_GROUP_CHATS: () => '/v2/search/group-messages',
  SEARCH_MEMBERS: () => '/v2/search/members',
  SEARCH_DOCS: () => '/v2/search/docs',
  SEARCH_FILES: () => '/v2/search/files',
  SEARCH_BUCKETS: () => '/v2/search/buckets',
  SEARCH_COMMENTS: () => '/v2/search/comments',
  SEARCH_DISCUSSIONS: () => '/v2/search/discussions',
};

export {
  DEFAULT_HEADER,
  DEFAULT_OPTION,
  TIMEOUT_UPLOAD,
  URL_V1,
  URL_V2,
  PRIMARY_API_URL,
  METRIC_API_URL,
  REALTIME_API_URL,
  NOTIF_ACTIVITY_API_URL,
};
