import React, {
  memo, useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@mui/icons-material/Sort';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton, useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import styles from './CardListItemLabels.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import CardListColumnBox from '../../CardListColumnBox/CardListColumnBox';
import Label from '../../../UI/Label/Label';
import { limitChar } from '../../../../utilities/stringUtil';
import { cutArray } from '../../../../utilities/arrayUtil';
import { useDelayShowHideHandler } from '../../../../hooks/HelperHooks';
import ManageLabelsContainer from '../../../../pages/ManageLabelsContainer/ManageLabelsContainer';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { BoardActions } from '../../../../actions';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';

const CardListItemLabels = ({
  item,
  widthPercentage,
  variantBorder,
  setSelected,
  triggerScrollIntoView,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [{ currentBoard, isSidebarOpen, currentBoardLists }, dispatch] = useContext(GlobalContext);
  const labels = item.labels.length > 0 ? cutArray(item.labels, 3) : [];
  const [isLoadingLabels, setIsLoadingLabels] = useState(false);

  const [
    showManageLabels,
    handleShowManageLabels,
    handleHideManageLabels,
  ] = useDelayShowHideHandler();

  const initiateBoardV2ToGetCurrentBoardLabels = async () => {
    try {
      await BoardActions.initiateBoardV2(
        {
          boardId: item.board,
          companyId: item.company,
          teamId: item.team._id,
          currentBoard,
          currentBoardLists,
        },
        dispatch,
      );
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setIsLoadingLabels(false);
      handleShowManageLabels();
    }
  };

  const checkShouldGetLabelsOrNot = () => {
    setIsLoadingLabels(true);
  };

  useEffect(() => {
    if (!isLoadingLabels) return;

    setSelected(item._id);

    if (currentBoard?._id === item.board) {
      setIsLoadingLabels(false);
      handleShowManageLabels();
      return;
    }

    initiateBoardV2ToGetCurrentBoardLabels();
  }, [isLoadingLabels]);

  useEffect(() => {
    if (!showManageLabels) return;

    triggerScrollIntoView();
  }, [showManageLabels]);

  return (
    <CardListColumnBox
      variantBorder={variantBorder}
      widthPercentage={widthPercentage}
      variantCursor="pointer"
      onClick={showManageLabels ? () => null : checkShouldGetLabelsOrNot}
    >
      <div className={styles.container}>
        {!isLoadingLabels && labels.map((label, index) => {
          if (isMobile && index > 1) return <></>;
          return (
            <Label
              clicked={() => null}
              color={label.color?.name}
              name={limitChar(label.name, (isMobile || isSidebarOpen) ? 3 : 5)}
            />
          );
        })}
        {isLoadingLabels && <Skeleton width={80} />}
        {showManageLabels
         && (
         <ManageLabelsContainer
           cancel={handleHideManageLabels}
           card={item}
           isLoading={isLoadingLabels}
         />
         )}
      </div>
    </CardListColumnBox>
  );
};

CardListItemLabels.propTypes = {
  item: PropTypes.object,
  widthPercentage: PropTypes.number,
  variantBorder: PropTypes.string.isRequired,
  setSelected: PropTypes.func,
  triggerScrollIntoView: PropTypes.func,
};

CardListItemLabels.defaultProps = {
  item: {},
  widthPercentage: 100,
  setSelected: () => null,
  triggerScrollIntoView: () => null,
};

export default memo(CardListItemLabels);
