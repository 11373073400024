import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ButtonDiv from '../../../../components/UI/Button/ButtonDiv/ButtonDiv';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import styles from './TeamNameOption.module.css';

const TeamNameOption = ({
  teams,
  handleUpdateDestTeamId,
  handleUpdateViewTeamName,
  cancel,
  handleFetchAllLists,
}) => {
  const [groupedTeams, setGroupedTeams] = useState([]);

  useEffect(() => {
    const grouped = teams.reduce((accumulator, team) => {
      const { type } = team;
      if (!accumulator[type]) {
        accumulator[type] = [];
      }
      accumulator[type].push(team);
      return accumulator;
    }, {});

    setGroupedTeams(grouped);
  }, []);

  const handleSelectedTeam = (teamId, teamName) => {
    handleUpdateDestTeamId({
      draggableId: teamId,
      source: {
        droppableId: teamId,
      },
    });

    handleUpdateViewTeamName({
      teamName,
    });

    handleFetchAllLists(teamId);
    cancel();
  };

  return (
    <div className={styles.mainSection}>
      {Object.keys(groupedTeams).map((type, index) => {
        const isLastType = index === Object.keys(groupedTeams).length - 1;

        return (
          <div key={type} className={!isLastType ? 'mb-3' : 'mb-0'}>
            <h6 className="mb-0">{type.toUpperCase()}</h6>
            <OverlayButton wait="copyCardLists">
              {groupedTeams[type].map((team) => (
                <ButtonDiv key={team._id} onClick={() => handleSelectedTeam(team?._id, team?.name)}>
                  <a>{team?.name}</a>
                </ButtonDiv>
              ))}
            </OverlayButton>
          </div>
        );
      })}
    </div>
  );
};

TeamNameOption.propTypes = {
  teams: PropTypes.array.isRequired,
  handleUpdateDestTeamId: PropTypes.func.isRequired,
  handleUpdateViewTeamName: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  handleFetchAllLists: PropTypes.func.isRequired,
};

export default TeamNameOption;
