import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ModalRoute, ModalSwitch } from 'react-router-modal-gallery';
import { Modal } from 'react-bootstrap';
import styles from './ListCards.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import CardListItem from '../../../components/CardList/CardListItem/CardListItem';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import handleLoadings from '../../../utilities/handleLoadings';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { BoardConstants, MyTaskCardConstants } from '../../../constants';
import { BoardActions, MyTaskCardActions, TeamActions } from '../../../actions';
import { FeedbackLoadingMain } from '../../../components/UI_V2/Feedback/Loading';
import InfinityScroll from '../../../components/UI/InfinityScroll/InfinityScroll';
import CardDetailContainer from '../../CardDetailContainer/CardDetailContainer';
import ModalLinkNoDecor from '../../../components/ModalLinkNoDecor/ModalLinkNoDecor';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import FilterBar from '../../../components/Container/FilterBar/FilterBar';
import ListCardsSkeleton from './ListCardsSkeleton/ListCardsSkeleton';
import { getStoreFilterBoardCard, setStoreFilterBoardCard } from '../../../utilities/localStorage';
import { useBoardPopulatedCardSocket, useBoardV2SocketForListUpdateOnly } from '../../../hooks/BoardHooks';
import { FeedbackDialogGeneral } from '../../../components/UI_V2/Feedback/Dialog';

const ListCards = ({
  showFilters,
  handleHideFilters,
  handleShowFilterActive,
  handleHideFilterActive,
  handleSetIsOverflow,
}) => {
  const [{
    user,
    currentBoardCardListAll,
    previousBoardCardListAll,
    teams,
    currentTeam,
    currentCompany,
    currentBoard,
    currentBoardLists,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId,
    cardId,
    boardId,
    teamId,
  } = params;

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadings, setLoadings] = useState([]);

  const initialFilterBoardCard = getStoreFilterBoardCard(
    { boardId, userId: user._id },
  );

  const initialFilterSubscribers = initialFilterBoardCard?.filterSubscribers || [];
  const initialFilterLabels = initialFilterBoardCard?.filterLabels || [];
  const initialFilterTitle = initialFilterBoardCard?.filterTitle || '';
  const initialFilterDueDate = initialFilterBoardCard?.filterDueDate || null;

  const [filters, setFilters] = useState({
    filterSubscribers: initialFilterSubscribers,
    filterLabels: initialFilterLabels,
    filterTitle: initialFilterTitle,
    filterDueDate: initialFilterDueDate,
  });

  // connect socket
  useBoardPopulatedCardSocket({
    boardId,
    userId: user?._id,
    view: 'listAll',
  }, dispatch);

  const { socket, removeListener } = useBoardV2SocketForListUpdateOnly({
    boardId: cardId ? undefined : boardId,
    userId: user?._id,
  }, dispatch);

  const isCardOpen = cardId !== undefined;
  const [itemSelected, setItemSelected] = useState(isCardOpen ? cardId : undefined);

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    const result = await BoardActions.loadMoreBoardCardListAll({
      companyId,
      teamId,
      boardId,
      currentBoardCardListAll,
      filters,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    BoardActions.setPreviousBoardCardListAll({
      previousBoardCardListAll: initialState.previousBoardCardListAll,
    }, dispatch);
    BoardActions.setCurrentBoardCardListAll({
      currentBoardCardListAll: initialState.currentBoardCardListAll,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentBoardCardListAll,
    previousLists: previousBoardCardListAll?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: BoardConstants.boardCardListLimit,
    dontSorting: true,
  });

  const initiateBoardCardListByAllApi = async (filtersValue) => {
    try {
      const startLoadings = handleLoadings('initiateBoardCardListByAll', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await BoardActions.initiateBoardCardListAll({
        companyId,
        teamId,
        boardId,
        filters: filtersValue,
      }, dispatch);
      await TeamActions.initiateTeam({
        teamId,
        companyId,
        currentTeam,
      }, dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateBoardCardListByAll', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (!user?._id) return;
    if (teamId === currentTeam?._id && !isFirstLoad) return;
    initiateBoardCardListByAllApi(filters);

    // eslint-disable-next-line consistent-return
    return () => {
      BoardActions.resetBoardAndLists({}, dispatch);
    };
  }, [location]);

  const [
    showEditMenu,
    handleShowEditMenu,
    handleHideEditMenu,
  ] = useDelayShowHideHandler();

  const [
    showQuickComment,
    handleShowQuickComment,
    handleHideQuickComment,
  ] = useDelayShowHideHandler();

  const [
    showNote,
    handleShowNote,
    handleHideNote,
  ] = useDelayShowHideHandler();

  const [
    showName,
    handleShowName,
    handleHideName,
  ] = useDelayShowHideHandler();

  const [
    showCopy,
    handleShowCopy,
    handleHideCopy,
  ] = useDelayShowHideHandler();

  // const [
  //   showMove,
  //   handleShowMove,
  //   handleHideMove,
  // ] = useDelayShowHideHandler();

  // const [
  //   showMember,
  //   handleShowMember,
  //   handleHideMember,
  // ] = useDelayShowHideHandler();

  // const [
  //   showLabel,
  //   handleShowLabel,
  //   handleHideLabel,
  // ] = useDelayShowHideHandler();
  // const [
  //   showDueDate,
  //   handleShowDueDate,
  //   handleHideDueDate,
  // ] = useDelayShowHideHandler();

  const onApplyFilters = (filtersValue) => {
    setFilters(filtersValue);
    initiateBoardCardListByAllApi(filtersValue);
    setStoreFilterBoardCard({
      boardId,
      userId: user._id,
      filter: filtersValue,
    });
  };

  useEffect(() => {
    const {
      filterDueDate,
      filterLabels,
      filterSubscribers,
      filterTitle,
    } = filters;
    const isSearchFilterActive = filterTitle.length > 0;
    const isLabelsFilterActive = filterLabels.length > 0;
    const isMembersFilterActive = filterSubscribers.length > 0;
    const isDueDateFilterActive = filterDueDate;

    let isActive = false;
    if (isSearchFilterActive) isActive = true;
    if (isLabelsFilterActive) isActive = true;
    if (isMembersFilterActive) isActive = true;
    if (isDueDateFilterActive) isActive = true;

    if (isActive) {
      handleShowFilterActive(true);
    } else {
      handleHideFilterActive(false);
    }
  }, [filters]);

  useEffect(() => {
    if (lists?.length > 3) {
      handleSetIsOverflow(true);
    } else {
      handleSetIsOverflow(false);
    }
  }, [lists]);

  return (
    <>
      <FeedbackLoadingMain
        wait="initiateBoardCardListByAll"
        loadings={loadings}
        loadingComponent={<ListCardsSkeleton />}
      >
        <InfinityScroll
          scrollableTarget="listBoardCardList"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="Belum ada tugas disini..."
          marginBottomEndMessage={lists?.length < 14 ? '20rem' : undefined}
        >
          {lists.map((item) => (
            <CardListItem
              item={item}
              featureType="board"
              isSelected={itemSelected === item._id}
              setSelected={setItemSelected}
              showEditMenu={showEditMenu}
              handleShowEditMenu={handleShowEditMenu}
              handleHideEditMenu={handleHideEditMenu}
              showName={showName}
              handleShowName={handleShowName}
              showCopy={showCopy}
              handleShowCopy={handleShowCopy}
              handleHideCopy={handleHideCopy}
              handleHideName={handleHideName}
              showNote={showNote}
              handleShowNote={handleShowNote}
              handleHideNote={handleHideNote}
              showQuickComment={showQuickComment}
              handleShowQuickComment={handleShowQuickComment}
              handleHideQuickComment={handleHideQuickComment}
            />
          ))}
        </InfinityScroll>
      </FeedbackLoadingMain>
      <ModalSwitch renderModal={({ open, redirectToBack }) => (
        <FeedbackDialogGeneral
          open={open}
          onClose={
          () => history.push(`/companies/${companyId}/teams/${teamId}/boards/${boardId}/list/all`)
          }
          maxWidth="md"
          disableCloseIcon
        >
          <ModalRoute
            defaultParentPath={`/companies/${companyId}/teams/${teamId}/boards/${boardId}/list/all`}
            path="/companies/:companyId/teams/:teamId/boards/:boardId/list/all/cards/:cardId"
            component={CardDetailContainer}
          />
        </FeedbackDialogGeneral>
      )}
      >
        <ModalRoute
          defaultParentPath={`/companies/${companyId}/teams/${teamId}/boards/${boardId}/list/all`}
          path="/companies/:companyId/teams/:teamId/boards/:boardId/list/all/cards/:cardId"
          component={CardDetailContainer}
        />
      </ModalSwitch>
      {showFilters
      && (
      <FilterBar
        showLabelSection={false}
        listMembers={currentCompany.members}
        initialSelectedMembers={filters.filterSubscribers}
        showMemberSection
        showDueDateSection
        showTeamSection={false}
        initialDueDate={filters.filterDueDate}
        onClose={handleHideFilters}
        onApplyFilters={onApplyFilters}
        loadingsOnApplyFilters={loadings}
        waitOnApplyFilters="initiateBoardCardListByAll"
        initialSearch={filters.filterTitle}
        barTitle="Filter Tugas"
        topPositionVariant="withBottomBar"
      />
      )}
    </>
  );
};

ListCards.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  handleHideFilters: PropTypes.func.isRequired,
  handleShowFilterActive: PropTypes.func.isRequired,
  handleHideFilterActive: PropTypes.func.isRequired,
  handleSetIsOverflow: PropTypes.func.isRequired,
};

ListCards.defaultProps = {};

export default memo(ListCards);
