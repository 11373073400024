import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import Form from 'react-bootstrap/Form';
import { useSnackbar } from 'notistack';
import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';
import styles from './CheersButton.module.css';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import ButtonDiv from '../../../../components/UI/Button/ButtonDiv/ButtonDiv';
import apiUtil from '../../../../utilities/apiUtil';
import cheersIcon from '../../../../assets/CheersPage/cheers-icon.png';
import { DisplayAvatarMember } from '../../../../components/UI_V2/Display/Avatar';

const CheersButton = (
  {
    type,
    // newVersion, //no need using this anymore
    comment,
    message,
    receiver,
    // primaryParent, //deprecated
    // secondaryParent, //deprecated
    // thirdParent, //deprecated
    parentIds,
  },
) => {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);

  const [showCreate, setShowCreate] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [newCheer, setNewCheer] = useState({ value: '' });
  const [postNewCheer, setPostNewCheer] = useState();

  const params = useParams();
  const { companyId, teamId } = params;

  const handleCancel = () => {
    setShowCreate(false);
  };

  const disableShowEmojiPicker = () => {
    setShowEmojiPicker(false);
  };

  const handleClear = () => {
    setNewCheer({ value: '' });
    setPostNewCheer();
    disableShowEmojiPicker();
  };

  useEffect(() => {
    if (postNewCheer === undefined) return;

    const postApiNewCheer = async () => {
      let ids;
      let url;
      const payload = {
        content: postNewCheer,
        receiver,
      };
      switch (type) {
        // main
        case 'occurrenceMain':
          ids = [params.eventId, params.occurrenceId];
          url = `/api/v1/events/${ids[0]}/occurrences/${ids[1]}/cheers`;
          break;
        case 'blastMain':
          ids = [params.postId];
          url = `/api/v1/posts/${ids[0]}/cheers`;
          break;
        case 'eventMain':
          ids = [params.eventId];
          url = `/api/v1/events/${ids[0]}/cheers`;
          break;
        case 'docMain':
          ids = [params.docId];
          url = `/api/v1/docs/${ids[0]}/cheers`;
          break;
        case 'fileMain':
          ids = [params.fileId];
          url = `/api/v1/files/${ids[0]}/cheers`;
          break;
        case 'cardMain':
          ids = [params.cardId];
          url = `/api/v1/cards/${ids[0]}/cheers`;
          break;
        // group chat & chat
        case 'group-chat-message':
          ids = [params.groupChatId, message._id];
          url = `/api/v1/group-chats/${ids[0]}/messages/${ids[1]}/cheers`;
          break;
        case 'group-chat-attachment':
          ids = [params.groupChatId, message._id];
          url = `/api/v1/group-chats/${ids[0]}/attachments/${ids[1]}/cheers`;
          break;
        case 'private-chat-message':
          ids = [params.chatId, message._id];
          url = `/api/v1/chats/${ids[0]}/messages/${ids[1]}/cheers`;
          break;
        case 'private-chat-attachment':
          ids = [params.chatId, message._id];
          url = `/api/v1/chats/${ids[0]}/attachments/${ids[1]}/cheers`;
          break;
        // comment
        case 'occurrence':
          ids = [params.eventId, params.occurrenceId, comment._id];
          url = `/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${ids[2]}/cheers`;
          break;
        case 'blast':
          ids = [params.postId, comment._id];
          url = `/api/v1/posts/${ids[0]}/comments/${ids[1]}/cheers`;
          break;
        case 'question':
          ids = [params.questionId, comment._id];
          url = `/api/v1/questions/${ids[0]}/comments/${ids[1]}/cheers`;
          break;
        case 'event':
          ids = [params.eventId, comment._id];
          url = `/api/v1/events/${ids[0]}/comments/${ids[1]}/cheers`;
          break;
        case 'doc':
          ids = [params.docId, comment._id];
          url = `/api/v1/docs/${ids[0]}/comments/${ids[1]}/cheers`;
          break;
        case 'file':
          ids = [params.fileId, comment._id];
          url = `/api/v1/files/${ids[0]}/comments/${ids[1]}/cheers`;
          break;
        case 'card':
          // quick comment tidak ada params cardId
          ids = [params.cardId || parentIds?.[0], comment._id];
          url = `/api/v1/cards/${ids[0]}/comments/${ids[1]}/cheers`;
          break;
        // discussion
        case 'occurrenceDiscussion':
          // comment._id now should be a discussion id
          ids = [params.eventId, params.occurrenceId, params.commentId, comment._id];
          url = `/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${ids[2]}/discussions/${ids[3]}/cheers`;
          break;
        case 'blastDiscussion':
          ids = [params.postId, params.commentId, comment._id];
          url = `/api/v1/posts/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers`;
          break;
        case 'questionDiscussion':
          ids = [params.questionId, params.commentId, comment._id];
          url = `/api/v1/questions/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers`;
          break;
        case 'eventDiscussion':
          ids = [params.eventId, params.commentId, comment._id];
          url = `/api/v1/events/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers`;
          break;
        case 'docDiscussion':
          ids = [params.docId, params.commentId, comment._id];
          url = `/api/v1/docs/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers`;
          break;
        case 'fileDiscussion':
          ids = [params.fileId, params.commentId, comment._id];
          url = `/api/v1/files/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers`;
          break;
        case 'cardDiscussion':
          ids = [params.cardId, params.commentId, comment._id];
          url = `/api/v1/cards/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers`;
          break;
        default:
          break;
      }

      try {
        const startLoadings = handleLoadings('createCheer', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.post(url, payload, {
          params: {
            companyId,
            teamId,
          },
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('createCheer', [...loadings], 'end');
        setLoadings([...endLoadings]);

        handleClear();
      }
    };

    postApiNewCheer();
  }, [postNewCheer]);

  const handleChanged = (event) => {
    const { value } = event.target;

    if (value?.length > 15) return;

    setNewCheer((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleToggleShow = () => {
    setShowCreate(!showCreate);
  };

  const handlePostNewCheer = () => {
    if (newCheer === undefined || newCheer.value === undefined || newCheer.value.length < 1) {
      enqueueSnackbar('Cheer cannot be empty', {
        variant: 'error',
      });
      return;
    }

    setPostNewCheer(newCheer.value);
  };

  const onEmojiClick = (code, emoji) => {
    setNewCheer((prevValue) => {
      let newValue;
      if (prevValue.value) {
        newValue = prevValue.value + emoji.emoji;
      } else {
        newValue = emoji.emoji;
      }

      if (prevValue.value.length > 14) return prevValue;

      return ({
        ...prevValue,
        value: newValue,
      });
    });
  };

  const handleShowEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  return (
    <>
      {showCreate
        ? (
          <OverlayButton wait="createCheer" loadings={loadings}>
            <div className={styles.createMode}>
              <div className={styles.photo}>
                <DisplayAvatarMember src={user?.photoUrl} size="md" />
              </div>
              <div className={styles.content} onClick={disableShowEmojiPicker}>
                <Form onSubmit={(event) => {
                  event.preventDefault();
                  handlePostNewCheer();
                }}
                >
                  <Form.Control autoFocus onChange={(event) => handleChanged(event)} placeholder="Kasih cheers!" value={newCheer.value} />
                </Form>
              </div>
              <div className={styles.action}>
                <InsertEmoticonIcon onClick={handleShowEmojiPicker} />
                <CheckCircleOutlineIcon className={styles.checkIcon} onClick={handlePostNewCheer} />
                <HighlightOffIcon className={styles.cancelIcon} onClick={handleToggleShow} />
                {showEmojiPicker
                  ? (
                    <div
                      className={styles.emojiPicker}
                    >
                      <EmojiPicker onEmojiClick={onEmojiClick} />
                    </div>
                  ) : null}
              </div>
            </div>
          </OverlayButton>
        )
        : (
          <ButtonDiv onClick={handleToggleShow} className={styles.container}>
            <div className={styles.buttonCheers}>
              <img src={cheersIcon} />
            </div>
          </ButtonDiv>
        )}
    </>
  );
};

CheersButton.propTypes = {
  type: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  receiver: PropTypes.string.isRequired,
  parentIds: PropTypes.array,
};

CheersButton.defaultProps = {
  parentIds: [],
};

export default CheersButton;
