import React, { memo } from 'react';
import ReactInfiniteScrollComponent from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { isNil, isNumber, isString } from 'lodash';
import { FeedbackSpinnerGeneral } from '../../UI_V2/Feedback/Spinner';
import './InfinityScroll.css';
import { DisplayTextBody } from '../../UI_V2/Display/Text';
import Color from '../../../themes/colors';

const InfinityScrollNotification = ({
  dataLength,
  next,
  hasMore,
  emptyMessage,
  children,
  height,
  style,
  scrollableTarget,
  loader,
  isLoading,
  scrollThreshold,
  inverse,
  initialScrollY,
  marginBottomEndMessage,
  innerRef,
  provided,
}) => {
  const heightProps = isNumber(height) || (isString(height) && height !== '') ? { height } : {};
  const styleProps = !isNil(style) ? { style } : {};

  return (
    <div
      className="bodyInfinityScroll"
      ref={innerRef}
      {...provided?.droppableProps}
    >
      <ReactInfiniteScrollComponent
        {...styleProps}
        {...heightProps}
        inverse={inverse}
        initialScrollY={initialScrollY}
        scrollableTarget={scrollableTarget}
        dataLength={dataLength}
        next={next}
        hasMore={hasMore}
        scrollThreshold={scrollThreshold}
        loader={
          isLoading ? (
            <div className="infiniteScroll">
              <FeedbackSpinnerGeneral size={32} color={Color.yellowAccentCicle} />
            </div>
          ) : <></>
        }
        endMessage={(
          <div
            className="infiniteScroll"
            style={
            marginBottomEndMessage ? { marginBottom: marginBottomEndMessage } : {}
          }
          >
            <DisplayTextBody>
              {dataLength === 0 ? emptyMessage : 'Udah, itu aja ya! 🥳'}
            </DisplayTextBody>
          </div>
        )}
      >
        {children}
      </ReactInfiniteScrollComponent>
    </div>
  );
};

InfinityScrollNotification.propTypes = {
  children: PropTypes.node.isRequired,
  emptyMessage: PropTypes.string,
  dataLength: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  scrollThreshold: PropTypes.number,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  style: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  scrollableTarget: PropTypes.string,
  loader: PropTypes.node,
  isLoading: PropTypes.bool,
  inverse: PropTypes.bool,
  initialScrollY: PropTypes.number,
  marginBottomEndMessage: PropTypes.string,
  provided: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};

InfinityScrollNotification.defaultProps = {
  emptyMessage: 'No data here yet...',
  height: '',
  style: {},
  loader: null,
  isLoading: false,
  scrollThreshold: 0.8,
  inverse: false,
  initialScrollY: null,
  marginBottomEndMessage: undefined,
};

export default memo(InfinityScrollNotification);
