import axios from 'axios';
import { ApiConstants, BoardConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';
import { handleAxiosDeleteRefreshToken } from './CheckLoginActions';

async function getListsByCompanyAndTeam({
  companyId, teamId,
}) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.LISTS(), {
      params: {
        companyId,
        teamId,
      },
    });
    return { lists: result?.data?.lists };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getAllListsWithoutCards({
  currentBoard, teamId, companyId, currentBoardLists,
}) {
  try {
    const boardId = currentBoard?._id;

    // no need to get lists if same lists and same item
    if (currentBoard?.totalList === currentBoardLists?.length) {
      const firstItemTheSame = currentBoard?.lists?.[0]?._id === currentBoardLists?.[0]?._id;
      if (firstItemTheSame) {
        return { lists: currentBoardLists };
      }
    }
    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD_LIST({ boardId }), {
      params: {
        limitList: BoardConstants.maximumLimitList,
        limitCard: BoardConstants.minimumLimitCard,
        lastIndex: BoardConstants.initialLastIndex,
        teamId,
        companyId,
      },
    });
    return { lists: result?.data?.lists };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function moveList({
  boardId, data, companyId, socket, teamId,
}) {
  try {
    const body = {
      data,
      selector: {
        boardId,
      },
    };
    const result = await apiUtil.patch(ApiConstants.URL_V1.LISTS_POSITION(), body, {
      params: {
        companyId,
        teamId,
      },
    });
    if (socket) {
      socket.emit(BoardConstants.socketEvent.LIST_MOVE, body);
    }
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function createList({ body, companyId, teamId }) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.LISTS(), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function archiveList({
  body, listId, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.LIST_ARCHIVE({ listId }), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function setCompleteStatus({
  body, listId, companyId, teamId,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.LIST_COMPLETE_STATUS({ listId }), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function updateCompleteIcon({
  body, listId, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.LIST_COMPLETE_STATUS({ listId }), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getListIdByCardId({
  boardId, cardId, companyId, teamId,
}) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.LISTS_CARD({ cardId }), {
      params: {
        companyId,
        teamId,
        boardId,
      },
    });

    return result?.data?.listId;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function unarchiveList({
  body, companyId, teamId, listId, boardId,
}) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.LIST_UNARCHIVE({ listId }), body, {
      params: {
        companyId,
        teamId,
        boardId,
      },
    });

    return result?.data?.listId;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function unsetCompleteStatus({
  boardId, listId, companyId, teamId,
}) {
  try {
    const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.LIST_COMPLETE_STATUS({ listId })}`, {
      withCredentials: true,
      headers: {
        Authorization: `jwt ${localStorage.getItem('token')}`,
      },
      params: {
        companyId,
        boardId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    const newAuthToken = await handleAxiosDeleteRefreshToken(error);
    if (newAuthToken) {
      try {
        const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.LIST_COMPLETE_STATUS({ listId })}`, {
          withCredentials: true,
          headers: {
            Authorization: `jwt ${newAuthToken}`,
          },
          params: {
            companyId,
            boardId,
            teamId,
          },
        });

        return result;
      } catch (err) {
        throw new ErrorException(err);
      }
    }
    throw new ErrorException(error);
  }
}

async function updateList({
  body, listId, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.LIST({ listId }), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  createList,
  archiveList,
  setCompleteStatus,
  updateCompleteIcon,
  unsetCompleteStatus,
  updateList,
  getListsByCompanyAndTeam,
  getAllListsWithoutCards,
  moveList,
  getListIdByCardId,
  unarchiveList,
};
