import { cloneDeep, isArray, isNumber } from 'lodash';
import { useCallback } from 'react';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, ChatConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import { BreadCrumbActions, TeamActions } from '.';
import {
  getLastListCreatedAt,
  removeDuplicateByID,
  mergeList,
  getIndexItemByIDFromList,
  modifyItemPropertyByIDAndMoveToDestListIndex,
} from '../utilities/arrayUtil';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchCurrentChat({ currentChat }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_CHAT,
    currentChat,
  });
}

function dispatchCurrentGroupChat({ currentGroupChat }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_GROUP_CHAT,
    currentGroupChat,
  });
}

function dispatchPreviousChat({ previousChat }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_CHAT,
    previousChat,
  });
}

function dispatchPreviousGroupChat({ previousGroupChat }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_GROUP_CHAT,
    previousGroupChat,
  });
}

function dispatchUpdateCurrentChat({ updateCurrentChat }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_CHAT,
    updateCurrentChat,
  });
}

function dispatchUpdateCurrentGroupChat({ updateCurrentGroupChat }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_GROUP_CHAT,
    updateCurrentGroupChat,
  });
}

function dispatchRecentPrivateChats({ recentChats }, dispatch) {
  dispatch({
    type: actionTypes.SET_RECENT_CHATS,
    recentChats,
  });
}

function dispatchUpdateRecentPrivateChats({ updateRecentChats }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_RECENT_CHATS,
    updateRecentChats,
  });
}

function dispatchCurrentChatNotif({ currentChatNotifications }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_CHAT_NOTIFICATIONS,
    currentChatNotifications,
  });
}

function dispatchCurrentChatUnreadMessagesCounter(currentChatUnreadMessagesCounter, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_CHAT_UNREAD_MESSAGES_COUNTER,
    currentChatUnreadMessagesCounter,
  });
}

/*
  SetterDispatcher
*/

function setCurrentChat({ currentChat }, dispatch) {
  if (!currentChat) return;

  dispatchCurrentChat({ currentChat: cloneDeep(currentChat) }, dispatch);
}

function setCurrentGroupChat({ currentGroupChat }, dispatch) {
  if (!currentGroupChat) return;

  dispatchCurrentGroupChat({ currentGroupChat: cloneDeep(currentGroupChat) }, dispatch);
}

function setPreviousChat({ previousChat }, dispatch) {
  if (!previousChat) return;

  dispatchPreviousChat({ previousChat: cloneDeep(previousChat) }, dispatch);
}

function setPreviousGroupChat({ previousGroupChat }, dispatch) {
  if (!previousGroupChat) return;

  dispatchPreviousGroupChat({ previousGroupChat: cloneDeep(previousGroupChat) }, dispatch);
}

function setRecentPrivateChat({ recentChats }, dispatch) {
  if (!recentChats) return;

  dispatchRecentPrivateChats({ recentChats: cloneDeep(recentChats) }, dispatch);
}

function setCurrentChatNotif({ currentChatNotifications }, dispatch) {
  if (!currentChatNotifications) return;

  dispatchCurrentChatNotif({
    currentChatNotifications: cloneDeep(currentChatNotifications),
  }, dispatch);
}

function setCurrentChatUnreadMessagesCounter(currentChatUnreadMessagesCounter, dispatch) {
  if (!isNumber(currentChatUnreadMessagesCounter)) return;

  dispatchCurrentChatUnreadMessagesCounter(currentChatUnreadMessagesCounter, dispatch);
}

/*
  Helpers
*/

function addTypeToItemList(list, type) {
  if (!list || !type) {
    return [];
  }
  return list.map((itemList) => ({ ...itemList, type }));
}

function sortingChats(currentChat) {
  if (!currentChat) return [];

  let chats = currentChat;
  // sort by created date
  chats = chats.sort((a, b) => {
    const c = new Date(a.createdAt);
    const d = new Date(b.createdAt);
    return d - c;
  });

  return chats;
}

function sortingAndAddType(currentChats) {
  const attachments = addTypeToItemList(
    currentChats?.attachments, ChatConstants.typeChat.ATTACHMENT,
  );
  const messages = addTypeToItemList(currentChats?.messages, ChatConstants.typeChat.MESSAGE);

  let chats = [...attachments, ...messages];
  chats = sortingChats(chats);
  return chats;
}

// v1 sama v2 responsenya beda, maka metod ini dibuat agar sama responsenya
function modifyResponseChats(res, id, categoryChat) {
  let data = {};
  if (categoryChat === ChatConstants.categoryChat.PRIVATE) {
    // check if response from v1
    if (!isArray(res?.chat) && res?.chat?.attachments && res?.chat?.messages) {
      data = {
        ...res?.chat,
        chats: sortingAndAddType(res?.chat),
        messages: null,
        attachments: null,
      };
    } else {
      data = {
        _id: id,
        // company: res?.currentCompany?._id,
        ...res,
        chats: res?.chats,
        members: res?.members,
      };
    }
  } else if (!isArray(res?.groupChat) && res?.groupChat?.attachments && res?.groupChat?.messages) {
    data = {
      ...res?.groupChat,
      groupChats: sortingAndAddType(res?.groupChat),
      messages: null,
      attachments: null,
    };
  } else {
    data = {
      _id: id,
      ...res,
      groupChats: res?.groupChats,
      currentTeam: res?.currentTeam,
    };
  }
  return data;
}

function modifyResponseLoadChats(res, categoryChat, prevChats) {
  let data = {};
  if (categoryChat === ChatConstants.categoryChat.PRIVATE) {
    data = {
      ...prevChats,
      chats: res?.chats,
    };
  } else {
    data = {
      ...prevChats,
      groupChats: res?.groupChats,
    };
  }
  return data;
}

function dispatchChats({ isPrivateChat, updateChat }, dispatch) {
  if (isPrivateChat) {
    const updateCurrentChat = (currentChats) => {
      const newChats = updateChat(currentChats);

      return modifyResponseChats(newChats, null, ChatConstants.categoryChat.PRIVATE);
    };
    dispatchUpdateCurrentChat({ updateCurrentChat }, dispatch);
  } else {
    const updateCurrentGroupChat = (currentChats) => {
      const newChats = updateChat(currentChats);

      return modifyResponseChats(newChats, null, ChatConstants.categoryChat.GROUP);
    };
    dispatchUpdateCurrentGroupChat({ updateCurrentGroupChat }, dispatch);
  }
}

function mergeChats({ currentChats, nextChats, categoryChat }) {
  if (!currentChats || !nextChats) return [];

  const propertyStoreChat = categoryChat === ChatConstants.categoryChat.GROUP ? 'groupChats' : 'chats';
  let newChats = mergeList({
    currentList: currentChats?.[propertyStoreChat],
    nextList: nextChats?.[propertyStoreChat],
  });
  newChats = removeDuplicateByID(newChats);
  const mergedChats = {
    ...currentChats,
    [propertyStoreChat]: newChats,
  };

  return mergedChats;
}

/*
  Method
*/

function incomingChat({
  message, categoryChat, typeAction,
}, dispatch) {
  if (!message) return;
  const isPrivateChat = categoryChat === ChatConstants.categoryChat.PRIVATE;

  const updateChat = (currentChats) => {
    const listData = isPrivateChat ? currentChats.chats : currentChats.groupChats;
    const indexNewMessage = getIndexItemByIDFromList(listData, message);

    if (typeAction === ChatConstants.typeCallback.NEW
    ) {
      // if data available replace if not add to listData
      // listData[indexNewMessage >= 0 ? indexNewMessage : listData.length] = message;
      listData[listData.length] = message;
    } else if (indexNewMessage >= 0) {
      listData[indexNewMessage] = message;
    }

    const newChats = isPrivateChat
      ? { ...currentChats, chats: listData } : { ...currentChats, groupChats: listData };

    return newChats;
  };

  dispatchChats({ isPrivateChat, updateChat }, dispatch);
}

function incomingChatCheer({
  messageId, cheer, categoryChat, typeAction,
}, dispatch) {
  if (!messageId || !cheer) return;
  const isPrivateChat = categoryChat === ChatConstants.categoryChat.PRIVATE;

  const updateChat = (currentChats) => {
    const listData = isPrivateChat ? currentChats.chats : currentChats.groupChats;
    const message = listData.find((data) => data._id === messageId);

    if (!message) return currentChats;

    const isMessageHasCheers = message?.cheers !== undefined;

    const isTypeActionNew = typeAction === ChatConstants.typeCallback.NEW;
    const isTypeActionDelete = typeAction === ChatConstants.typeCallback.DELETE;

    let cheers = [];
    if (isTypeActionNew) {
      cheers = isMessageHasCheers ? [...message.cheers, cheer] : [cheer];
      message.cheers = cheers;
    }

    if (isTypeActionDelete) {
      cheers = isMessageHasCheers
        ? message.cheers.filter(
          (currentCheer) => currentCheer._id !== cheer._id,
        ) : [];
      message.cheers = cheers;
    }

    const indexNewMessage = getIndexItemByIDFromList(listData, message);

    listData[indexNewMessage] = message;

    const newChats = isPrivateChat
      ? { ...currentChats, chats: listData } : { ...currentChats, groupChats: listData };

    return newChats;
  };

  dispatchChats({ isPrivateChat, updateChat }, dispatch);
}

async function initiateChatV2({ chatId, companyId }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.CHAT({ chatId }), {
      params: {
        limit: ChatConstants.limitChat,
        companyId,
        disableGetCompany: true,
      },
    });

    const modifiedResult = modifyResponseChats(
      result.data, chatId, ChatConstants.categoryChat.PRIVATE,
    );

    setCurrentChat({ currentChat: modifiedResult }, dispatch);

    return modifiedResult;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreChats({ chatId, currentChat, companyId }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.CHAT({ chatId }), {
      params: {
        limit: ChatConstants.limitChat,
        createdAt: getLastListCreatedAt(currentChat.chats),
        companyId,
      },
    });

    const categoryChat = ChatConstants.categoryChat.PRIVATE;
    const modifiedResult = modifyResponseLoadChats(result.data, categoryChat, currentChat);
    const mergedChats = mergeChats(
      { currentChats: currentChat, nextChats: modifiedResult, categoryChat },
    );

    setPreviousChat({ previousChat: modifiedResult }, dispatch);
    setCurrentChat({ currentChat: mergedChats }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateGroupChatV2({ groupChatId, companyId, teamId }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.GROUP_CHAT({ groupChatId }), {
      params: {
        limit: ChatConstants.limitChat,
        companyId,
        teamId,
        createdAt: new Date(),
      },
    });

    const modifiedResult = modifyResponseChats(
      result.data, groupChatId, ChatConstants.categoryChat.GROUP,
    );

    setCurrentGroupChat({ currentGroupChat: modifiedResult }, dispatch);
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    return modifiedResult;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreGroupChats({
  groupChatId, currentGroupChat, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.GROUP_CHAT({ groupChatId }), {
      params: {
        limit: ChatConstants.limitChat,
        createdAt: getLastListCreatedAt(currentGroupChat.groupChats),
        companyId,
        teamId,
      },
    });

    const categoryChat = ChatConstants.categoryChat.GROUP;
    const modifiedResult = modifyResponseLoadChats(result.data, categoryChat, currentGroupChat);
    const mergedChats = mergeChats(
      { currentChats: currentGroupChat, nextChats: modifiedResult, categoryChat },
    );

    setPreviousGroupChat({ previousGroupChat: modifiedResult }, dispatch);
    setCurrentGroupChat({ currentGroupChat: mergedChats }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateNotifPrivateChatPopUp({ companyId, limit }, dispatch) {
  try {
    const result = await apiUtil.get(
      '/v2/notifications?',
      {
        params: {
          limit,
          page: 1,
          sortBy: 'updatedAt',
          orderBy: 'desc',
          filter: 'unread',
          type: 'chat',
          companyId,
        },
      },
    );

    setCurrentChatNotif({ currentChatNotifications: [...result.data.data] }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateListRecentChats({ currentCompany }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.RECENT_CHATS(), {
      params: {
        companyId: currentCompany._id,
      },
    });

    let members;
    // jaga2 jika currentCompany tidak ada members nya
    if (currentCompany === undefined || currentCompany.members === undefined) {
      members = [];
    } else {
      members = [...currentCompany.members];
    }

    const checkParam = [];
    members.forEach((member) => checkParam.push(member._id));

    const finalChats = result.data.chats.filter(
      (chat) => chat.members.some((member) => checkParam.includes(member._id)),
    );

    setRecentPrivateChat({ recentChats: finalChats }, dispatch);
    await initiateNotifPrivateChatPopUp({
      companyId: currentCompany?._id,
      limit: finalChats?.length,
    }, dispatch);

    return { ...result, limit: finalChats?.length };
  } catch (error) {
    throw new ErrorException(error);
  }
}

const manipulateRecentChats = ({
  chatId,
  lastMessage,
  isLatestMessage,
  typeAction,
}, dispatch) => {
  if (!lastMessage || !chatId) return;
  if (!isLatestMessage && typeAction === ChatConstants.typeCallback.DELETE) return;

  const updateRecentChats = (recentChats) => {
    let newRecentChats = [...recentChats];
    let destIndex = 0;

    if (typeAction === ChatConstants.typeCallback.DELETE && isLatestMessage) {
      destIndex = getIndexItemByIDFromList(newRecentChats, { _id: chatId });
    }

    newRecentChats = modifyItemPropertyByIDAndMoveToDestListIndex(
      recentChats,
      chatId,
      destIndex,
      'lastMessage',
      lastMessage,
    );
    return newRecentChats;
  };

  dispatchUpdateRecentPrivateChats({ updateRecentChats }, dispatch);
};

function incomingNewChatNotif({ notifications }, dispatch) {
  if (!notifications) return;

  const allNotifChat = notifications.filter((notification) => {
    let type = '';
    if (notification?.childService?.serviceType) {
      type = notification.childService.serviceType;
    } else {
      type = notification.service.serviceType;
    }

    switch (type) {
      case 'chat':
        return true;
      // case 'chatMention':
      //   return true;
      default:
        return false;
    }
  });

  if (allNotifChat?.length > 0) {
    setCurrentChatNotif({ currentChatNotifications: allNotifChat }, dispatch);
  }
}

export {
  incomingChat,
  sortingChats,
  initiateChatV2,
  initiateGroupChatV2,
  manipulateRecentChats,
  loadMoreChats,
  loadMoreGroupChats,
  modifyResponseChats,
  setPreviousChat,
  setCurrentChat,
  setCurrentChatUnreadMessagesCounter,
  setPreviousGroupChat,
  setCurrentGroupChat,
  initiateListRecentChats,
  incomingNewChatNotif,
  incomingChatCheer,
};
