import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import _ from 'lodash';
import ArchiveIcon from '@mui/icons-material/Archive';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AddIcon from '@mui/icons-material/Add';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useMediaQuery } from '@mui/material';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import styles from './TeamPage.module.css';
import BoxMenu from './BoxMenu/BoxMenu';
import ManageMultipleMembersContainer from '../ManageMultipleMembersContainer/ManageMultipleMembersContainer';
import Title from '../../components/Title/Title';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import BlastSnapshot from './BlastSnapshot/BlastSnapshot';
import GroupChatSnapshot from './GroupChatSnapshot/GroupChatSnapshot';
import ScheduleSnapshot from './ScheduleSnapshot/ScheduleSnapshot';
import CheckInSnapshot from './CheckInSnapshot/CheckInSnapshot';
import BoardSnapshot from './BoardSnapshot/BoardSnapshot';
import BucketSnapshot from './BucketSnapshot/BucketSnapshot';
import ShowLimitMembers from '../../components/ShowLimitMembers/ShowLimitMembers';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import { TeamActions, SearchJumpToActions } from '../../actions';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import blastIcon from '../../assets/TeamPage/blast.png';
import boardIcon from '../../assets/TeamPage/board.png';
import docsFilesIcon from '../../assets/TeamPage/docs-files.png';
import groupChatIcon from '../../assets/TeamPage/group-chat.png';
import checkInIcon from '../../assets/TeamPage/check-in.png';
import scheduleIcon from '../../assets/TeamPage/schedule.png';
import { useTeamHooks } from '../../hooks/TeamHooks';
import { PageConstants } from '../../constants';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, roles, typeRef } from '../../constants/RoleConstants';
import { hasPermission } from '../../actions/RoleActions';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import MemberOnlineModal from '../../components/Container/MemberOnlineModal/MemberOnlineModal';
import { elementId } from '../../constants/ElementIdConstants';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function TeamPage() {
  const [{
    user, currentTeam, currentCompany, currentRoleUser,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  const [showAddMember, setShowAddMember] = useState(false);
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();
  const [loadings, setLoadings] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId } = params;

  useTeamHooks({ teamId, userId: user?._id }, dispatch);
  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.team,
      featureId: teamId,
    },
  });

  const [
    showMemberOnlineModal,
    handleShowMemberOnlineModal,
    handleHideMemberOnlineModal,
  ] = useDelayShowHideHandler();

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    const startLoadings = handleLoadings('teamPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchCurrentTeam = async () => {
      try {
        const result = await TeamActions.initiateCurrentTeam({ teamId, companyId }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('teamPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchCurrentTeam();
  }, [location]);

  const handleShowAddMember = () => {
    setShowAddMember(true);
  };

  const handleCancelShowAddMember = () => {
    setShowAddMember(false);
  };

  const isBoardHasLists = currentTeam?.boards?.[0]?.lists?.length > 0;
  let listHasCards = [];
  if (isBoardHasLists) {
    listHasCards = currentTeam?.boards?.[0]?.lists?.filter((list) => list.cards.length > 0);
  }
  const isListHasCards = listHasCards.length > 0;

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const limitMembers = isMobile ? 1 : 5;

  const pageType = PageConstants.pageType.teams;
  const breadcrumb = generateRoutesAndTitle(
    { type: pageType, data: { currentCompany, currentTeam } },
  );

  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: teamId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={currentTeam?.name} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="teams"
//         bottomBarGlobalActions={(
//           <>
//             {/* {
//               currentTeam?._id === teamId
//               && isUserManagerOrAbove
//               && (
//               <GlobalActionButton
//                 handleClick={goToSettings}
//                 // text="Team Settings"
//                 text="Pengaturan Tim"
//                 icon={<SettingsOutlinedIcon />}
//               />
//               )
// } */}
//             {/* {
//              currentTeam?._id === teamId
//              && (
//              <LinkNoDecor to={`${location.pathname}/reports/members/${user._id}`}>
//                <GlobalActionButton
//                  text="Kinerja Saya"
//                  icon={PageConstants.pageProperties.reports.icon}
//                />
//              </LinkNoDecor>
//              )
//           } */}
//             {/* {
//              currentTeam?._id === teamId
//              && (
//              <GlobalActionButton
//                handleClick={handleShowModalRoleUser}
//               //  text="My Role Here"
//                text="Akses Saya"
//                icon={<VerifiedUserIcon />}
//              />
//              )
//           } */}
//           </>
//         )}
        bottomBarGlobalActions={(
          <>
            {currentTeam.members
              && (
                <div style={{ position: 'relative' }}>
                  <ShowLimitMembers
                    members={currentTeam?.members}
                    limit={limitMembers}
                    companyId={companyId}
                    handleClickButton={handleShowAddMember}
                    noPlusButton={!isUserManagerOrAbove}
                    additionalPlusButton={isUserManagerOrAbove
                    && currentTeam?.members?.length >= limitMembers}
                    positionPopUpOnlineOnPhoto="topRight"
                    enableDiffClickOnExceededMember
                    handleClickExceededMember={handleShowMemberOnlineModal}
                    idAddButton={elementId.teamMemberToggleAddButton}
                  />
                </div>
              )}
            {showAddMember
            && isUserManagerOrAbove
            && <ManageMultipleMembersContainer position="topLeft" idApplyButton={elementId.teamMemberToggleApplyButton} type="team" cancel={handleCancelShowAddMember} />}
            <div className={styles.role}>
              {
             currentTeam?._id === teamId
             && (
             <GlobalActionButton
               handleClick={handleShowModalRoleUser}
              //  text="My Role Here"
               text="Akses Saya"
               icon={<VerifiedUserIcon />}
             />
             )
          }
            </div>
            {currentTeam?._id === teamId
              && isUserManagerOrAbove
              && (
                <LinkNoDecor to={`${location.pathname}/settings`}>
                  <GlobalActionButton
            // handleClick={handleShowArchivedItems}
                    buttonMode="outlined"
            // text="Archived Teams"
                    text="Pengaturan Tim"
                    icon={<SettingsOutlinedIcon />}
                  />
                </LinkNoDecor>
              )}
            {currentTeam.members && showMemberOnlineModal
            && (
            <MemberOnlineModal
              open={showMemberOnlineModal}
              onClose={handleHideMemberOnlineModal}
              members={currentTeam?.members}
              title={currentTeam?.name}
            />
            )}
          </>
        )}
      />
      <PageLoading wait="teamPage" loadings={loadings}>
        <div className={containerWithSidebarStyles[isSidebarOpen]}>
          {currentTeam.archived ? (
            <>
              {currentTeam.archived.status ? (
                <div className={styles.archivedSection}>
                  <ArchiveIcon />
                  {/* <h1>This team is archived</h1> */}
                  <h1>Tim ini udah terarsip</h1>
                </div>
              ) : null}
            </>
          ) : null}
          <div className={styles.rowBox}>
            <div className={styles.colBox}>
              {currentTeam.groupChat ? (
                <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/group-chats/${currentTeam.groupChat?._id}`}>
                  {currentTeam?.groupChat?.messages?.length < 1
                      && currentTeam?.groupChat?.attachments?.length < 1
                    ? (
                      <BoxMenu
                        // title="Group Chat"
                        title="Chat Grup"
                        // subtitle="A place for chatting arround with your team"
                        subtitle="Chat grup realtime selayaknya grup Whatsapp. Khusus buat kerja."
                        imageSrc={groupChatIcon}
                      />
                    )
                    : (
                      <BoxMenu
                        // title="Group Chat"
                        title="Chat Grup"
                        hasItems
                      >
                        <GroupChatSnapshot
                          messages={currentTeam.groupChat.messages}
                          attachments={currentTeam.groupChat.attachments}
                        />
                      </BoxMenu>
                    )}
                </LinkNoDecor>
              ) : null}
            </div>

            <div className={styles.colBox}>
              {currentTeam.blast ? (
                <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/blasts/${currentTeam.blast?._id}`}>
                  {currentTeam.blast?.posts?.length < 1
                    ? (
                      <BoxMenu
                        // title="Blast!"
                        title="Pengumuman"
                        // subtitle="Keep team informed and decide something without meetings"
                        subtitle="Broadcast pengumuman biar ngga tenggelam"
                        imageSrc={blastIcon}
                      />
                    )
                    : (
                      <BoxMenu
                        // title="Blast!"
                        title="Pengumuman"
                        hasItems
                      >
                        <BlastSnapshot posts={currentTeam.blast.posts} />
                      </BoxMenu>
                    )}

                </LinkNoDecor>
              ) : null}
            </div>

            <div className={styles.colBox}>
              {currentTeam.schedule ? (
                <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/schedules/${currentTeam.schedule?._id}`}>
                  {currentTeam.schedule?.events?.length < 1 ? (
                    <BoxMenu
                      // title="Schedule"
                      title="Jadwal"
                      // subtitle="A centralized calendar for your team"
                      subtitle="Kalender untuk mengingat jadwal penting, bisa terkoneksi ke kalender Google/Apple."
                      imageSrc={scheduleIcon}
                    />
                  ) : (
                    <BoxMenu
                      // title="Schedule"
                      title="Jadwal"
                      hasItems
                    >
                      <ScheduleSnapshot events={currentTeam.schedule.events} />
                    </BoxMenu>
                  )}
                </LinkNoDecor>
              ) : null}
            </div>
          </div>
          <div className={styles.rowBox}>
            <div className={styles.colBox}>
              {currentTeam?.boards?.[0] ? (
                <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/boards/${currentTeam?.boards?.[0]?._id}`}>
                  {isBoardHasLists && isListHasCards
                    ? (
                      <BoxMenu
                        title="Tugas"
                        hasItems
                      >
                        <BoardSnapshot lists={currentTeam?.boards?.[0]?.lists} />

                      </BoxMenu>
                    )
                    : (
                      <BoxMenu
                        // title="Board"
                        title="Tugas"
                        subtitle="Kelola tugas tim dan lihat progressnya dengan lebih jelas."
                        imageSrc={boardIcon}
                      />
                    ) }

                </LinkNoDecor>
              ) : null}
            </div>

            <div className={styles.colBox}>
              {currentTeam.checkIn ? (
                <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/check-ins/${currentTeam.checkIn?._id}`}>
                  {currentTeam.checkIn?.questions?.length < 1
                    ? (
                      <BoxMenu
                        // title="Check-Ins"
                        title="Pertanyaan"
                        // subtitle="Remove unnecessary meetings by
                        // sending routine questions for your team"
                        subtitle="Kirim pertanyaan rutin untuk dijawab tim kamu."
                        imageSrc={checkInIcon}
                      />
                    )

                    : (
                      <BoxMenu
                        // title="Check-Ins"
                        title="Pertanyaan"
                        hasItems
                      >
                        <CheckInSnapshot questions={currentTeam.checkIn.questions} />
                      </BoxMenu>
                    )}

                </LinkNoDecor>
              ) : null}
            </div>
            <div className={styles.colBox}>
              {currentTeam.bucket ? (
                <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/buckets/${currentTeam.bucket?._id}`}>
                  {currentTeam.bucket?.docs?.length < 1
                      && currentTeam.bucket.files.length < 1
                      && currentTeam.bucket.buckets.length < 1 ? (
                        <BoxMenu
                          // title="Docs & Files"
                          // subtitle="Storing all your documents & files in one place"
                          title="Dokumen & File"
                          subtitle="Simpan dokumen, link, file penting secara rapi di 1 tempat"
                          imageSrc={docsFilesIcon}
                        />
                    ) : (
                      <BoxMenu
                        // title="Docs & Files"
                        title="Dokumen & File"
                        hasItems
                      >
                        <BucketSnapshot folder={currentTeam.bucket} />

                      </BoxMenu>
                    )}
                </LinkNoDecor>
              ) : null}
            </div>
          </div>
        </div>
        <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureType={typeRef.team}
          featureId={teamId}
          featureTitle={currentTeam?.name}
          roleType={roleTypeRef.team}
        />
      </PageLoading>
    </>
  );
}

export default TeamPage;
