/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, {
  memo, useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DeleteIcon from '@mui/icons-material/Delete';
import EventIcon from '@mui/icons-material/Event';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useMediaQuery } from '@mui/material';
import invariant from 'tiny-invariant';
import styles from './ChecklistItem.module.css';
import Color from '../../../../../../themes/colors';
import { InputButtonMain } from '../../../../../../components/UI_V2/Input/Button';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import { useDelayShowHideHandler } from '../../../../../../hooks/HelperHooks';
import EditChecklistItemName from './EditChecklistItemName/EditChecklistItemName';
import MarkChecklistItem from './MarkChecklistItem/MarkChecklistItem';
import { FeedbackSpinnerGeneral } from '../../../../../../components/UI_V2/Feedback/Spinner';
import ManageDueDateContainer from '../../../../../ManageDueDateContainer/ManageDueDateContainer';
import { DisplayDateBox } from '../../../../../../components/UI_V2/Display/Date';
import { GlobalContext } from '../../../../../../contexts/GlobalStateProvider';
import { checkIfUserAuthorizedToModifyCard } from '../../../../../../actions/UserActions';

const dragStartStyles = {
  true: styles.dragStartTrue,
  false: styles.dragStartFalse,
};

const hoveredStyles = {
  true: styles.hoveredTrue,
  false: styles.hoveredFalse,
};

const ChecklistItem = ({
  checklistItem,
  index,
  checklistId,
  teamId,
  cardId,
  companyId,
  handleDeleteChecklistItem,
  movedChecklistItemId,
}) => {
  const [{
    user, currentCard, currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const [isDragged, setIsDragged] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isCompleted, setIsCompleted] = useState(checklistItem?.complete?.status);

  useEffect(() => {
    setIsCompleted(checklistItem?.complete?.status);
  }, [checklistItem?.complete?.status]);
  const [
    showEditChecklistItemName,
    handleShowEditChecklistItemName,
    handleHideEditChecklistItemName,
  ] = useDelayShowHideHandler();
  const [
    showEditDate,
    handleShowEditDate,
    handleHideEditDate,
  ] = useDelayShowHideHandler();

  const ref = useRef(null);

  useEffect(() => {
    const el = ref.current;
    invariant(el, 'Element ref not set');

    return draggable({
      element: el,
      getInitialData: () => ({
        index,
        draggedChecklistItemId: checklistItem._id,
        checklistId,
        type: 'checklistItem',
      }),
      onDragStart: () => setIsDragged(true),
      onDrop: () => setIsDragged(false),
    });
  }, [checklistItem?._id]);

  const isChecklistItemLoading = movedChecklistItemId === checklistItem._id;

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      className={`${styles.container} ${dragStartStyles[isDragged]} ${hoveredStyles[isHovered]}`}
      ref={ref}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isChecklistItemLoading && (
      <>
        <div className={styles.overlayBackground} />
        <div className={styles.overlayContent}>
          <FeedbackSpinnerGeneral color={Color.yellowAccentCicle} />
        </div>
      </>
      )}
      <MarkChecklistItem
        checklistItem={checklistItem}
        setIsCompleted={setIsCompleted}
        isCompleted={isCompleted}
        cardId={cardId}
        teamId={teamId}
        companyId={companyId}
        checklistId={checklistId}
      />
      <div className={styles.body}>
        {!showEditChecklistItemName && (
        <div className={styles.name}>
          <DisplayTextBody fontStyle={isCompleted ? 'lineThrough' : 'normal'} mode="13">
            {checklistItem.name}
          </DisplayTextBody>
        </div>
        )}
        {showEditChecklistItemName && (
        <EditChecklistItemName
          cardId={cardId}
          checklistId={checklistId}
          teamId={teamId}
          companyId={companyId}
          checklistItemId={checklistItem._id}
          onClose={handleHideEditChecklistItemName}
          initialChecklistItemName={checklistItem.name}
        />
        )}
        {showEditDate && (
        <ManageDueDateContainer
          cancel={() => {
            handleHideEditDate();
            setIsHovered(false);
          }}
          checklistItem={checklistItem}
          type="checklistItem"
          position="topRight"
          open={showEditDate}
          modalVersion
        />
        )}
        {!isHovered && checklistItem.dueDate !== undefined && (
        <div className={styles.action}>
          <DisplayDateBox
            complete={checklistItem.complete}
            date={checklistItem.dueDate}
            startDate={checklistItem.startDate}
            icon
            changeColorDate
          />
        </div>
        )}
        {isHovered && checkIfUserAuthorizedToModifyCard(currentCard, user,
          currentRoleUser) && (
          <div className={styles.action}>
            <div className={styles.actionButton} onClick={handleShowEditDate}>
              {checklistItem.dueDate !== undefined
                ? (
                  <DisplayDateBox
                    complete={checklistItem.complete}
                    date={checklistItem.dueDate}
                    startDate={checklistItem.startDate}
                    icon
                    changeColorDate
                  />
                )
                : <EventIcon />}
            </div>
            <div className={styles.actionButton} onClick={handleShowEditChecklistItemName}>
              <EditIcon />
            </div>
            <div
              className={styles.actionButton}
              onClick={
            () => handleDeleteChecklistItem(
              checklistItem._id, checklistId,
            )
}
            >
              <DeleteIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ChecklistItem.propTypes = {
  checklistItem: PropTypes.object.isRequired,
  index: PropTypes.number,
  checklistId: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  handleDeleteChecklistItem: PropTypes.func.isRequired,
  movedChecklistItemId: PropTypes.string.isRequired,
};

ChecklistItem.defaultProps = {
  index: 0,
};

export default memo(ChecklistItem);
