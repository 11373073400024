import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './LoginPage.module.css';
import LoginButton from './LoginButton/LoginButton';
import SeparatorLoginButton from './SeparatorLoginButton/SeparatorLoginButton';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import mobileViewImage from '../../assets/AuthPage/login-illustration-mobileview.png';
import webViewImage from '../../assets/AuthPage/login-illustration-webview.png';
import Title from '../../components/Title/Title';
import LoginEmailPassword from './LoginEmailPassword/LoginEmailPassword';

function LoginPage() {
  const [{ user }, dispatch] = useContext(GlobalContext);

  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const isMobile = useMediaQuery('(max-width:1024px)');

  let titleDescText = {
    title: '',
    subTitle: null,
    desc: '',
  };
  const isFromLogout = localStorage.getItem('logout');
  const isFromEmailInvitation = query.sourcePage === 'emailInvitation';

  if (isFromEmailInvitation) {
    titleDescText = {
      title: 'Login Cicle',
      subTitle: 'Kamu telah klik undangan bergabung ke Company di Cicle.',
      desc: 'Silahkan masuk terlebih dahulu, sebelum kami tambahkan kamu ke dalam Company.',
    };
  } else if (isFromLogout) {
    titleDescText = {
      title: 'Sampai Jumpa!',
      desc: 'Sedih melihat kamu pergi. Tapi tenang saja, kamu bisa masuk kembali ke Cicle kapan saja!',
    };
    localStorage.removeItem('logout');
  } else {
    titleDescText = {
      title: 'Login Cicle',
      desc: 'Silahkan login terlebih dahulu',
    };
  }

  if (!isFromEmailInvitation) {
    localStorage.removeItem('emailInvitationToken');
  }

  function signInWithGoogle() {
    window.open(`${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/auth/google`, '_self');
  }

  // const signInWithApple = () => {
  //   window.open(`${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/auth/apple`, '_self');
  // };

  return (
    <>
      <Title title="Cicle - Kelola tugas karyawan, laporan kinerja, dan komunikasi realtime dalam 1 aplikasi" />
      <LogoHeader />
      {user._id === undefined ? (
        <div className={styles.LoginPage__container}>
          <div className={styles.LoginPage__leftSection}>
            <LazyLoadImage
              alt=""
              className={styles.LoginPage__leftSection__illustration}
              src={isMobile
                ? mobileViewImage
                : webViewImage}
            />
          </div>
          <div className={styles.LoginPage__rightSection}>
            <div className={styles.LoginPage__rightSection__text}>
              <div className={styles.LoginPage__textSection}>
                <h1>{titleDescText.title}</h1>
                {titleDescText.subTitle
                && <p className={styles.subtitle}>{titleDescText.subTitle}</p>}
                <p>{titleDescText.desc}</p>
              </div>
            </div>
            <div className={styles.LoginPage__rightSection__loginButton}>
              <LoginEmailPassword isFromEmailInvitation={isFromEmailInvitation} />
              <SeparatorLoginButton />
              <LoginButton
                // eslint-disable-next-line react/jsx-no-bind
                handleClick={signInWithGoogle}
                loginWith="google"
                authType="signin"
              />
            </div>
          </div>
        </div>
      ) : history.push('/')}
    </>
  );
}

export default LoginPage;
