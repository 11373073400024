import React, {
  useContext, useEffect,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { actionTypes } from '../../reducers/reducer';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import styles from './CardRedirectionPage.module.css';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import {
  CardActions,
} from '../../actions';
import LoadingWithAnimation from '../../components/UI/LoadingWithAnimation/LoadingWithAnimation';
import { useGetBoardCardViewData } from '../../hooks/BoardHooks';
import { getStoreBoardCardView } from '../../utilities/localStorage';
import { getSessionBoardCardView } from '../../utilities/sessionStorage';

function CardRedirectionPage() {
  const [{
    user, currentTeam,
    currentCard,
    currentBoard,
  }, dispatch] = useContext(GlobalContext);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { readNotifId, commentId } = query;

  const {
    companyId, teamId, cardId,
  } = params;

  const getUrlBoardCardView = (mainValue, initialCalenderViewValue, boardId) => {
    let url;
    switch (mainValue) {
      case 'listAll':
        url = `/companies/${companyId}/teams/${teamId}/boards/${boardId}/list/all/cards/${cardId}?readNotifId=${readNotifId}`;
        if (commentId) {
          url = `${url}&commentId=${commentId}`;
        }
        break;

      case 'kanbanAll':
        url = `/companies/${companyId}/teams/${teamId}/boards/${boardId}/kanban/all/cards/${cardId}?readNotifId=${readNotifId}`;
        if (commentId) {
          url = `${url}&commentId=${commentId}`;
        }
        break;

      case 'calendarAll':
        url = `/companies/${companyId}/teams/${teamId}/boards/${boardId}/calendar/all/cards/${cardId}?readNotifId=${readNotifId}&initialCalendarView=${initialCalenderViewValue}`;
        if (commentId) {
          url = `${url}&commentId=${commentId}`;
        }
        break;

      default:
        url = `/companies/${companyId}/teams/${teamId}/boards/${boardId}/kanban/all/cards/${cardId}?readNotifId=${readNotifId}`;
        if (commentId) {
          url = `${url}&commentId=${commentId}`;
        }
        break;
    }

    return url;
  };

  const fetchCardApiThenRedirect = async () => {
    try {
      const result = await CardActions.initiateCardV2(
        {
          cardId,
          currentCard,
          currentBoardId: currentBoard?._id,
          companyId,
          teamId,
          currentTeam,
        },
        dispatch,
      );

      let initialBoardCardView;

      initialBoardCardView = getSessionBoardCardView(
        { companyId, userId: user?._id },
      );

      if (!initialBoardCardView?.mainValue) {
        initialBoardCardView = getStoreBoardCardView(
          { companyId, userId: user?._id },
        );
      }

      const boardCardViewInitialCalendarViewValue = initialBoardCardView?.initialCalendarViewValue || 'dayGridMonth';
      const boardCardViewMainValue = initialBoardCardView?.mainValue || 'listAll';

      const url = getUrlBoardCardView(boardCardViewMainValue,
        boardCardViewInitialCalendarViewValue, result?.data?.boardId);

      history.push(url);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      let message = status?.message;
      if (status?.message === 'Something went wrong!'
        || status?.message === 'Something went wrong.'
      ) message = 'Link is not valid';
      dispatch({
        type: actionTypes.SET_ERROR_RESPONSE,
        errorResponse: { message },
      });

      history.push(`/errors?previousPath=${location.pathname}`);
    }
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    fetchCardApiThenRedirect();
  }, []);

  return (
    <>
      <MainLayout>
        <LoadingWithAnimation>
          <h1>Tunggu ya</h1>
          <h1>Mengalihkan ke view tugas yang kamu tuju...</h1>
        </LoadingWithAnimation>
      </MainLayout>
    </>
  );
}

export default CardRedirectionPage;
