import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import { isEmpty } from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';
import EmojiPicker from 'emoji-picker-react';
import MoodIcon from '@mui/icons-material/Mood';
import { useSnackbar } from 'notistack';
import draftToHtml from 'draftjs-to-html';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './EditBlastPostPage.module.css';
import Title from '../../components/Title/Title';
import checkIsJson from '../../utilities/checkIsJson';
import ManageSubscribersContainer from '../ManageSubscribersContainer/ManageSubscribersContainer';
import EditorFull from '../FroalaEditor/EditorFull/EditorFull';
import extractElementBySelectorFromString from '../../utilities/extractElementBySelectorFromString';
import handleLoadings from '../../utilities/handleLoadings';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { PostActions, SearchJumpToActions, TeamActions } from '../../actions';
import { PageConstants } from '../../constants';
import apiUtil from '../../utilities/apiUtil';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import { FeedbackLoadingMain } from '../../components/UI_V2/Feedback/Loading';
import EditBlastPostPageSkeleton from './Skeleton/Skeleton';
import { DisplayDividerMain } from '../../components/UI_V2/Display/Divider';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../components/UI_V2/Display/Text';
import Color from '../../themes/colors';
import { InputSwitchMain } from '../../components/UI_V2/Input/Switch';

function EditBlastPostPage() {
  const [{
    user, currentBlast, currentBlastPost, currentTeam, currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const [newMessage, setNewMessage] = useState({
    value: '',
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [postMessage, setPostMessage] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [editorModel, setEditorModel] = useState();
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [trigger, setTrigger] = useState();
  const [subscribers, setSubscribers] = useState([]);
  const [isAccessPrivate, setAccessPrivate] = useState(false);
  const [dueDateOption, setDueDateOption] = useState('auto');
  const [autoCompleteCode, setAutoCompleteCode] = useState('1');
  const [isResetAuto, setResetAuto] = useState(false);
  const [isPostAutoCompleteDueDate, setPostAutoCompleteDueDate] = useState(true);
  const initialDueDate = new Date();
  initialDueDate.setHours(initialDueDate.getHours() + 1);
  initialDueDate.setMinutes(0);
  const [selectedDueDate, setSelectedDueDate] = useState(initialDueDate);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const {
    companyId, teamId, postId,
  } = params;

  const handleCancel = () => {
    history.push(`/companies/${companyId}/teams/${teamId}/posts/${postId}`);
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('editBlastPostPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiBlastDetail = async () => {
      try {
        await PostActions.initiatePost({
          postId, teamId, blastId: currentBlast?._id, companyId,
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('editBlastPostPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    fetchApiBlastDetail();
  }, []);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (postMessage === undefined) {
      return;
    }

    const postApiMessage = async () => {
      try {
        const startLoadings = handleLoadings('editBlastPost', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.patch(`/api/v1/posts/${postId}`, postMessage, {
          params: {
            companyId,
            teamId,
          },
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('editBlastPost', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiMessage();
  }, [postMessage]);

  const onEmojiClick = (code, emoji) => {
    setNewMessage((prevValue) => {
      let newValue;
      if (prevValue.value !== '') {
        newValue = prevValue.value + emoji.emoji;
      } else {
        newValue = emoji.emoji;
      }
      return ({
        ...prevValue,
        value: newValue,
      });
    });
  };

  useEffect(() => {
    if (isEmpty(currentBlastPost)) {
      return;
    }

    let initialContent;
    if (checkIsJson(currentBlastPost?.content)) {
      initialContent = draftToHtml(JSON.parse(currentBlastPost?.content));
    } else {
      initialContent = currentBlastPost?.content;
    }

    setEditorModel(initialContent);

    setNewMessage({ value: currentBlastPost?.title });

    if (currentBlastPost?.isPublic !== undefined) {
      setAccessPrivate(!currentBlastPost?.isPublic);
    }

    if (currentBlastPost?.dueDate) {
      const newDueDate = new Date(currentBlastPost.dueDate);
      setSelectedDueDate(newDueDate);
    }

    if (!currentBlastPost?.autoComplete?.status) {
      setDueDateOption('manual');
      setPostAutoCompleteDueDate(false);
    } else {
      setDueDateOption('auto');
      setPostAutoCompleteDueDate(true);
    }

    // set subscribers
    const tempSubscribers = [];
    if (currentBlastPost?.subscribers) {
      currentBlastPost.subscribers.forEach((subscriber) => {
        if (subscriber) tempSubscribers.push(subscriber);
      });
    }

    setSubscribers([...tempSubscribers]);

    setIsInitialContentReady(true);
  }, [currentBlastPost]);

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewMessage((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleShowEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const disableShowEmojiPicker = () => {
    setShowEmojiPicker(false);
  };

  const handlePostBlast = () => {
    const mentionedUsers = extractElementBySelectorFromString(editorModel, '#mentioned-user');

    // send subscriber ids
    const subscriberIds = [];

    subscribers.forEach((subscriber) => {
      if (!subscriber || !subscriber._id) {
        enqueueSnackbar('Cannot send null subscribers', {
          variant: 'error',
        });
        return;
      }

      subscriberIds.push(subscriber._id);
    });

    let autoCompleteData = {
      status: false,
    };
    let manualDueDateData = selectedDueDate;
    let completeData = currentBlastPost.complete;
    if (dueDateOption === 'auto') {
      const today = new Date();
      const autoCompleteDate = new Date(
        today.setUTCDate(today.getUTCDate() + Number(autoCompleteCode)),
      );
      autoCompleteData = {
        status: true,
        date: autoCompleteDate,
      };
      manualDueDateData = null;
      if (isResetAuto) {
        completeData = {
          status: false,
          updatedAt: null,
        };
      }
    }

    setPostMessage({
      title: newMessage.value,
      content: editorModel,
      subscribers: [...subscriberIds],
      mentionedUsers,
      dueDate: manualDueDateData,
      'isNotified.dueOneDay': false,
      'isNotified.dueOneHour': false,
      isPublic: !isAccessPrivate,
      autoComplete: autoCompleteData,
      complete: completeData,
    });
  };

  useEffect(() => {
    if (trigger === undefined) return;
    handlePostBlast();
  }, [trigger]);

  const handleTriggerPostBlast = () => {
    if (trigger) {
      setTrigger(false);
    } else {
      setTrigger(true);
    }
  };

  const handleSetSubscribers = (incomingSubscribers) => {
    setSubscribers([...incomingSubscribers]);
  };

  const handleEditorModelChange = (model) => {
    setEditorModel(model);
  };

  const handleChangeAccess = useCallback((access) => {
    setAccessPrivate(access);
  }, []);

  const handleDateChangeDueDate = useCallback((date) => {
    setSelectedDueDate(date);
  }, []);

  const handleChangeDueDateOption = (event) => {
    setDueDateOption(event.target.value);
  };

  const handleChangeAutoCompleteCode = (event) => {
    setAutoCompleteCode(event.target.value);
  };

  const handleChangeResetAuto = () => {
    setResetAuto(!isResetAuto);
  };

  const customConfig = {
    heightMin: 200,
  };
  const pageType = PageConstants.pageType.postsEdit;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentBlast, currentBlastPost,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${postId}-edit`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={currentBlastPost.title === undefined ? 'Pengumuman' : `Mengubah ${currentBlastPost.title}`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="blasts"
        withBottomNavBar={false}
      />
      <LayoutMain>
        <SurfacePaperMain>
          <div className={styles.container}>
            <FeedbackLoadingMain
              wait="editBlastPostPage"
              loadings={loadings}
              loadingComponent={<EditBlastPostPageSkeleton />}
            >
              <div className={styles.headerSection}>
                <div className={styles.inputTitleSection}>
                  <TextareaAutosize placeholder="Ketik judul..." onClick={disableShowEmojiPicker} onChange={(event) => handleChanged(event)} minRows={1} maxRows={2} value={newMessage.value} />
                  <MoodIcon onClick={handleShowEmojiPicker} />
                  {showEmojiPicker
                    ? (
                      <div
                        className={styles.emojiPicker}
                      >
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                      </div>
                    ) : null}
                </div>
              </div>
              <DisplayDividerMain marginTop="0px" borderColor={Color.gray4} />
              <div className={styles.postsSection}>
                {isInitialContentReady && currentTeam?.members ? <EditorFull companyId={companyId} model={editorModel} onModelChange={handleEditorModelChange} triggerLists={currentTeam.members} type="blast" handleAction={handleTriggerPostBlast} customConfig={customConfig} /> : null}
              </div>
              {/* <DisplayDividerMain /> */}

              <div className={styles.selectDueDateOption}>
                <div className={styles.selectDueDateOption__title}>
                  <DisplayTextSubHeadline
                    mode="14"
                    color={Color.deepGray2}
                  >
                    Kapan tenggat waktu Pengumuman ini selesai?
                  </DisplayTextSubHeadline>
                </div>
                <div className={styles.selectDueDateOption__chooseOption}>
                  <div className={styles.selectDueDateOption__choose}>
                    <FormControl variant="standard">
                      <Select
                        value={dueDateOption}
                        onChange={handleChangeDueDateOption}
                      >
                        <MenuItem value="auto">Selesai otomatis</MenuItem>
                        <MenuItem value="manual">Atur tenggat waktu sendiri</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <DisplayDividerMain orientation="vertical" flexItem marginRight="1rem" marginLeft="1rem" />
                  {dueDateOption === 'auto' ? (
                    <div className={styles.selectDueDateOption__item}>
                      {isPostAutoCompleteDueDate ? (
                        <div
                          className={styles.selectDueDateOption__item__reset}
                          onClick={handleChangeResetAuto}
                        >
                          <div className={styles.selectDueDateOption__item__reset__text}>
                            <DisplayTextBody
                              color={Color.deepGray2}
                            >
                              Reset tanggal selesai otomatis?
                            </DisplayTextBody>
                          </div>
                          <Checkbox
                            defaultChecked
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            checked={isResetAuto}
                            value={isResetAuto}
                          />
                        </div>
                      ) : null}
                      {isResetAuto || (!isPostAutoCompleteDueDate && dueDateOption === 'auto') ? (
                        <>
                          {isResetAuto ? (
                            <>
                              <DisplayDividerMain orientation="vertical" flexItem marginRight="1rem" marginLeft="0.3rem" />
                              <div className={styles.selectDueDateOption__item__text}>
                                <DisplayTextBody
                                  color={Color.deepGray2}
                                >
                                  Reset ke :
                                </DisplayTextBody>
                              </div>
                            </>
                          ) : (
                            <div className={styles.selectDueDateOption__item__text}>
                              <DisplayTextBody
                                color={Color.deepGray2}
                              >
                                Atur selesai otomatis pada :
                              </DisplayTextBody>
                            </div>
                          )}
                          <FormControl variant="standard">
                            <Select
                              value={autoCompleteCode}
                              onChange={handleChangeAutoCompleteCode}
                            >
                              <MenuItem value="1">1 Hari dari sekarang</MenuItem>
                              <MenuItem value="3">3 Hari dari sekarang</MenuItem>
                              <MenuItem value="7">7 Hari dari sekarang</MenuItem>
                            </Select>
                          </FormControl>
                        </>
                      ) : null}
                    </div>
                  )
                    : (
                      <div className={styles.selectDueDateOption__item}>
                        <div className={styles.selectDueDateOption__item__text}>
                          <DisplayTextBody
                            color={Color.deepGray2}
                          >
                            Tenggat :
                          </DisplayTextBody>
                        </div>
                        <div className={styles.dueDate}>
                          <div className={styles.datePicker}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                            >
                              <DesktopDatePicker
                                inputFormat="yyyy/MM/dd"
                                value={selectedDueDate}
                                onChange={handleDateChangeDueDate}
                                slotProps={{
                                  textField: {
                                    variant: 'standard', // Use "standard" to remove the box outline
                                    sx: {
                                      '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                                      '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                      fontSize: '0.875rem', // Smaller font size
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                          {/* <div className={styles.timePicker}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                          >
                            <TimePicker
                              value={selectedDueDate}
                              onChange={handleDateChangeDueDate}
                              renderInput={
                                (pickerParams) => <TextField variant="standard" {...pickerParams} />
                              }
                            />
                          </LocalizationProvider>
                        </div> */}
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className={styles.manageSubscribers}>
                <ManageSubscribersContainer
                  type="post"
                  initialSubscribers={subscribers}
                  handleSetSubscribers={handleSetSubscribers}
                  companyId={companyId}
                />
              </div>

              <InputSwitchMain
                title="Apakah pengumuman ini Rahasia untuk Penerima aja?"
                labelSwitch="Rahasia"
                handleChange={handleChangeAccess}
                defaultValue={isAccessPrivate}
                marginBottom="0px"
              />
              <div className={styles.actionSection}>
                <div className={styles.actionSection__cancel}>
                  <InputButtonMain handleClick={handleCancel} border="rounded" variant="outlinedDanger" wide="wider">Batal</InputButtonMain>
                </div>
                <InputButtonMain handleClick={handlePostBlast} border="rounded" wide="wider" wait="editBlastPost" spinnerSize="sm" loadings={loadings}>Simpan Perubahan</InputButtonMain>
              </div>
            </FeedbackLoadingMain>
          </div>
        </SurfacePaperMain>
      </LayoutMain>
    </>
  );
}

export default EditBlastPostPage;
