import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ListNameOption.module.css';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import ButtonDiv from '../../../../components/UI/Button/ButtonDiv/ButtonDiv';

const ListNameOption = ({
  cancel, handleUpdateDestListId, handleUpdateViewListName, lists, handleSelectedBoardId
}) => {
  const [loadings, setLoadings] = useState([]);
  const handleSelectList = (listId, listName, boardId) => {
    handleUpdateDestListId({
      droppableId: listId,
      index: 0,
    });

    handleUpdateViewListName({
      listName,
      listPosition: 1,
    });

    handleSelectedBoardId(boardId);
    cancel();
  };

  return (
    <div className={styles.mainSection}>
      <OverlayButton wait="copyCardLists" loadings={loadings}>
        {lists?.length ? lists?.map((list) => (
          <ButtonDiv
            key={list._id}
            onClick={() => {
              handleSelectList(list._id, list.name, list.board);
            }}
            className={styles.listSection}
          >
            <a>{list.name}</a>
          </ButtonDiv>
        )) : (
          <ButtonDiv
            className={styles.listSection}
          >
            <a>-</a>
          </ButtonDiv>
        )}
      </OverlayButton>
    </div>
  );
};

ListNameOption.propTypes = {
  cancel: PropTypes.func.isRequired,
  handleUpdateDestListId: PropTypes.func.isRequired,
  handleUpdateViewListName: PropTypes.func.isRequired,
  handleSelectedBoardId: PropTypes.func.isRequired,
  lists: PropTypes.array.isRequired,
};

export default ListNameOption;
