import React, {
  useContext, useEffect, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import styles from './CheckLoginPage.module.css';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { CheckLoginActions, CompanyActions, TeamActions } from '../../actions';
import LoadingWithAnimation from '../../components/UI/LoadingWithAnimation/LoadingWithAnimation';

function CheckLoginPage() {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [wait, setWait] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);

  let previousPath = '/';

  if (query.previousPath) {
    const searchParams = new URLSearchParams(location.search);
    previousPath = searchParams.get('previousPath');
  }

  const isSignUp = previousPath.includes('/signup');
  const isSignIn = previousPath.includes('/signin');

  const goToWelcomePage = () => {
    history.push('/welcome');
  };

  const goToSignIn = () => {
    history.push('/signin');
  };

  const goToSignUp = () => {
    history.push('/signup');
  };

  const goToInvitation = (emailInvitationToken) => {
    history.push(`/invitation/${emailInvitationToken}`);
  };

  const goToRedirectHome = (companyId) => {
    history.push(`/companies/${companyId}`);
  };

  const goToPreviousPath = () => {
    history.push(previousPath);
  };

  useEffect(() => {
    const { tempToken } = query;
    const { userId } = query;
    const token = localStorage.getItem('token');
    const emailInvitationToken = localStorage.getItem('emailInvitationToken');

    const checkAuthentication = async () => {
      // this page can check several flows.
      // 1. for checking and redirecting user that is get invited by email
      // but not logged in yet, by providing tempToken, userId,
      // and has localStorage emailInvitationToken
      // after successful google/apple login
      // 2. for checking usual login only (not from email invitation)
      // by providing tempToken & userId after successful google/apple login
      // 3. for checking if token is exist but no user object, client can
      // request the user object by providing the correct token.
      // 4. On poin 2 and 3, if user does not have any company, then he will be
      // forced to create company or input invitation link.
      try {
        // if (isSignIn) {
        //   goToSignIn();
        //   return;
        // }

        // if (isSignUp) {
        //   goToSignUp();
        //   return;
        // }

        if (tempToken && userId) {
          const resultUser = await CheckLoginActions.getTokenAndUserWithTempToken(
            { tempToken, userId },
            dispatch,
          );

          if (emailInvitationToken) {
            goToInvitation(emailInvitationToken);
            return;
          }

          const resultCompanies = await CompanyActions.initiateCompanies({}, dispatch);

          if (resultCompanies?.length < 1) {
            goToWelcomePage();
            return;
          }

          const resultCompany = await CheckLoginActions.initiateCurrentCompany(
            { previousPath, companies: resultCompanies, userId: resultUser?._id },
            dispatch,
          );

          TeamActions.initiateTeams(
            {
              currentCompany: resultCompany,
              userId: resultUser?._id,
            },
            dispatch,
          );
          if (resultCompany && previousPath === '/') {
            goToRedirectHome(resultCompany?._id);
            return;
          }
        }

        if (token !== undefined && user._id === undefined) {
          const resultUser = await CheckLoginActions.getUserWithToken(
            {},
            dispatch,
          );

          const resultCompanies = await CompanyActions.initiateCompanies({}, dispatch);
          if (resultCompanies?.length < 1) {
            goToWelcomePage();
            return;
          }
          const resultCompany = await CheckLoginActions.initiateCurrentCompany(
            { previousPath, companies: resultCompanies, userId: resultUser?._id },
            dispatch,
          );

          TeamActions.initiateTeams(
            {
              currentCompany: resultCompany,
              userId: resultUser?._id,
            },
            dispatch,
          );
          if (resultCompany && previousPath === '/') {
            goToRedirectHome(resultCompany?._id);
          }
        }
      } catch (error) {
        if (error?.code !== 401) {
          const status = handleStatusMsg(error, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
        goToSignIn();
      } finally {
        setWait(false);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    if (wait) return;
    goToPreviousPath();
  }, [wait]);

  return (
    <>
      <MainLayout>
        <LoadingWithAnimation>
          {/* <h1>Please Wait</h1>
          <h1>Logging you in...</h1> */}
          <h1>Tunggu ya,</h1>
          <h1>Memproses...</h1>
        </LoadingWithAnimation>
      </MainLayout>
    </>
  );
}

export default CheckLoginPage;
