import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import styles from './FromUntilDateFilterSection.module.css';
import { useDelayShowHideHandler, useSearch, useSelectItemsOnList } from '../../../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import { DisplayTextBody, DisplayTextSubBody } from '../../../UI_V2/Display/Text';
import ButtonTextWithCheck from '../ButtonTextWithCheck/ButtonTextWithCheck';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import { limitChar } from '../../../../utilities/stringUtil';
import { DisplayDividerMain } from '../../../UI_V2/Display/Divider';
import Color from '../../../../themes/colors';
import ButtonTextWithCheckBox from '../ButtonTextWithCheckBox/ButtonTextWithCheckBox';
import { InputButtonMain } from '../../../UI_V2/Input/Button';
import QuickDateSelection from './QuickDateSelection/QuickDateSelection';

const FromUntilDateFilterSection = ({
  selectFromUntilHook,
  onApply,
  selectedQuickDate,
  handleSelectQuickDate,
  modifyFromUntilDateByQuickDate,
  handleSetIsFirstLoadDate,
  handleUnsetIsFirstLoadDate,
  listDisableQuickDate,
}) => {
  const [
    initFromDate,
    fromDate,
    handleDateChangeFrom,
    handleDateChangeInitFrom,
    initUntilDate,
    untilDate,
    handleDateChangeUntil,
    handleDateChangeInitUntil,
  ] = selectFromUntilHook;

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const handleSetIsFirstLoad = useCallback((value) => {
    setIsFirstLoad(value);
  }, []);

  const isShowCustomQuickDate = selectedQuickDate === 'custom' && !isFirstLoad;

  // if (isShowCustomQuickDate) handleUnsetIsFirstLoadDate(false);

  return (
    <Box className={styles.container}>
      <Box className={styles.titleBox}>
        <DisplayTextBody>
          Filter Periode
        </DisplayTextBody>
      </Box>
      <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0.4rem" />
      {!isShowCustomQuickDate && (
      <QuickDateSelection
        onApply={onApply}
        handleSelectQuickDate={handleSelectQuickDate}
        selectedQuickDate={selectedQuickDate}
        listDisableQuickDate={listDisableQuickDate}
        // handleDateChangeFrom={handleDateChangeFrom}
        // handleDateChangeUntil={handleDateChangeUntil}
        modifyFromUntilDateByQuickDate={modifyFromUntilDateByQuickDate}
        handleSetIsFirstLoad={handleSetIsFirstLoad}
        handleUnsetIsFirstLoadDate={handleUnsetIsFirstLoadDate}
        handleSetIsFirstLoadDate={handleSetIsFirstLoadDate}
      />
      )}
      {isShowCustomQuickDate && (
      <>
        <div className={styles.dateWrapper}>
          <div className={styles.dateSection}>
            <div className={styles.text}>
              <DisplayTextBody>
                From
              </DisplayTextBody>
            </div>
            <div className={styles.datePicker}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                // inputFormat="yyyy/MM/dd"
                  value={fromDate}
                  onChange={handleDateChangeFrom}
                  slotProps={{
                    textField: {
                      variant: 'standard', // Use "standard" to remove the box outline
                      sx: {
                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                        '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        fontSize: '0.875rem', // Smaller font size
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className={styles.dateSection}>
            <div className={styles.text}>
              <DisplayTextBody>
                Until
              </DisplayTextBody>
            </div>
            <div className={styles.datePicker}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                // inputFormat="yyyy/MM/dd"
                  value={untilDate}
                  onChange={handleDateChangeUntil}
                  slotProps={{
                    textField: {
                      variant: 'standard', // Use "standard" to remove the box outline
                      sx: {
                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Removes the default outline
                        '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        fontSize: '0.875rem', // Smaller font size
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div className={styles.action}>
          <InputButtonMain
            handleClick={() => {
              onApply();
              handleUnsetIsFirstLoadDate(false);
            }}
            size="sm"
            wide="block"
          >
            Save
          </InputButtonMain>
        </div>
      </>
      )}
    </Box>
  );
};

FromUntilDateFilterSection.propTypes = {
  // featureTypes: PropTypes.array,
  onApply: PropTypes.func.isRequired,
  selectFromUntilHook: PropTypes.object.isRequired,
  selectedQuickDate: PropTypes.oneOf([
    'today',
    'yesterday',
    'thisWeek',
    'previousWeek',
    'thisTwoWeek',
    'thisMonth',
    'previousMonth',
    'thisQuarter',
    'previousQuarter',
    'thisYear',
    'previousYear',
    'custom',
  ]),
  handleSelectQuickDate: PropTypes.func.isRequired,
  modifyFromUntilDateByQuickDate: PropTypes.func.isRequired,
  handleSetIsFirstLoadDate: PropTypes.func.isRequired,
  handleUnsetIsFirstLoadDate: PropTypes.func.isRequired,
  listDisableQuickDate: PropTypes.array,
};

FromUntilDateFilterSection.defaultProps = {
  selectedQuickDate: 'thisMonth',
  listDisableQuickDate: [],
};

export default memo(FromUntilDateFilterSection);
