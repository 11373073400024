import React, { useCallback, memo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styles from './AttachFileContainer.module.css';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { useDropZoneFile } from '../../hooks/DropFileHook';
import { GlobalContext } from '../../contexts/GlobalStateProvider';

const AttachFileContainer = ({
  handleLoading,
  cancel,
  children,
  disableClickUpload,
  handleUploadForm,
  handleModifyOptionalParams,
  type,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const uploadFileByDrop = useCallback(async (files) => {
    if (!files) return;
    const data = new FormData();

    let optionalParams;

    if (handleModifyOptionalParams) {
      optionalParams = handleModifyOptionalParams();
    }

    for (let i = 0; i < files.length; i += 1) {
      data.append('file', files[i]);
    }

    handleLoading(true);
    try {
      const result = await handleUploadForm(data, false, optionalParams);
      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (err) {
      const getErrorMessageDropzoneFile = files?.[0]?.errors?.[0];
      const messageError = getErrorMessageDropzoneFile === undefined
        ? err : getErrorMessageDropzoneFile;
      const status = handleStatusMsg(messageError, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      handleLoading(false);
      if (cancel) cancel();
    }
  }, [location, type]);

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length < 1) {
      await uploadFileByDrop(fileRejections);
    } else {
      await uploadFileByDrop(acceptedFiles);
    }
  }, [location, type]);

  const {
    getRootProps,
    getInputProps,
  } = useDropZoneFile({
    noDragEventsBubbling: true,
    onDrop,
  });

  return (
    <div className={styles.container} {...getRootProps()}>
      {disableClickUpload ? null : <input {...getInputProps()} />}
      {children}
    </div>
  );
};

AttachFileContainer.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
  handleLoading: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  disableClickUpload: PropTypes.bool,
  forceTeamId: PropTypes.string,
  handleUploadForm: PropTypes.func,
  handleModifyOptionalParams: PropTypes.func,
};

AttachFileContainer.defaultProps = {
  disableClickUpload: false,
  forceTeamId: undefined,
  handleUploadForm: () => null,
  handleModifyOptionalParams: undefined,
};

// const isComponentDataEqual = (prevProps, nextProps) => {
//   const { card, type } = nextProps;
//   if (typeof prevProps.card === 'undefined') {
//     return type;
//   }
//   return isEqual(prevProps.card, card)
//   && prevProps.type === type;
// };

export default memo(AttachFileContainer);
