import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import styles from './SwitchCreateCompany.module.css';
import HeaderSwitchCompany from './HeaderSwitchCompany/HeaderSwitchCompany';
import BodySwitchCompany from './BodySwitchCompany/BodySwitchCompany';
import Line from '../../../components/UI/Line/Line';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import handleLoadings from '../../../utilities/handleLoadings';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { CompanyActions } from '../../../actions';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import CreateCompanyModal from './CreateCompanyModal/CreateCompanyModal';

const SwitchCreateCompany = ({ cancel, handleDisableOutsideClick }) => {
  const [{
    counterCompaniesNotif,
    currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showCreateCompany, setShowCreateCompany] = useState(false);

  const { companyId } = params;

  const handleClickHeaderCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleShowCreateCompany = () => {
    setShowCreateCompany(true);
    handleDisableOutsideClick(true);
  };

  const handleCloseCreateCompany = () => {
    setShowCreateCompany(false);
    handleDisableOutsideClick(false);
  };

  const lineCustomStyle = {
    border: '2px solid #EFF5FF',
    marginTop: '6px',
    marginBottom: '0px',
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <HeaderSwitchCompany
            isCollapsed={isCollapsed}
            company={currentCompany}
            handleClickCollapsed={handleClickHeaderCollapsed}
            handleClickCreate={handleShowCreateCompany}
          />
        </div>
        {counterCompaniesNotif?.companies?.length > 0 ? (
          <>
            <Line customStyle={lineCustomStyle} />
            <div className={styles.body}>
              <OverlayButton wait="chgDefaultCompany" loadings={loadings}>
                <BodySwitchCompany
                  companies={counterCompaniesNotif.companies}
                  companyId={companyId}
                  cancel={cancel}
                />
              </OverlayButton>
            </div>
          </>
        ) : <div style={{ marginTop: '6px' }} />}
      </div>

      <CreateCompanyModal show={showCreateCompany} onHide={handleCloseCreateCompany} />
    </>
  );
};

SwitchCreateCompany.propTypes = {
  cancel: PropTypes.func.isRequired,
  handleDisableOutsideClick: PropTypes.func.isRequired,
};

export default SwitchCreateCompany;
