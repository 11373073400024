import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Tooltip from '@mui/material/Tooltip';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useSnackbar } from 'notistack';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import styles from './BucketPage.module.css';
import NewItemMenu from './NewItemMenu/NewItemMenu';
import BucketMenu from './BucketMenu/BucketMenu';
import Title from '../../components/Title/Title';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import { BucketActions, SearchJumpToActions, TeamActions } from '../../actions';
import BucketList from './BucketList/BucketList';
import { useArchivedBucketItemsHooks, useBucketSocket } from '../../hooks/BucketHooks';
import apiUtil from '../../utilities/apiUtil';
import { useDropZoneFile } from '../../hooks/DropFileHook';
import PrivateIcon from '../../components/UI/PrivateIcon/PrivateIcon';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { PageConstants } from '../../constants';
import ArchivedItemsBar from '../../components/Container/ArchivedItemsBar/ArchivedItemsBar';
import FilterBar from '../../components/Container/FilterBar/FilterBar';
import { hasPermission } from '../../actions/RoleActions';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, roles, typeRef } from '../../constants/RoleConstants';
import FeedbackLoadingMain from '../../components/UI_V2/Feedback/Loading/Main/Main';
import BucketSkeleton from './BucketSkeleton/BucketSkeleton';
import { useDelayShowHideHandler, useUploadForm } from '../../hooks/HelperHooks';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { checkIfUserAuthorizedToEditData } from '../../actions/UserActions';
import { useInitiateTeamBelowRole } from '../../hooks/TeamHooks';
import { DisplayCreatorMain } from '../../components/UI_V2/Display/Creator';
import { DisplayAvatarMemberWithOnline } from '../../components/UI_V2/Display/Avatar';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import { FeedbackLoadingDrawer } from '../../components/UI_V2/Feedback/Loading';

function BucketPage() {
  const [{
    user, currentBucket, currentTeam,
    bucketDocPath, currentCompany, currentRoleUser,
    currentTeamBelowRole,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [shouldFetchSubscribers, setShouldFetchSubscribers] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [showArchivedItems, setShowArchivedItems] = useState(false);
  const [initiateListBucketItems,
    loadMoreListBucketItems,
    unarchiveBucketItem] = useArchivedBucketItemsHooks();
  const [showMenu, setShowMenu] = useState(false);
  const [showBucketMenu, setShowBucketMenu] = useState(false);
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, bucketId } = params;

  useBucketSocket({ bucketId, userId: user?._id }, dispatch);
  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.bucket,
      featureId: bucketId,
    },
  });

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  // initiate members below role
  // useInitiateTeamBelowRole({});

  // React dropzone
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [files, setFiles] = useState();
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length < 1) {
      setFiles(fileRejections);
    } else {
      setFiles(acceptedFiles);
    }
  }, []);

  const handleLoadingUpload = (value) => {
    setLoadingUpload(value);
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropZoneFile({
    onDrop,
  });

  const {
    progressUpload,
    uploadForm,
    listFilesBeingUploaded,
  } = useUploadForm(`${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/buckets/${bucketId}/files`, companyId, teamId);

  useEffect(() => {
    if (files === undefined) {
      return;
    }

    const postApiFiles = async () => {
      const data = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        data.append('file', files[i]);
      }

      handleLoadingUpload(true);

      try {
        const result = await uploadForm(data);

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
      } catch (err) {
        const getErrorMessageDropzoneFile = files?.[0]?.errors?.[0];
        const messageError = getErrorMessageDropzoneFile === undefined
          ? err : getErrorMessageDropzoneFile;
        const status = handleStatusMsg(messageError, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        handleLoadingUpload(false);
      }
    };
    postApiFiles();
  }, [files]);
  //

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('bucketPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiBucketDetail = async () => {
      try {
        await BucketActions.initiateBucket(
          { bucketId, companyId, teamId }, dispatch,
        );
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);

        // setShouldFetchSubscribers(true);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('bucketPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    fetchApiBucketDetail();

    // eslint-disable-next-line consistent-return
    return () => {
      setShouldFetchSubscribers(false);
    };
  }, [location]);

  // const fetchBucketSubscribersApi = async () => {
  //   try {
  //     const result = await BucketActions.initiateBucketSubscribers({
  //       bucketId,
  //       companyId,
  //       teamId,
  //     }, dispatch);

  //     const resultSubscribers = result?.data?.subscribers;
  //     const tempSubscribers = [];
  //     if (resultSubscribers) {
  //       resultSubscribers.forEach((subscriber) => {
  //         tempSubscribers.push(subscriber);
  //       });
  //     }

  //     setSubscribers([...tempSubscribers]);
  //   } catch (err) {
  //     const status = handleStatusMsg(err, 'error');

  //     dispatch({
  //       type: actionTypes.SET_ERROR_RESPONSE,
  //       errorResponse: { message: status.message },
  //     });

  //     history.push(`/errors?previousPath=${location.pathname}`);
  //   }
  // };

  // useEffect(() => {
  //   if (!shouldFetchSubscribers) {
  //     return;
  //   }
  //   fetchBucketSubscribersApi();
  // }, [shouldFetchSubscribers]);

  const handleShowMenu = () => {
    setShowMenu(true);
  };

  const handleCancelShowMenu = () => {
    setShowMenu(false);
  };

  const handleShowBucketMenu = () => {
    setShowBucketMenu(true);
  };

  const handleCancelShowBucketMenu = () => {
    setShowBucketMenu(false);
  };

  const handleShowArchivedItems = () => {
    setShowArchivedItems(true);
  };

  const handleHideArchivedItems = () => {
    setShowArchivedItems(false);
  };

  /* Show/Hide Filters */
  const [showFilters, setShowFilters] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleShowFilters = () => {
    setShowFilters(true);
  };

  const handleHideFilters = () => {
    setShowFilters(false);
  };

  const handleSetIsFilterActive = (value) => {
    setIsFilterActive(value);
  };
  //

  let titlePage = 'Dokumen & File';
  if (currentBucket?.title && currentBucket?.title !== 'Docs & Files') titlePage = currentBucket.title;

  const isMainBucketPage = currentBucket?.type === 'main';
  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const pageType = PageConstants.pageType.buckets;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, bucketDocPath, currentTeam, currentBucket,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: bucketId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={titlePage} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="buckets"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton
              handleClick={handleShowMenu}
              buttonMode="outlined"
              text="Tambah Berkas"
              icon={<AddIcon />}
            />
            {!isMainBucketPage
            && checkIfUserAuthorizedToEditData(currentBucket,
              user, currentRoleUser,
              currentTeamBelowRole)
            && (
            <GlobalActionButton
              handleClick={handleShowBucketMenu}
              buttonMode="outlined"
              text="Ubah Folder ini"
              icon={<EditOutlinedIcon />}
            />
            )}
            {showMenu
              ? (
                <NewItemMenu
                  cancel={handleCancelShowMenu}
                  bucket={currentBucket}
                  handleLoading={handleLoadingUpload}
                  handleUploadFiles={uploadForm}
                />
              ) : null}
            {showBucketMenu
              ? (
                <BucketMenu
                  bucket={currentBucket}
                  cancel={handleCancelShowBucketMenu}
                />
              ) : null}
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="Akses Saya"
              buttonMode="outlined"
              icon={<VerifiedUserIcon />}
            />
            <GlobalActionButton
              handleClick={handleShowFilters}
              buttonMode="outlined"
              text="Filter"
              icon={<FilterAltOutlinedIcon />}
            >
              {isFilterActive ? (
                <Tooltip
                  title="Filter-mu aktif. Atur atau hapus filter kalo kamu ga menemukan yang kamu cari."
                >
                  <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
                </Tooltip>
              ) : null}
            </GlobalActionButton>
            {isMainBucketPage && isUserManagerOrAbove && (
            <GlobalActionButton
              handleClick={handleShowArchivedItems}
              buttonMode="outlined"
              text="Arsip Berkas"
              icon={<ArchiveOutlinedIcon />}
            />
            )}
          </>
        )}
        bottomBarOtherActions={(
          <>
            {/* <GlobalActionButton
              handleClick={handleShowFilters}
              buttonMode="outlined"
              text="Filter"
              icon={<FilterAltOutlinedIcon />}
            >
              {isFilterActive ? (
                <Tooltip
                  title="Filter-mu aktif.
                  Atur atau hapus filter kalo kamu ga menemukan yang kamu cari."
                >
                  <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
                </Tooltip>
              ) : null}
            </GlobalActionButton>
            {isMainBucketPage && isUserManagerOrAbove && (
            <GlobalActionButton
              handleClick={handleShowArchivedItems}
              buttonMode="outlined"
              text="Arsip Berkas"
              icon={<ArchiveOutlinedIcon />}
            />
            )} */}
          </>
        )}
      />
      <LayoutMain>
        <SurfacePaperMain>
          <FeedbackLoadingMain
            wait="bucketPage"
            loadings={loadings}
            loadingComponent={<BucketSkeleton />}
          >
            <div className={styles.container} {...getRootProps()}>
              {showArchivedItems
            && isMainBucketPage && (
            <ArchivedItemsBar
              initiateListDataFunc={initiateListBucketItems}
              loadMoreListDataFunc={loadMoreListBucketItems}
              unarchiveDataFunc={unarchiveBucketItem}
              onClose={handleHideArchivedItems}
              barTitle="Arsip Berkas"
              featureType="bucket"
              topPositionVariant="withBottomBar"
            />
              )}
              <div className={styles.box}>
                <div className={styles.headerSection__container}>
                  {currentBucket.archived ? (
                    <>
                      {currentBucket.archived.status ? (
                        <div className={styles.archivedSection}>
                          <ArchiveIcon />
                          <h1>Folder ini udah terarsip</h1>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {currentBucket?.creator?._id && (
                  <div className={styles.creator}>
                    <DisplayCreatorMain data={currentBucket} />
                  </div>
                  )}
                </div>
                {/* <SeparatorLine /> */}
                <div className={styles.docsSection}>
                  <BucketList
                    showFilters={showFilters}
                    handleHideFilters={handleHideFilters}
                    handleSetIsFilterActive={handleSetIsFilterActive}
                  />
                </div>
              </div>
              {isDragActive ? (
                <>
                  <div className={`${styles.box} ${styles.backgroundOverlay}`} />
                  <div className={`${styles.box} ${styles.contentOverlay}`}>
                    <h1>Lepaskan file untuk diunggah.</h1>
                  </div>
                </>
              ) : null}
              {/* {loadingUpload ? (
                <>
                  <div className={`${styles.box} ${styles.backgroundOverlay}`} />
                  <div className={`${styles.box} ${styles.contentOverlay}`}>
                    <div className={styles.loadingContainer}>
                      <Spinner animation="border" size="sm" variant="warning" />
                    </div>
                  </div>
                </>
              ) : null} */}
              <FeedbackLoadingDrawer
                loadingUpload={loadingUpload}
                listFilesBeingUploaded={listFilesBeingUploaded}
                progressUpload={progressUpload}
              />
            </div>

            { currentBucket.type === 'folder' && (
              <>
                <SeparatorLine />
                {currentBucket?.subscribers !== undefined ? (
                  <div className={styles.Subscribers}>
                    <div className={styles.subscribersHeader}>
                      {currentBucket?.subscribers?.length < 1
                        ? (
                          <p>
                            <strong>Folder ini belum punya penerima.</strong>
                          </p>
                        ) : <p>Folder ini diterima oleh : </p>}
                    </div>
                    <div className={styles.subscribersItem}>

                      {currentBucket?.subscribers?.map((subscriber) => (
                        <div>
                          <DisplayAvatarMemberWithOnline
                            src={subscriber.photoUrl}
                            userId={subscriber._id}
                            size="sm"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </FeedbackLoadingMain>
        </SurfacePaperMain>
        <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureId={bucketId}
          featureType={typeRef.bucket}
          featureTitle={currentBucket?.title}
          roleType={roleTypeRef.feature}
          featureData={currentBucket}
        />
      </LayoutMain>
    </>
  );
}

export default BucketPage;
