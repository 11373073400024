import React from 'react';
import PropTypes from 'prop-types';
import styles from './ShowCheers.module.css';
import CheersItem from './CheersItem/CheersItem';
import CheersButton from './CheersButton/CheersButton';

const ShowCheers = (
  {
    type, // use this instead
    // newVersion, //use this instead
    comment, // use this instead
    message,
    loadings,
    showDelete,
    handleToggleDelete,
    handleDeleteCheer,
    cheers,
    receiver,
    // primaryParent, //this is deprecated
    // secondaryParent, //this is deprecated
    // thirdParent //this is deprecated
    parentIds,
  },
) => (
  <div className={styles.container}>
    {cheers?.map((cheer) => (
      <CheersItem
            // looks like no needed here
            // type={type}
            // newVersion={newVersion}
            // comment={comment}
        loadings={loadings}
        showDelete={showDelete}
        handleDeleteCheer={handleDeleteCheer}
        handleToggleDelete={handleToggleDelete}
        cheer={cheer}
      />
    ))}
    <CheersButton
      type={type}
            // newVersion={newVersion}
      comment={comment}
      message={message}
      receiver={receiver}
      parentIds={parentIds}
    />
  </div>
);

ShowCheers.propTypes = {
  type: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  receiver: PropTypes.string.isRequired,
  loadings: PropTypes.array.isRequired,
  showDelete: PropTypes.string.isRequired,
  handleToggleDelete: PropTypes.func.isRequired,
  handleDeleteCheer: PropTypes.func.isRequired,
  cheers: PropTypes.array.isRequired,
  parentIds: PropTypes.array,
};

ShowCheers.defaultProps = {
  parentIds: [],
};

export default ShowCheers;
