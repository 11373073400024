import React, {
  memo, useContext, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import styles from './SelectTeamUserReport.module.css';
import Color from '../../../../themes/colors';
import { InputSearchGeneral } from '../../../../components/UI_V2/Input/Search';
import TeamItemUserReport from './TeamItemUserReport/TeamItemUserReport';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { useSearch } from '../../../../hooks/HelperHooks';

import handleLoadings from '../../../../utilities/handleLoadings';
import { TeamActions } from '../../../../actions';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';

const SelectTeamUserReport = ({
  variantUrlTeam,
}) => {
  const [
    {
      teams,
      currentTeam,
      currentCompany,
      user,
    },
    dispatch,
  ] = useContext(GlobalContext);

  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = useSearch({ originalList: teams });

  const params = useParams();

  const { companyId, teamId, memberId } = params;

  // useEffect(() => {
  //   const startLoadings = handleLoadings('initiateTeams', [...loadings], 'start');
  //   setLoadings([...startLoadings]);

  //   if (!user?._id) {
  //     return;
  //   }

  //   if (!currentTeam || currentTeam?._id !== teamId) {
  //     return;
  //   }

  //   const fetchTeams = async () => {
  //     try {
  //       await TeamActions.initiateTeams({ currentCompany, userId: user?._id });
  //     } catch (error) {
  //       console.log(error);
  //       const status = handleStatusMsg(error, 'error');

  //       enqueueSnackbar(status.message, {
  //         variant: 'error',
  //       });
  //     } finally {
  //       const endLoadings = handleLoadings('initiateTeams', [...loadings], 'end');
  //       setLoadings([...endLoadings]);
  //     }
  //   };

  //   fetchTeams();
  // }, [currentTeam]);

  const isTeamSelected = (team) => team._id === teamId;

  const getUrlClickTeamByVariant = useCallback(
    (companyIdValue, teamIdValue, memberIdValue, variantValue) => {
      let url;
      switch (variantValue) {
        case 'realtime':
          url = `/companies/${companyIdValue}/teams/${teamIdValue}/reports/members/${memberIdValue}?variantUrl=realtime`;
          break;
        case 'recapMonthWeekly':
          url = `/companies/${companyIdValue}/teams/${teamIdValue}/reports/members/${memberIdValue}?variantUrl=recapMonthWeekly`;
          break;
        default:
          break;
      }

      return url;
    }, [],
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <DisplayTextBody
          decoration="bold"
        >
          Pilih Tim :
        </DisplayTextBody>
      </div>
      <div className={styles.body}>
        <div className={styles.search}>
          <InputSearchGeneral
            onChange={onChange}
            value={keyword}
            placeholder="Cari tim..."
            onClickCloseIcon={resetSearch}
            size="sm"
            autoFocus={false}
          />
        </div>
        <div className={styles.list}>
          {searchResult?.length > 0 && searchResult?.map((team) => (
            <LinkNoDecor
              to={getUrlClickTeamByVariant(
                companyId,
                team._id,
                memberId,
                variantUrlTeam,
              )}
            >
              <TeamItemUserReport team={team} isSelected={isTeamSelected(team)} />
            </LinkNoDecor>
          ))}
          {searchResult?.length < 1 && (
          <DisplayTextBody>
            Tim ga ketemu
          </DisplayTextBody>
          )}
        </div>
      </div>
    </div>
  );
};

SelectTeamUserReport.propTypes = {
  variantUrlTeam: PropTypes.oneOf(['recapMonthWeekly', 'realtime']),
};

SelectTeamUserReport.defaultProps = {
  variantUrlTeam: 'realtime',
};

export default memo(SelectTeamUserReport);
