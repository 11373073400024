import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './CardListItem.module.css';
import Color from '../../../themes/colors';
import CardListItemName from './CardListItemName/CardListItemName';
import CardListItemList from './CardListItemList/CardListItemList';
import CardListItemTeam from './CardListItemTeam/CardListItemTeam';
import CardListItemDueDate from './CardListItemDueDate/CardListItemDueDate';
import CardListItemLabels from './CardListItemLabels/CardListItemLabels';
import CardListItemMembers from './CardListItemMembers/CardListItemMembers';
import EditCardMenu from '../../../pages/ListContainer/EditCardMenu/EditCardMenu';
import ManageMoveCard from '../../../pages/CardDetailContainer/ManageMoveCard/ManageMoveCard';
import ManageLabelsContainer from '../../../pages/ManageLabelsContainer/ManageLabelsContainer';
import ManageDueDateContainer from '../../../pages/ManageDueDateContainer/ManageDueDateContainer';
import ManageMultipleMembersContainer from '../../../pages/ManageMultipleMembersContainer/ManageMultipleMembersContainer';
import ManageDescWithBox from '../../../pages/CardDetailContainer/ManageDescWithBox/ManageDescWithBox';
import ChangeNameFormWithBox from '../../../pages/CardDetailContainer/ChangeNameFormWithBox/ChangeNameFormWithBox';
import QuickCommentsWithBox from '../../../pages/CardDetailContainer/QuickCommentsWithBox/QuickCommentsWithBox';
import ManageCopyCard from '../../../pages/CardDetailContainer/ManageCopyCard/ManageCopyCard';

const selectedStyles = {
  true: styles.selectedTrue,
  false: styles.selectedFalse,
};

const CardListItem = ({
  item,
  isSelected,
  setSelected,
  showEditMenu,
  handleShowEditMenu,
  handleHideEditMenu,
  showQuickComment,
  handleShowQuickComment,
  handleHideQuickComment,
  // showLabel,
  // handleShowLabel,
  // handleHideLabel,
  // showDueDate,
  // handleShowDueDate,
  // handleHideDueDate,
  // showMove,
  // handleShowMove,
  // handleHideMove,
  // showMember,
  // handleShowMember,
  // handleHideMember,
  showName,
  showCopy,
  handleShowName,
  handleShowCopy,
  handleHideName,
  handleHideCopy,
  showNote,
  handleShowNote,
  handleHideNote,
  featureType,
  enableCreateList,
}) => {
  const itemRef = useRef(null);
  const triggerScrollIntoView = () => {
    if (itemRef?.current) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <>
      <div ref={itemRef} className={`${styles.container} ${selectedStyles[isSelected]}`}>
        <CardListItemName
          item={item}
          widthPercentage={36}
          variantBorder="noTopLeftBorder"
          setSelected={setSelected}
          triggerScrollIntoView={triggerScrollIntoView}
          showEditMenu={showEditMenu}
          handleShowEditMenu={handleShowEditMenu}
          handleHideEditMenu={handleHideEditMenu}
          featureType={featureType}
        />
        <CardListItemMembers
          item={item}
          widthPercentage={14}
          variantBorder="noTopLeftBorder"
          setSelected={setSelected}
          triggerScrollIntoView={triggerScrollIntoView}
        />
        <CardListItemLabels
          item={item}
          widthPercentage={14}
          variantBorder="noTopLeftBorder"
          setSelected={setSelected}
          triggerScrollIntoView={triggerScrollIntoView}
        />
        <CardListItemDueDate
          item={item}
          widthPercentage={12}
          variantBorder="noTopLeftBorder"
          setSelected={setSelected}
          triggerScrollIntoView={triggerScrollIntoView}
        />
        <CardListItemList
          item={item}
          widthPercentage={12}
          variantBorder="noTopLeftBorder"
          setSelected={setSelected}
          triggerScrollIntoView={triggerScrollIntoView}
          enableCreateList={enableCreateList}
        />
        <CardListItemTeam
          item={item}
          widthPercentage={12}
          variantBorder="noTopLeftRightBorder"
          setSelected={setSelected}
          triggerScrollIntoView={triggerScrollIntoView}
        />

      </div>
      {showEditMenu === item._id && (
      <div className={styles.editMenuBox}>
        <EditCardMenu
          card={item}
          list={item.list}
          handleShowQuickComment={handleShowQuickComment}
          handleShowNote={handleShowNote}
          handleShowName={handleShowName}
          handleShowCopy={handleShowCopy}
          // handleShowMove={handleShowMove}
          // handleShowMember={handleShowMember}
          // handleShowLabel={handleShowLabel}
          // handleShowDueDate={handleShowDueDate}
          handleCancelAllCardMenu={handleHideEditMenu}
          selectIdVariant="cardId"
          disableScrollIntoView
          forceTeamId={item.team._id}
          disableEditDueDate
          disableEditMembers
          disableEditMoveCard
          disableEditLabels
        />
        {showCopy === item._id && (
          <ManageCopyCard
            card={item}
            list={item.list}
            cancel={handleHideCopy}
            bottom
          />
        )}
        {/* {showMove === item._id
                                    && (
                                    <ManageMoveCard
                                      cancel={handleHideMove}
                                      left
                                      card={item}
                                      list={item.list}
                                    />
                                    )} */}
        {/* {showLabel === item._id
                                    && (
                                    <ManageLabelsContainer
                                      left
                                      cancel={handleHideLabel}
                                      card={item}
                                    />
                                    )}
        {showDueDate === item._id
                                    && (
                                    <ManageDueDateContainer
                                      left
                                      cancel={handleHideDueDate}
                                      card={item}
                                    />
                                    )}
        {showMember === item._id
                                    && (
                                    <ManageMultipleMembersContainer
                                      cancel={handleHideMember}
                                      type="card"
                                      left
                                      card={item}
                                      list={item.list}
                                    />
                                    )} */}
        {showNote === item._id
                                    && (
                                    <ManageDescWithBox
                                      cancel={handleHideNote}
                                      left
                                      card={item}
                                      list={item.list}
                                      disableScrollIntoView
                                    />
                                    )}
        {showName === item._id
                                    && (
                                    <ChangeNameFormWithBox
                                      cancel={handleHideName}
                                      left
                                      card={item}
                                      list={item.list}
                                    />
                                    )}
        {showQuickComment === item._id
                                    && (
                                    <QuickCommentsWithBox
                                      cancel={handleHideQuickComment}
                                      type="card"
                                      left
                                      card={item}
                                      list={item.list}
                                      disableScrollIntoView
                                    />
                                    )}
      </div>
      )}
    </>
  );
};

CardListItem.propTypes = {
  item: PropTypes.object,
  isSelected: PropTypes.bool,
  setSelected: PropTypes.func,
  showEditMenu: PropTypes.string.isRequired,
  handleShowEditMenu: PropTypes.func.isRequired,
  handleHideEditMenu: PropTypes.func.isRequired,
  showName: PropTypes.string.isRequired,
  showCopy: PropTypes.string.isRequired,
  handleShowName: PropTypes.func.isRequired,
  handleShowCopy: PropTypes.func.isRequired,
  handleHideCopy: PropTypes.func.isRequired,
  handleHideName: PropTypes.func.isRequired,
  // showMove: PropTypes.string.isRequired,
  // handleShowMove: PropTypes.func.isRequired,
  // handleHideMove: PropTypes.func.isRequired,
  // showDueDate: PropTypes.string.isRequired,
  // handleShowDueDate: PropTypes.func.isRequired,
  // handleHideDueDate: PropTypes.func.isRequired,
  showQuickComment: PropTypes.string.isRequired,
  handleShowQuickComment: PropTypes.func.isRequired,
  handleHideQuickComment: PropTypes.func.isRequired,
  showNote: PropTypes.string.isRequired,
  handleShowNote: PropTypes.func.isRequired,
  handleHideNote: PropTypes.func.isRequired,
  // showMember: PropTypes.string.isRequired,
  // handleShowMember: PropTypes.func.isRequired,
  // handleHideMember: PropTypes.func.isRequired,
  // showLabel: PropTypes.string.isRequired,
  // handleShowLabel: PropTypes.func.isRequired,
  // handleHideLabel: PropTypes.func.isRequired,
  featureType: PropTypes.oneOf(['board', 'myTask']),
  enableCreateList: PropTypes.bool,
};

CardListItem.defaultProps = {
  item: {},
  isSelected: false,
  setSelected: () => null,
  featureType: 'myTask',
  enableCreateList: true,
};

export default memo(CardListItem);
