import React, { useState, useContext, useEffect } from 'react';
import {
  useHistory, useParams, useLocation, Link,
} from 'react-router-dom';
import { isNil } from 'lodash';
import DayPicker from 'react-day-picker';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import Paper from '../../components/UI/Paper/Paper';
import styles from './SchedulePage.module.css';
import 'react-day-picker/lib/style.css';
import Title from '../../components/Title/Title';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import OverlayButton from '../../components/UI/Button/OverlayButton/OverlayButton';
import { ScheduleActions, SearchJumpToActions, TeamActions } from '../../actions';
import EventList from './EventList/EventList';
import { useScheduleHooks } from '../../hooks/ScheduleHooks';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { PageConstants } from '../../constants';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';

function SchedulePage() {
  const [{
    user, currentSchedule, currentTeam, currentCompany, currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [clickedDay, setClickedDay] = useState({ selectedDay: new Date() });
  // const [
  //   showModalRoleUser,
  //   handleShowModalRoleUser,
  //   handleHideModalRoleUser,
  // ] = useDelayShowHideHandler();
  const [filteredEvents, setFilteredEvents] = useState();
  const { enqueueSnackbar } = useSnackbar();

  // preparation for pagination schedule events
  const initialFromDate = new Date();
  // fix UTC
  initialFromDate.setDate(1);
  initialFromDate.setHours(0, 0, 0, 0);
  const initialUntilDate = new Date(initialFromDate);
  initialUntilDate.setUTCDate(initialUntilDate.getUTCDate() + 60);
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [untilDate, setUntilDate] = useState(initialUntilDate);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, scheduleId } = params;

  useScheduleHooks({
    scheduleId,
    userId: user?._id,
  }, dispatch);

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.schedule,
      featureId: scheduleId,
    },
  });

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (!fromDate || !untilDate) return;
    // socket.on('schedule', () => {
    //   let number = Math.random();
    //     //trigger useEffect to fetch new Data
    //     setNewSocketSchedule(number);
    // })
    const startLoadings = handleLoadings('schedulePage', [...loadings], 'start');
    setLoadings([...startLoadings]);
    const fetchApiScheduleDetail = async () => {
      try {
        const result = await ScheduleActions.initiateScheduleV2({
          scheduleId,
          companyId,
          fromDate,
          untilDate,
          teamId,
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('schedulePage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiScheduleDetail();
    // return () => socket.off('schedule')
  }, [location]);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (!filteredEvents) return;

    const startLoadings = handleLoadings('scheduleCalendar', [...loadings], 'start');
    setLoadings([...startLoadings]);
    const fetchApiScheduleDetail = async () => {
      try {
        const result = await ScheduleActions.initiateScheduleV2({
          scheduleId,
          companyId,
          fromDate,
          untilDate,
          teamId,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('scheduleCalendar', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiScheduleDetail();
  }, [fromDate, untilDate]);

  useEffect(() => {
    if (currentSchedule.events === undefined) return;

    const greaterThanTodayEvents = currentSchedule.events.filter((scheduleEvent) => {
      let eventDate = new Date(scheduleEvent.startDate);
      // resetting hour, so we can compare
      eventDate = eventDate.setHours(0, 0, 0, 0);
      const compareDate = clickedDay.selectedDay.setHours(0, 0, 0, 0);
      return eventDate >= compareDate;
    });

    setFilteredEvents(greaterThanTodayEvents);
  }, [currentSchedule]);

  const handleCreateSchedule = () => {
    history.push(`${location.pathname}/create`);
  };

  useEffect(() => {
    if (currentSchedule.events === undefined) return;

    if (clickedDay.selectedDay === undefined) {
      setFilteredEvents(currentSchedule.events);
      return;
    }

    const greaterThanTodayEvents = currentSchedule.events.filter((scheduleEvent) => {
      let eventDate = new Date(scheduleEvent.startDate);
      // resetting hour, so we can compare
      eventDate = eventDate.setHours(0, 0, 0, 0);
      const compareDate = clickedDay.selectedDay.setHours(0, 0, 0, 0);
      return eventDate >= compareDate;
    });

    setFilteredEvents(greaterThanTodayEvents);
  }, [clickedDay]);

  const handleDayClick = (day, { selected }) => {
    if (selected) {
      setClickedDay({ selectedDay: undefined });
      return;
    }

    setClickedDay({ selectedDay: day });
  };

  const renderDay = (day) => {
    const date = day.getDate();

    const dateStyle = {
      position: 'absolute',
      color: 'black',
      bottom: 0,
      right: 0,
      fontSize: 20,
    };
    const cellStyle = {
      height: 30,
      width: 30,
      position: 'relative',
      cursor: 'pointer',
    };
    const eventStyle = {
      width: 6,
      height: 6,
      backgroundColor: 'red',
      borderRadius: '100%',
      marginBottom: 2,
    };
    const dotEvent = {
      width: 13,
      height: 13,
      backgroundColor: 'red',
      borderRadius: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 2,
    };
    const dotCountEvent = {
      fontSize: 8,
      fontWeight: 500,
      color: 'white',
    };

    const eventOnTheCurrentDay = currentSchedule?.events?.filter((scheduleEvent) => {
      let eventDate = new Date(scheduleEvent.startDate);
      // resetting hour, so we can compare
      eventDate = eventDate.setHours(0, 0, 0, 0);
      const compareDate = day.setHours(0, 0, 0, 0);

      return eventDate === compareDate;
    });
    const isEventAboveLimit = eventOnTheCurrentDay?.length >= 4;

    return (
      <div style={cellStyle}>
        <div style={dateStyle}>{date}</div>
        {
          !isNil(eventOnTheCurrentDay) && isEventAboveLimit && (
            <div style={dotEvent}>
              <p style={dotCountEvent}>{eventOnTheCurrentDay?.length}</p>
            </div>
          )
        }
        {
          !isNil(eventOnTheCurrentDay) && !isEventAboveLimit && (
            <>
              {eventOnTheCurrentDay.map(() => <div style={eventStyle} />)}
            </>
          )
        }
      </div>
    );
  };

  const handleMonthChange = (month) => {
    // set month hours, minutes, ms to 0
    // fix UTC
    const startDate = new Date(month);
    startDate.setHours(startDate.getHours() - 7);
    setFromDate(startDate);

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 60);
    setUntilDate(endDate);

    setClickedDay({ selectedDay: startDate });
  };

  const pageType = PageConstants.pageType.schedules;
  const breadcrumb = generateRoutesAndTitle({ type: pageType, data: { companyId, currentTeam } });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: scheduleId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title="Jadwal" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="schedules"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton handleClick={handleCreateSchedule} text="Buat Jadwal" icon={<AddIcon />} />
            {/* {
            currentTeam?._id === teamId
            && (
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="My Role Here"
              icon={<VerifiedUserIcon />}
            />
          )
} */}
          </>
        )}
      />
      <LayoutMain>
        <PageLoading wait="schedulePage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.eventsSection}>
              <div className={styles.eventsSection__setPublic}>
                <Link to={`${location.pathname}/public-calendar`}>
                  {/* <p>Add this Schedule to your Google Calendar, or Apple Calendar...</p> */}
                  <p>Sinkronisasi kalender ini ke kalender Google/Apple-mu...</p>
                </Link>
              </div>
              <OverlayButton wait="scheduleCalendar" loadings={loadings}>
                <div className={styles.eventsSection__calendar}>
                  <DayPicker
                    numberOfMonths={2}
                    onMonthChange={handleMonthChange}
                    pagedNavigation
                    renderDay={renderDay}
                    onDayClick={handleDayClick}
                    selectedDays={clickedDay.selectedDay}
                  />
                </div>
              </OverlayButton>

              <div className={styles.eventsSection__items}>
                <EventList
                  currentCompany={currentCompany}
                  companyId={companyId}
                  teamId={teamId}
                  events={filteredEvents}
                  user={user}
                  currentRoleUser={currentRoleUser}
                />
              </div>
            </div>

          </SurfacePaperMain>
        </PageLoading>
        {/* <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureTitle={currentTeam?.name}
          featureType={typeRef.schedule}
          featureId={scheduleId}
          roleType={roleTypeRef.team}
        /> */}
      </LayoutMain>
    </>
  );
}

export default SchedulePage;
