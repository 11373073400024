import {
  cloneDeep, isEmpty, isEqual, isNil, slice,
} from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, BoardConstants, CardConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import {
  getIndexItemByIDFromList,
  getItemByIDFromList,
  getLastListDate,
  getLastListIndex,
  insertItemArrayImmutable,
  mergeObjectListAndRemoveDuplicate,
  removeDuplicateByID,
  removeItemArrayImmutable,
  updateListProperty,
  updateListSocket,
  updateMultipleListSocket,
} from '../utilities/arrayUtil';
import { BreadCrumbActions, CardActions, TeamActions } from '.';
import { ErrorException } from '../utilities/handleError';
import { boardCardListLimit } from '../constants/BoardConstants';
import { initialState } from '../contexts/GlobalStateProvider';

/*
  Dispatcher
*/

function dispatchCurrentBoard({ currentBoard }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BOARD,
    currentBoard,
  });
}

function dispatchCurrentBoardLabels({ currentBoardLabels }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BOARD_LABELS,
    currentBoardLabels,
  });
}

function dispatchCurrentBoardLists({ currentBoardLists }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BOARD_LISTS,
    currentBoardLists,
  });
}

function dispatchFilteredBoardLists({ filteredLists }, dispatch) {
  dispatch({
    type: actionTypes.SET_FILTERED_LISTS,
    filteredLists,
  });
}

function dispatchUpdateCurrentBoardAndList({ updateCurrentBoardAndList }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BOARD_AND_LIST,
    updateCurrentBoardAndList,
  });
}

function dispatchUpdateCurrentBoard({ updateCurrentBoard }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BOARD,
    updateCurrentBoard,
  });
}

function dispatchUpdateCurrentBoardLists({ updateCurrentBoardLists }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BOARD_LISTS,
    updateCurrentBoardLists,
  });
}

function dispatchUpdateCurrentBoardLabels({ updateCurrentBoardLabels }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_LABELS,
    updateCurrentBoardLabels,
  });
}

function dispatchUpdateFilteredBoardLists({ updateFilteredBoardLists }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_FILTER_BOARD_LISTS,
    updateFilteredBoardLists,
  });
}

function dispatchUpdatePreviousBoardList({ updatePreviousBoardList }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_PREVIOUS_BOARD_LIST,
    updatePreviousBoardList,
  });
}

function dispatchPreviousBoardCardListAll({ previousBoardCardListAll }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_BOARD_CARD_LIST_ALL,
    previousBoardCardListAll,
  });
}

function dispatchCurrentBoardCardListAll({ currentBoardCardListAll }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BOARD_CARD_LIST_ALL,
    currentBoardCardListAll,
  });
}

function dispatchUpdateCurrentBoardCardListAll(
  { updateCurrentBoardCardListAll }, dispatch,
) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BOARD_CARD_LIST_ALL,
    updateCurrentBoardCardListAll,
  });
}

function dispatchPreviousBoardCardCalendarAll({ previousBoardCardCalendarAll }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_BOARD_CARD_CALENDAR_ALL,
    previousBoardCardCalendarAll,
  });
}

function dispatchCurrentBoardCardCalendarAll({ currentBoardCardCalendarAll }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BOARD_CARD_CALENDAR_ALL,
    currentBoardCardCalendarAll,
  });
}

function dispatchUpdateCurrentBoardCardCalendarAll(
  { updateCurrentBoardCardCalendarAll }, dispatch,
) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BOARD_CARD_CALENDAR_ALL,
    updateCurrentBoardCardCalendarAll,
  });
}

/*
  SetterDispatcher
*/

function setCurrentBoard({ currentBoard }, dispatch) {
  if (!currentBoard) return;

  dispatchCurrentBoard(
    { currentBoard: cloneDeep(currentBoard) }, dispatch,
  );
}

function setCurrentBoardLabels({ currentBoardLabels }, dispatch) {
  if (!currentBoardLabels) return;

  dispatchCurrentBoardLabels(
    { currentBoardLabels: cloneDeep(currentBoardLabels) }, dispatch,
  );
}

function setCurrentBoardLists({ currentBoardLists }, dispatch) {
  if (!currentBoardLists) return;
  dispatchCurrentBoardLists(
    { currentBoardLists: cloneDeep(currentBoardLists) }, dispatch,
  );
}

function setFilteredBoardLists({ filteredLists }, dispatch) {
  if (!filteredLists) return;

  dispatchFilteredBoardLists(
    { filteredLists: cloneDeep(filteredLists) }, dispatch,
  );
}

function setPreviousBoardCardListAll({ previousBoardCardListAll }, dispatch) {
  if (!previousBoardCardListAll) return;

  dispatchPreviousBoardCardListAll(
    { previousBoardCardListAll: cloneDeep(previousBoardCardListAll) }, dispatch,
  );
}

function setCurrentBoardCardListAll({ currentBoardCardListAll }, dispatch) {
  if (!currentBoardCardListAll) return;
  dispatchCurrentBoardCardListAll(
    { currentBoardCardListAll: cloneDeep(currentBoardCardListAll) }, dispatch,
  );
}

function setPreviousBoardCardCalendarAll({ previousBoardCardCalendarAll }, dispatch) {
  if (!previousBoardCardCalendarAll) return;

  dispatchPreviousBoardCardCalendarAll(
    { previousBoardCardCalendarAll: cloneDeep(previousBoardCardCalendarAll) }, dispatch,
  );
}

function setCurrentBoardCardCalendarAll({ currentBoardCardCalendarAll }, dispatch) {
  if (!currentBoardCardCalendarAll) return;
  dispatchCurrentBoardCardCalendarAll(
    { currentBoardCardCalendarAll: cloneDeep(currentBoardCardCalendarAll) }, dispatch,
  );
}

/*
  Helpers
*/

const getNewCardList = (oldCards, newCards) => {
  const lengthOldCards = oldCards.length;
  const lengthNewCards = newCards.length;

  // so that the length is consistent
  if (lengthOldCards >= lengthNewCards) return newCards;

  // set length list back to nearest limit
  const currentPageList = Math.ceil(lengthOldCards / CardConstants.limitCard);
  const expectedTotalList = currentPageList * CardConstants.limitCard;
  const maxPageList = Math.ceil(lengthNewCards / CardConstants.limitCard);
  if (maxPageList === currentPageList) {
    return newCards;
  }
  if (currentPageList === 0) {
    return slice(newCards, 0, CardConstants.limitCard);
  }

  return slice(newCards, 0, expectedTotalList);
};

const sortingNewListBoard = (oldList, newList) => {
  let listsBoard = new Array(newList.length);

  newList.forEach((newItem, indexNewItem) => {
    let oldItem = oldList[indexNewItem];
    let oldItemWithOutCards = { ...oldItem, cards: [] };
    const newItemWithOutCards = { ...newItem, cards: [] };

    // if id not the same, it's mean list has been reorder
    if (oldItem?._id !== newItem?._id) {
      const indexItemFromOldList = getIndexItemByIDFromList(oldList, newItem);
      if (indexItemFromOldList >= 0) {
        oldItem = oldList[indexItemFromOldList];
        oldItemWithOutCards = { ...oldItem, cards: [] };
      // item has not been shown yet
      } else {
        return;
      }
    }
    if (isEqual(oldItemWithOutCards, newItemWithOutCards)) {
      oldItem = {
        ...oldItemWithOutCards,
        cards: removeDuplicateByID(getNewCardList(oldItem.cards, newItem.cards)),
      };
    } else {
      oldItem = {
        ...newItemWithOutCards,
        cards: removeDuplicateByID(getNewCardList(oldItem.cards, newItem.cards)),
      };
    }
    listsBoard = insertItemArrayImmutable(listsBoard, indexNewItem, oldItem);
  });

  // trim undefined item list
  const sortedOldList = listsBoard.filter((item) => !isNil(item));
  return sortedOldList;
};

const updatePreviousBoardListItem = (newBoardList, previousBoardList) => {
  const sliceFirstTenCards = (inComingCards) => {
    const cards = inComingCards;
    return slice(cards, 0, CardConstants.limitCard);
  };

  const sliceLastCards = (inComingCards) => {
    const cards = inComingCards;
    const lengthCards = cards?.length;
    const currentPageList = Math.ceil(lengthCards / CardConstants.limitCard);
    const previousPageList = Math.floor(lengthCards / CardConstants.limitCard);
    const diffCards = lengthCards % CardConstants.limitCard;
    if (diffCards === 0) {
      const firstIndexToCut = (currentPageList * CardConstants.limitCard) - CardConstants.limitCard;
      return slice(cards, firstIndexToCut, lengthCards);
    }
    return slice(cards, (previousPageList * CardConstants.limitCard), lengthCards);
  };

  const spliceObjectList = (list, options) => ({
    ...list,
    cards: options?.new
      ? sliceFirstTenCards(list.cards)
      : sliceLastCards(list.cards),
  });

  const getOldItem = (oldList, newItem, indexNewItem) => {
    const itemOldList = oldList[indexNewItem];

    if (newItem?._id === itemOldList?._id) {
      return itemOldList;
    }
    const indexItemFromList = getIndexItemByIDFromList(oldList, newItem);
    if (indexItemFromList >= 0) {
      return oldList[indexItemFromList];
    }
    return {};
  };

  return newBoardList.map((newItem, indexNewItem) => {
    const oldItem = getOldItem(previousBoardList, newItem, indexNewItem);

    if (isEmpty(oldItem)) return spliceObjectList(newItem, { new: true });

    const newCards = newItem.cards;
    const oldCards = oldItem.cards;
    const lengthOldCards = oldCards.length;
    const lengthNewCards = newCards.length;

    if (lengthOldCards === lengthNewCards) {
      return oldItem;
    }

    return spliceObjectList(newItem, { new: false });
  });
};

function updateBoardList(previousList, newList, replaceCard) {
  return {
    ...newList,
    cards: replaceCard
      ? removeDuplicateByID(getNewCardList(previousList.cards, newList.cards))
      : previousList.cards,
  };
}

function updatePreviousBoardListItemV2({
  previousBoardList, newList, typeAction,
}) {
  let listData = previousBoardList;
  const indexNewList = getIndexItemByIDFromList(listData, newList);

  switch (typeAction) {
    case 'new':
      listData = insertItemArrayImmutable(
        listData,
        previousBoardList?.length,
        newList,
      );
      break;
    case 'delete':
      if (indexNewList >= 0) {
        listData = removeItemArrayImmutable(
          listData,
          indexNewList,
        );
      }
      break;
    default:
      break;
  }

  return listData;
}

function updateMultiplePreviousBoardListItemV2({
  previousBoardList, newLists, typeAction,
}) {
  let listData = previousBoardList;

  newLists.forEach((listItem) => {
    const indexNewList = getIndexItemByIDFromList(listData, listItem);

    switch (typeAction) {
      case 'new':
        listData = insertItemArrayImmutable(
          listData,
          previousBoardList?.length,
          listItem,
        );
        break;
      case 'delete':
        if (indexNewList >= 0) {
          listData = removeItemArrayImmutable(
            listData,
            indexNewList,
          );
        }
        break;
      default:
        break;
    }
  });

  return listData;
}

/*
  Method
*/

function incomingBoardUpdate({ currentBoard }, dispatch) {
  if (!currentBoard) return;

  const updateCurrentBoardAndList = (currentBoardLists) => {
    const updatedBoardList = sortingNewListBoard(currentBoardLists, currentBoard.lists);
    const newBoard = {
      ...currentBoard,
      lists: updatedBoardList,
    };

    return cloneDeep(newBoard);
  };

  const updatePreviousBoardList = (currentBoardLists, previousBoardList) => {
    const updatedBoardList = sortingNewListBoard(currentBoardLists, currentBoard.lists);
    const updatedPreviousBoardList = updatePreviousBoardListItem(
      updatedBoardList,
      previousBoardList,
    );

    return cloneDeep(updatedPreviousBoardList);
  };

  dispatchUpdateCurrentBoardAndList({ updateCurrentBoardAndList }, dispatch);
  dispatchUpdatePreviousBoardList({ updatePreviousBoardList }, dispatch);
}

function incomingBoardUpdateV2({ board }, dispatch) {
  if (!board) return;

  const updateCurrentBoard = (currentBoard) => {
    const newBoard = {
      ...board,
      lists: currentBoard?.lists,
    };

    return cloneDeep(newBoard);
  };

  dispatchUpdateCurrentBoard({ updateCurrentBoard }, dispatch);
}

const reorderCardPositionWithUncompleteBoardLists = ({
  destination, draggableId, source, currentBoardLists, card,
}) => {
  if (!destination || !draggableId || !source || !currentBoardLists || !card) {
    return currentBoardLists || [];
  }
  const newLists = cloneDeep(currentBoardLists);
  const indexListSource = getIndexItemByIDFromList(newLists, { _id: source.droppableId });
  const listSource = newLists[indexListSource];
  let indexCard;
  if (listSource) {
    indexCard = getIndexItemByIDFromList(listSource?.cards, { _id: draggableId });
  }
  // check apakah ini mindahin cardnya beda list atau tidak
  // jika beda list...
  if (destination.droppableId !== source.droppableId) {
    const indexListDest = getIndexItemByIDFromList(newLists, { _id: destination.droppableId });
    const listDest = newLists[indexListDest];
    if (indexListSource >= 0 && listSource && indexCard >= 0) {
      const removedCardsFromListSource = updateListSocket({
        newData: card,
        currentList: listSource?.cards,
        typeAction: BoardConstants.typeCallback.DELETE,
      });
      const newListSource = {
        ...listSource,
        cards: removedCardsFromListSource,
      };
      newLists[indexListSource] = newListSource;
    }

    if (indexListDest >= 0 && listDest) {
      const addedCardsToListDest = updateListSocket({
        newData: card,
        currentList: listDest?.cards,
        typeAction: BoardConstants.typeCallback.NEW,
        destIndex: destination.index,
      });
      const newListDest = {
        ...listDest,
        cards: addedCardsToListDest,
      };
      newLists[indexListDest] = newListDest;
    }
  } else { // jika di list yang sama
    let newListSourceRemovedCard = {};
    if (indexListSource >= 0 && listSource) newListSourceRemovedCard = { ...listSource };
    if (indexListSource >= 0 && listSource && indexCard >= 0) {
      const removedCardsFromListSource = updateListSocket({
        newData: card,
        currentList: listSource?.cards,
        typeAction: BoardConstants.typeCallback.DELETE,
      });
      newListSourceRemovedCard = {
        ...listSource,
        cards: removedCardsFromListSource,
      };
      newLists[indexListSource] = newListSourceRemovedCard;
    }

    if (indexListSource >= 0 && listSource) {
      const addedCardsToListSource = updateListSocket({
        newData: card,
        currentList: newListSourceRemovedCard?.cards,
        typeAction: BoardConstants.typeCallback.NEW,
        destIndex: destination.index,
      });
      const newListSourceAddedCard = {
        ...listSource,
        cards: addedCardsToListSource,
      };
      newLists[indexListSource] = newListSourceAddedCard;
    }
  }
  return cloneDeep(newLists);
};

const reorderCardPosition = ({
  destination, draggableId, source, currentBoardLists,
}) => {
  if (!destination || !draggableId || !source || !currentBoardLists) {
    return currentBoardLists || [];
  }
  const newLists = cloneDeep(currentBoardLists);
  const indexListSource = getIndexItemByIDFromList(newLists, { _id: source.droppableId });
  const listSource = newLists[indexListSource];

  if (indexListSource < 0) {
    return currentBoardLists;
  }
  const indexCard = getIndexItemByIDFromList(listSource?.cards, { _id: draggableId });
  if (indexCard < 0) {
    return currentBoardLists;
  }
  const card = listSource?.cards[indexCard];
  // check apakah ini mindahin cardnya beda list atau tidak
  // jika beda list...
  if (destination.droppableId !== source.droppableId) {
    const indexListDest = getIndexItemByIDFromList(newLists, { _id: destination.droppableId });
    if (indexListDest < 0) {
      return currentBoardLists;
    }
    const listDest = newLists[indexListDest];
    const removedCardsFromListSource = updateListSocket({
      newData: card,
      currentList: listSource?.cards,
      typeAction: BoardConstants.typeCallback.DELETE,
    });
    const newListSource = {
      ...listSource,
      cards: removedCardsFromListSource,
    };
    newLists[indexListSource] = newListSource;

    const addedCardsToListDest = updateListSocket({
      newData: card,
      currentList: listDest?.cards,
      typeAction: BoardConstants.typeCallback.NEW,
      destIndex: destination.index,
    });
    const newListDest = {
      ...listDest,
      cards: addedCardsToListDest,
    };
    newLists[indexListDest] = newListDest;
  } else { // jika di list yang sama
    const removedCardsFromListSource = updateListSocket({
      newData: card,
      currentList: listSource?.cards,
      typeAction: BoardConstants.typeCallback.DELETE,
    });
    const newListSourceRemovedCard = {
      ...listSource,
      cards: removedCardsFromListSource,
    };
    newLists[indexListSource] = newListSourceRemovedCard;
    const addedCardsToListSource = updateListSocket({
      newData: card,
      currentList: newListSourceRemovedCard?.cards,
      typeAction: BoardConstants.typeCallback.NEW,
      destIndex: destination.index,
    });
    const newListSourceAddedCard = {
      ...listSource,
      cards: addedCardsToListSource,
    };
    newLists[indexListSource] = newListSourceAddedCard;
  }
  return cloneDeep(newLists);
};

const reorderListPosition = ({
  destination, draggableId, source, currentBoardLists,
}) => {
  if (!destination || !draggableId || !source || !currentBoardLists) {
    return currentBoardLists || [];
  }
  let newLists = currentBoardLists;
  const indexList = getIndexItemByIDFromList(newLists, { _id: draggableId });
  const list = newLists[indexList];
  if (indexList < 0) {
    return currentBoardLists;
  }

  const removedListFromCurrentBoardLists = updateListSocket({
    newData: list,
    currentList: newLists,
    typeAction: BoardConstants.typeCallback.DELETE,
  });

  newLists = [...removedListFromCurrentBoardLists];

  const addedListToCurrentBoardLists = updateListSocket({
    newData: list,
    currentList: newLists,
    typeAction: BoardConstants.typeCallback.NEW,
    destIndex: destination.index,
  });

  newLists = [...addedListToCurrentBoardLists];

  return cloneDeep(newLists);
};

function incomingItemMove({ data, selector, type }, dispatch) {
  if (!data || !selector || !type) return;
  const { boardId } = selector;
  const { destination, draggableId, source } = data;
  if (!boardId || !destination || !draggableId || !source) return;

  const updateCurrentBoardLists = (currentBoardLists) => {
    let newLists;
    if (type === 'list') {
      newLists = reorderListPosition({
        currentBoardLists, destination, draggableId, source,
      });
    } else if (type === 'card') {
      newLists = reorderCardPosition({
        currentBoardLists, destination, draggableId, source,
      });
    } else {
      return currentBoardLists;
    }
    return cloneDeep(newLists);
  };

  dispatchUpdateCurrentBoardLists({ updateCurrentBoardLists }, dispatch);
}

function incomingListUpdate({
  currentList, lists, replaceCard, typeAction, withActionEdit,
}, dispatch) {
  if (!currentList && !lists) return;
  if (lists && lists?.length < 1) return;

  let updateCurrentBoardLists = (currentBoardLists) => currentBoardLists;
  let updatePreviousBoardList = (currentBoardLists, previousBoardList) => previousBoardList;
  if (lists) {
    updateCurrentBoardLists = (currentBoardLists) => {
      const updatedCurrentBoardLists = updateMultipleListSocket({
        newLists: lists,
        currentList: currentBoardLists,
        typeAction,
        actionEdit: withActionEdit ? (previousList, newList) => updateBoardList(
          previousList,
          newList,
          replaceCard,
        ) : null,
        reverse: false,
      });
      return cloneDeep(updatedCurrentBoardLists);
    };

    updatePreviousBoardList = (currentBoardLists, previousBoardList) => {
      const updatedPreviousBoardList = updateMultiplePreviousBoardListItemV2({
        previousBoardList,
        newLists: lists,
        typeAction,
      });

      return cloneDeep(updatedPreviousBoardList);
    };
  }
  if (currentList) {
    updateCurrentBoardLists = (currentBoardLists) => updateListSocket({
      newData: currentList,
      currentList: currentBoardLists,
      typeAction,
      actionEdit: (previousList, newList) => updateBoardList(
        previousList,
        newList,
        replaceCard,
      ),
      reverse: false,
    });

    updatePreviousBoardList = (currentBoardLists, previousBoardList) => {
      const updatedPreviousBoardList = updatePreviousBoardListItemV2({
        previousBoardList,
        newList: currentList,
        typeAction,
      });

      return cloneDeep(updatedPreviousBoardList);
    };
  }

  dispatchUpdateCurrentBoardLists({ updateCurrentBoardLists }, dispatch);
  dispatchUpdatePreviousBoardList({ updatePreviousBoardList }, dispatch);
}

function incomingLabelsUpdate({ currentLabel, typeAction }, dispatch) {
  if (!currentLabel) return;

  const updateCurrentBoardLabels = (currentBoardLabels) => updateListSocket({
    newData: currentLabel,
    currentList: currentBoardLabels,
    typeAction,
    reverse: false,
  });

  dispatchUpdateCurrentBoardLabels({ updateCurrentBoardLabels }, dispatch);
}

function incomingBoardCardsUpdate({
  card, listId, typeAction, destIndex = 0,
}, dispatch) {
  if (!card) return;
  const updateCurrentBoardLists = (currentBoardLists) => {
    const listData = currentBoardLists;
    const indexNewData = getIndexItemByIDFromList(currentBoardLists, { _id: listId });
    if (indexNewData < 0) {
      return currentBoardLists;
    }

    const listByIndex = currentBoardLists[indexNewData];
    const newCards = updateListSocket({
      newData: card,
      currentList: listByIndex?.cards,
      typeAction,
      reverse: true,
      destIndex,
    });
    const newList = {
      ...listByIndex,
      cards: newCards,
    };

    listData[indexNewData] = newList;

    return cloneDeep(listData);
  };

  dispatchUpdateCurrentBoardLists({ updateCurrentBoardLists }, dispatch);
}

const resetBoardAndLists = ({}, dispatch) => {
  setCurrentBoard({ currentBoard: {} }, dispatch);
  setCurrentBoardLabels({ currentBoardLabels: [] }, dispatch);
  setCurrentBoardLists({ currentBoardLists: [] }, dispatch);
  setFilteredBoardLists({ filteredLists: [] }, dispatch);
  CardActions.setCurrentBoardList({ currentBoardLists: [] }, dispatch);
  CardActions.setPreviousBoardList({ previousBoardList: [] }, dispatch);
};

const getWhichListsNeedToFetchCards = ({ currentBoardLists }) => {
  if (!currentBoardLists) return [];
  const result = currentBoardLists.filter(
    (list) => list?.cards?.length === BoardConstants.limitCard
      && list?.cards?.length !== list.totalCard,
  );
  return cloneDeep(result);
};

async function initiateBoardV2({
  boardId, teamId, companyId, currentBoardLists, currentBoard, shouldFetchLists,
}, dispatch) {
  try {
    // no need to get lists if same lists and same item
    if (boardId === currentBoard?._id
        && currentBoard?.totalList === currentBoardLists?.length
    ) {
      if (shouldFetchLists) shouldFetchLists(Math.random());
      return {
        lists: [],
      };
    }
    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD({ boardId }), {
      params: {
        limitList: BoardConstants.limitList,
        limitCard: BoardConstants.limitCard,
        teamId,
        companyId,
      },
    });

    setCurrentBoard({ currentBoard: result?.data?.board }, dispatch);
    setCurrentBoardLabels({ currentBoardLabels: result?.data?.board?.labels }, dispatch);
    CardActions.setCurrentBoardList({ currentBoardLists: result?.data?.board?.lists }, dispatch);
    CardActions.setPreviousBoardList({ previousBoardList: result?.data?.board?.lists }, dispatch);
    // const teamMembers = await TeamActions.getCurrentTeamMembers({ teamId, companyId });
    // TeamActions.saveCurrentTeam({
    //   currentTeam: result?.data?.currentTeam,
    //   members: teamMembers,
    // }, dispatch);
    // await TeamActions.initiateCurrentTeam({ teamId, companyId }, dispatch);
    if (shouldFetchLists) shouldFetchLists(Math.random());
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// deprecated
async function loadMoreBoardLists({
  currentBoard, teamId, companyId, currentBoardLists,
}, dispatch) {
  try {
    const boardId = currentBoard?._id;
    // no need to get lists if same lists and same item
    if (currentBoard?.totalList === currentBoardLists?.length) {
      return {
        lists: [],
      };
    }
    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD_LIST({ boardId }), {
      params: {
        limitList: BoardConstants.limitList,
        limitCard: BoardConstants.limitCard,
        lastIndex: getLastListIndex(currentBoardLists),
        teamId,
        companyId,
      },
    });

    const newBoardList = mergeObjectListAndRemoveDuplicate({
      currentObjectList: { lists: currentBoardLists },
      nextObjectList: result?.data,
      keyObject: 'lists',
    });

    CardActions.setCurrentBoardList({ currentBoardLists: newBoardList?.lists }, dispatch);
    CardActions.setPreviousBoardList({ previousBoardList: newBoardList?.lists }, dispatch);

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreBoardListsV2({
  currentBoard,
  teamId,
  companyId,
  localLists,
  handleUpdateLocalLists,
  triggerFetchCards,
  isBoardListsLengthAtTheLimitOrAbove,
  onShouldFetch,
}, dispatch) {
  try {
    const boardId = currentBoard?._id;
    // no need to get lists if same lists and same item
    if (currentBoard?.totalList === localLists?.length) {
      triggerFetchCards(localLists);
      onShouldFetch();
      return [];
    }

    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD_LIST({ boardId }), {
      params: {
        limitList: BoardConstants.limitList,
        limitCard: BoardConstants.limitCard,
        lastIndex: getLastListIndex(localLists),
        teamId,
        companyId,
      },
    });

    const newBoardList = mergeObjectListAndRemoveDuplicate({
      currentObjectList: { lists: localLists },
      nextObjectList: result?.data,
      keyObject: 'lists',
    });

    if (isBoardListsLengthAtTheLimitOrAbove(result?.data?.lists)) {
      handleUpdateLocalLists(newBoardList?.lists);
      CardActions.setCurrentBoardList({ currentBoardLists: newBoardList?.lists }, dispatch);
      CardActions.setPreviousBoardList({ previousBoardList: newBoardList?.lists }, dispatch);
      onShouldFetch(Math.random());
    } else {
      handleUpdateLocalLists(newBoardList?.lists);
      CardActions.setCurrentBoardList({ currentBoardLists: newBoardList?.lists }, dispatch);
      CardActions.setPreviousBoardList({ previousBoardList: newBoardList?.lists }, dispatch);
      triggerFetchCards(newBoardList?.lists);
      onShouldFetch();
    }
    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getListArchivedCards({
  limit = BoardConstants.limitCard,
  page = 1,
  companyId,
  teamId,
  filters,
  boardId,
}) {
  try {
    let params = {
      companyId,
      teamId,
      limit,
      page,
      'filter[archived]': true,
    };

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD_CARDS({ boardId }), {
      params,
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getListArchivedLists({
  limit = BoardConstants.limitList,
  page = 1,
  companyId,
  teamId,
  filters,
  boardId,
}) {
  try {
    let params = {
      companyId,
      teamId,
      limit,
      page,
      'filter[archived]': true,
    };

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD_LISTS_ONLY({ boardId }), {
      params,
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const initiateBoardCardListAll = async ({
  companyId,
  teamId,
  boardId,
  filters,
  limit = boardCardListLimit,
}, dispatch) => {
  try {
    let params = {
      companyId,
      teamId,
      limit,
    };

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }

      if (filters.filterLabels) {
        params = {
          ...params,
          'filter[labels]': filters.filterLabels,
        };
      }

      if (filters.filterSubscribers) {
        params = {
          ...params,
          'filter[members]': filters.filterSubscribers,
        };
      }

      if (filters.filterDueDate) {
        params = {
          ...params,
          'filter[dueDate]': filters.filterDueDate,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD_CARD_LIST_ALL({ boardId }), {
      params,
    },
    {});

    setCurrentBoardCardListAll({ currentBoardCardListAll: result?.data }, dispatch);
    setPreviousBoardCardListAll({
      previousBoardCardListAll:
      initialState.previousBoardCardListAll,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreBoardCardListAll = async ({
  companyId,
  teamId,
  boardId,
  filters,
  currentBoardCardListAll,
  limit = boardCardListLimit,
}, dispatch) => {
  try {
    const dueDateAt = getLastListDate(currentBoardCardListAll.data, 'dueDate');

    let params = {
      companyId,
      teamId,
      limit,
    };

    if (dueDateAt && filters?.filterDueDate !== 'completed') {
      params = {
        ...params,
        dueDateAt,
      };
    } else if (filters?.filterDueDate === 'completed') {
      const completedAt = getLastListDate(currentBoardCardListAll.data, 'setAt', 'complete');
      params = {
        ...params,
        completedAt,
      };
    } else {
      const createdAt = getLastListDate(currentBoardCardListAll.data, 'createdAt');
      if (createdAt) {
        params = {
          ...params,
          createdAt,
        };
      }
    }

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }

      if (filters.filterLabels) {
        params = {
          ...params,
          'filter[labels]': filters.filterLabels,
        };
      }

      if (filters.filterSubscribers) {
        params = {
          ...params,
          'filter[members]': filters.filterSubscribers,
        };
      }

      if (filters.filterDueDate) {
        params = {
          ...params,
          'filter[dueDate]': filters.filterDueDate,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD_CARD_LIST_ALL({ boardId }), {
      params,
    },
    {});

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentBoardCardListAll,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousBoardCardListAll({
      previousBoardCardListAll:
      result?.data,
    }, dispatch);
    setCurrentBoardCardListAll({
      currentBoardCardListAll:
      mergedListData,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateBoardCardCalendarAll = async ({
  companyId,
  filters,
  fromDate,
  untilDate,
  teamId,
  boardId,
}, dispatch) => {
  try {
    let params = {
      companyId,
      teamId,
      fromDate,
      untilDate,
    };

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }

      if (filters.filterLabels) {
        params = {
          ...params,
          'filter[labels]': filters.filterLabels,
        };
      }

      if (filters.filterSubscribers) {
        params = {
          ...params,
          'filter[members]': filters.filterSubscribers,
        };
      }

      if (filters.filterDueDate) {
        params = {
          ...params,
          'filter[dueDate]': filters.filterDueDate,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD_CARD_CALENDAR_ALL({ boardId }), {
      params,
    },
    {});

    setCurrentBoardCardCalendarAll({ currentBoardCardCalendarAll: result?.data }, dispatch);
    setPreviousBoardCardCalendarAll({
      previousBoardCardCalendarAll:
      initialState.previousBoardCardCalendarAll,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreBoardCardCalendarAll = async ({
  companyId,
  filters,
  currentBoardCardCalendarAll,
  fromDate,
  untilDate,
  teamId,
  boardId,
}, dispatch) => {
  try {
    const dueDateAt = getLastListDate(currentBoardCardCalendarAll.data, 'dueDate');

    let params = {
      companyId,
      teamId,
      fromDate,
      untilDate,
    };

    if (dueDateAt && filters?.filterDueDate !== 'completed') {
      params = {
        ...params,
        dueDateAt,
      };
    } else if (filters?.filterDueDate === 'completed') {
      const completedAt = getLastListDate(currentBoardCardCalendarAll.data, 'setAt', 'complete');
      params = {
        ...params,
        completedAt,
      };
    } else {
      const createdAt = getLastListDate(currentBoardCardCalendarAll.data, 'createdAt');
      if (createdAt) {
        params = {
          ...params,
          createdAt,
        };
      }
    }

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }

      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterLabels) {
        params = {
          ...params,
          'filter[labels]': filters.filterLabels,
        };
      }

      if (filters.filterMembers) {
        params = {
          ...params,
          'filter[members]': filters.filterMembers,
        };
      }

      if (filters.filterDueDate) {
        params = {
          ...params,
          'filter[dueDate]': filters.filterDueDate,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V2.BOARD_CARD_CALENDAR_ALL({ boardId }), {
      params,
    },
    {});

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentBoardCardCalendarAll,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousBoardCardCalendarAll({
      previousBoardCardCalendarAll:
      result?.data,
    }, dispatch);
    setCurrentBoardCardCalendarAll({
      currentBoardCardCalendarAll:
      mergedListData,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const incomingCardUpdateOnBoardCardListAll = ({
  currentCard,
  typeAction = BoardConstants.typeCallback.EDIT,
}, dispatch) => {
  if (!currentCard) return;
  const updateCurrentBoardCardListAll = (currentBoardCardListAll) => {
    const newCurrentBoardCardListAll = updateListProperty({
      keyProperty: 'data',
      newData: currentCard,
      currentList: currentBoardCardListAll,
      typeAction,
      reverse: true,
    });

    return cloneDeep(newCurrentBoardCardListAll);
  };

  dispatchUpdateCurrentBoardCardListAll({ updateCurrentBoardCardListAll }, dispatch);
};

const incomingCardUpdateOnBoardCardCalendarAll = ({
  currentCard,
  typeAction = BoardConstants.typeCallback.EDIT,
}, dispatch) => {
  if (!currentCard) return;
  const updateCurrentBoardCardCalendarAll = (currentBoardCardCalendarAll) => {
    const newCurrentBoardCardCalendarAll = updateListProperty({
      keyProperty: 'data',
      newData: currentCard,
      currentList: currentBoardCardCalendarAll,
      typeAction,
      reverse: true,
    });

    return cloneDeep(newCurrentBoardCardCalendarAll);
  };

  dispatchUpdateCurrentBoardCardCalendarAll({ updateCurrentBoardCardCalendarAll }, dispatch);
};

export {
  setCurrentBoard,
  setCurrentBoardLists,
  setFilteredBoardLists,
  initiateBoardV2,
  incomingBoardUpdate,
  loadMoreBoardLists,
  loadMoreBoardListsV2,
  incomingBoardUpdateV2,
  incomingListUpdate,
  incomingLabelsUpdate,
  incomingBoardCardsUpdate,
  incomingItemMove,
  dispatchUpdateFilteredBoardLists,
  dispatchUpdateCurrentBoardLists,
  dispatchUpdatePreviousBoardList,
  reorderCardPositionWithUncompleteBoardLists,
  reorderCardPosition,
  reorderListPosition,
  resetBoardAndLists,
  getWhichListsNeedToFetchCards,
  getListArchivedCards,
  getListArchivedLists,
  initiateBoardCardListAll,
  loadMoreBoardCardListAll,
  incomingCardUpdateOnBoardCardListAll,
  setCurrentBoardCardListAll,
  setPreviousBoardCardListAll,
  incomingCardUpdateOnBoardCardCalendarAll,
  setCurrentBoardCardCalendarAll,
  setPreviousBoardCardCalendarAll,
  initiateBoardCardCalendarAll,
  loadMoreBoardCardCalendarAll,
  setCurrentBoardLabels,
};
