import React, {
  memo, useState, useEffect, useCallback, useContext,
} from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'react-resize-detector';
import { useMediaQuery } from '@mui/material';
import { AudioRecorder } from 'react-audio-voice-recorder';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import { GlobalContext, initialState } from '../../../../contexts/GlobalStateProvider';
import styles from './CreateMessage.module.css';
import AttachFileContainer from '../../../AttachFileContainer/AttachFileContainer';
import EditorMin from '../../../FroalaEditor/EditorMin/EditorMin';
import extractElementBySelectorFromString from '../../../../utilities/extractElementBySelectorFromString';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { ApiConstants } from '../../../../constants';
import apiUtil from '../../../../utilities/apiUtil';
import { ChatActions } from '../../../../actions';
import { useIsTyping, useUpdateUserTypingStatus } from '../../../../hooks/UserTypingHooks';
import { elementId } from '../../../../constants/ElementIdConstants';
import AudioPlayerOnCreateMessage from './AudioPlayerOnCreateMessage/AudioPlayerOnCreateMessage';
import { useDelayShowHideHandler } from '../../../../hooks/HelperHooks';

const editorTextStyles = {
  chat: {},
  groupChat: {
    width: '68vw',
  },
  mobile: {
    width: '62vw',
  },
};

function CreateMessage({
  handleChangeHeightDivMessagesSection, handleOnCloseReplyMessageSection, handleLoading, chatWith,
  type, socket, handleUploadFiles, triggerFocusEditor, handleModifyOptionalParamsOnUploadAttachment,
  repliedMessage, repliedGroupMessage, handlePostAttachmentVoiceNote,
}) {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const [postMessage, setPostMessage] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [editorModel, setEditorModel] = useState();
  const [trigger, setTrigger] = useState();
  const [isAction, setIsAction] = useState();
  const [isRecording, handleSetIsRecording, handleUnsetIsRecording] = useDelayShowHideHandler();
  const params = useParams();

  const {
    companyId, teamId, chatId, groupChatId,
  } = params;

  const [isTyping, handleTyping] = useIsTyping({});
  useUpdateUserTypingStatus({ isTyping, socket, userId: user?._id });

  const handleCancel = () => {
    // setEditorModel('');
    setIsAction(Math.random());
  };

  const isChat = type === 'chat';

  useEffect(() => {
    if (postMessage === undefined) {
      return;
    }

    const postApiMessage = async () => {
      try {
        const startLoadings = handleLoadings(`create-${type}-message`, [...loadings], 'start');
        setLoadings([...startLoadings]);
        switch (type) {
          case 'groupChat':
            await apiUtil.post(
              ApiConstants.URL_V1.GROUP_CHAT_MESSAGE({ groupChatId }),
              postMessage,
              {
                params: {
                  companyId,
                  teamId,
                },
              },
            );
            break;
          case 'replyGroupChat':
            await apiUtil.post(
              ApiConstants.URL_V1.GROUP_CHAT_MESSAGE({ groupChatId }),
              postMessage,
              {
                params: {
                  companyId,
                  teamId,
                },
              },
            );
            handleOnCloseReplyMessageSection();
            break;
          case 'chat':
            await apiUtil.post(
              ApiConstants.URL_V1.CHAT_MESSAGE({ chatId }),
              postMessage,
              {
                params: {
                  companyId,
                },
              },
            );
            ChatActions.setCurrentChatUnreadMessagesCounter(0, dispatch);
            handleOnCloseReplyMessageSection();
            break;
          case 'replyChat':
            await apiUtil.post(
              ApiConstants.URL_V1.CHAT_MESSAGE({ chatId }),
              postMessage,
              { params: { companyId } },
            );
            ChatActions.setCurrentChatUnreadMessagesCounter(0, dispatch);
            handleOnCloseReplyMessageSection();
            break;
          default:
            await apiUtil.post(
              ApiConstants.URL_V1.CHAT_MESSAGE({ chatId }),
              postMessage,
              {
                params: {
                  companyId,
                },
              },
            );
            ChatActions.setCurrentChatUnreadMessagesCounter(0, dispatch);
            break;
        }
        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings(`create-${type}-message`, [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    postApiMessage();
  }, [postMessage]);

  useEffect(() => {
    if (trigger === undefined) return;
    const checkWait = loadings.filter((loading) => loading === `create-${type}-message`);
    if (checkWait.length > 0) return;

    if (!editorModel) {
      handleCancel();
      return;
    }

    const mentionedUsers = extractElementBySelectorFromString(editorModel, '#mentioned-user');
    const newMessage = { content: editorModel, mentionedUsers };
    if (type === 'replyChat') {
      newMessage.replyTo = repliedMessage?._id;
      newMessage.replyToType = repliedMessage?.type;
    } else if (type === 'replyGroupChat') {
      newMessage.replyTo = repliedGroupMessage?._id;
      newMessage.replyToType = repliedGroupMessage?.type;
    }

    setPostMessage(newMessage);
  }, [trigger]);

  const handleTriggerPostMessage = () => {
    setTrigger(Math.random());
  };

  const handleEditorModelChange = (model) => {
    setEditorModel(model);
  };

  const defaultHeightEditorMin = 9;
  const onResize = (width, height) => {
    handleChangeHeightDivMessagesSection(height + defaultHeightEditorMin);
  };

  const { width, height, ref } = useResizeDetector({
    handleWidth: false,
    onResize,
  });

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const mobileEditorTextStyles = isMobile && editorTextStyles.mobile;

  const isEditorModelEmpty = editorModel === undefined
  || editorModel === null || editorModel?.length < 1;

  const [audioSrc, setAudioSrc] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [showAudio, setShowAudio] = useState(false);

  const onRecordingComplete = (blob) => {
    const url = URL.createObjectURL(blob);
    setAudioSrc(url);
    setAudioBlob(blob);
    setShowAudio(true);
    handleUnsetIsRecording();
  };

  const isVoiceNoteCreated = showAudio && audioSrc && isEditorModelEmpty;

  const handleClearAudio = () => {
    setAudioBlob(null);
    setAudioSrc(null);
    setShowAudio(false);
  };

  const handleSendVoiceNoteApi = async (currentAudioBlob) => {
    try {
      const startLoadings = handleLoadings(`create-${type}-message`, [...loadings], 'start');
      setLoadings([...startLoadings]);

      await handlePostAttachmentVoiceNote(currentAudioBlob);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings(`create-${type}-message`, [...loadings], 'end');
      setLoadings([...endLoadings]);
      handleClearAudio();
    }
  };

  const handleStop = () => {
    handleUnsetIsRecording();
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.closest('[data-testid="ar_cancel"]')) {
        handleStop();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick); // Cleanup
    };
  }, []);

  return (
    <div className={styles.container} ref={ref}>
      {(!showAudio && !isRecording) && (
      <AttachFileContainer
        handleUploadForm={handleUploadFiles}
        handleLoading={handleLoading}
        type={type}
        cancel={handleOnCloseReplyMessageSection}
        handleModifyOptionalParams={handleModifyOptionalParamsOnUploadAttachment}
      >
        <div
          id={isChat ? elementId.chatMessageAttachmentButton
            : elementId.groupChatMessageAttachmentButton}
          className={styles.attachment}
        >
          <AttachFileIcon />
        </div>
      </AttachFileContainer>
      )}
      {(!showAudio && !isRecording) && (
      <div
        className={styles.text}
        style={{
          ...editorTextStyles[type],
          ...mobileEditorTextStyles,
        }}
      >
        {chatWith
           && (
           <EditorMin
             model={editorModel}
             onModelChange={handleEditorModelChange}
             triggerLists={chatWith}
             type={type}
             handleAction={handleTriggerPostMessage}
             isAction={isAction}
             triggerFocusOnly={triggerFocusEditor}
             companyId={companyId}
             handleTyping={handleTyping}
           />
           )}
      </div>
      )}
      {showAudio && audioSrc && (
        <div className={styles.audioTumbnail}>
          <AudioPlayerOnCreateMessage audioSrc={audioSrc} onDeleteAudio={handleClearAudio} />
        </div>
      )}
      {isEditorModelEmpty && !showAudio && (
        <div
          className={styles.audioRecorder}
          onClick={isRecording ? () => null : handleSetIsRecording}
        >
          <AudioRecorder
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            onRecordingComplete={onRecordingComplete}
            showVisualizer
          />
        </div>
      )}
      {(!isEditorModelEmpty || (showAudio && isEditorModelEmpty)) && (
      <OverlayButton
        wait={`create-${type}-message`}
        loadings={loadings}
      >
        <div
          id={isChat ? elementId.chatMessageSendButton : elementId.groupChatMessageSendButton}
          className={styles.send}
          onClick={isVoiceNoteCreated
            ? () => handleSendVoiceNoteApi(audioBlob) : handleTriggerPostMessage}
        >
          <SendIcon />
        </div>
      </OverlayButton>
      )}
    </div>
  );
}

CreateMessage.propTypes = {
  handleChangeHeightDivMessagesSection: PropTypes.func.isRequired,
  handleOnCloseReplyMessageSection: PropTypes.func.isRequired,
  handleLoading: PropTypes.func.isRequired,
  chatWith: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  socket: PropTypes.object.isRequired,
  handleUploadFiles: PropTypes.func.isRequired,
  triggerFocusEditor: PropTypes.number,
  handleModifyOptionalParamsOnUploadAttachment: PropTypes.func,
  repliedMessage: PropTypes.object,
  repliedGroupMessage: PropTypes.object,
  handlePostAttachmentVoiceNote: PropTypes.func,
};

CreateMessage.defaultProps = {
  triggerFocusEditor: undefined,
  handleModifyOptionalParamsOnUploadAttachment: undefined,
  repliedMessage: {},
  repliedGroupMessage: {},
  handlePostAttachmentVoiceNote: () => null,
};

export default memo(CreateMessage);
