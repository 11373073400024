import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Highlighter from 'react-highlight-words';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../../themes/colors';
import LinkNoDecor from '../../../../../../components/LinkNoDecor/LinkNoDecor';
import { limitChar } from '../../../../../../utilities/stringUtil';
import { getUrlItem } from '../../../../../../actions/SearchJumpToActions';
import { PageConstants } from '../../../../../../constants';
import styles from './ResultItemDiscussion.module.css';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import CommentItem from '../../../../../CommentsSectionContainer/ShowComments/CommentItem/CommentItem';

const ResultItemDiscussion = ({
  item, isSelected, onClick, keywordArray, limitTitleChar, params,
}) => {
  const { companyId } = params;

  let routes = [];
  let newItem;
  if (item.card) {
    routes = [
      {
        name: item.team.name,
      },
      {
        name: 'Tugas',
      },
      {
        name: item.card.name,
      },
      {
        name: 'Diskusi',
      }
    ];

    newItem = {
      _id: item.discussion?._id,
      name: item.discussion.content,
      type: 'cardDiscussions',
      teamId: item.team?._id,
      cardId: item.card?._id,
      commentId: item.comment?._id,
      companyId,
      routes,
    };
  }

  if (item.event) {
    routes = [
      {
        name: item.team.name,
      },
      {
        name: 'Jadwal',
      },
      {
        name: item.event.title,
      },
      {
        name: 'Diskusi',
      }
    ];

    newItem = {
      _id: item.discussion?._id,
      name: item.discussion.content,
      type: 'eventDiscussions',
      teamId: item.team?._id,
      eventId: item.event?._id,
      commentId: item.comment?._id,
      companyId,
      routes,
    };

    if (item.event.isOccurrence) {
      newItem.eventId = item.event.event;
      newItem.type = 'occurrenceDiscussions';
      newItem.occurrenceId = item.event?._id;
    }
  }

  if (item.doc) {
    routes = [
      {
        name: item.team.name,
      },
      {
        name: 'Dokumen',
      },
      {
        name: item.doc.title,
      },
      {
        name: 'Diskusi',
      }
    ];

    newItem = {
      _id: item.discussion?._id,
      name: item.discussion.content,
      type: 'docDiscussions',
      teamId: item.team?._id,
      docId: item.doc?._id,
      commentId: item.comment?._id,
      companyId,
      routes,
    };
  }

  if (item.file) {
    routes = [
      {
        name: item.team.name,
      },
      {
        name: 'File',
      },
      {
        name: item.file.title,
      },
      {
        name: 'Diskusi',
      }
    ];

    newItem = {
      _id: item.discussion?._id,
      name: item.discussion.content,
      type: 'fileDiscussions',
      teamId: item.team?._id,
      fileId: item.file?._id,
      commentId: item.comment?._id,
      companyId,
      routes,
    };
  }

  if (item.post) {
    routes = [
      {
        name: item.team.name,
      },
      {
        name: 'Pengumuman',
      },
      {
        name: item.post.title,
      },
      {
        name: 'Diskusi',
      }
    ];

    newItem = {
      _id: item.discussion?._id,
      name: item.discussion.content,
      type: 'postDiscussions',
      teamId: item.team?._id,
      postId: item.post?._id,
      commentId: item.comment?._id,
      companyId,
      routes,
    };
  }

  if (item.question) {
    routes = [
      {
        name: item.team.name,
      },
      {
        name: 'Pertanyaan',
      },
      {
        name: item.question.title,
      },
      {
        name: 'Diskusi',
      }
    ];

    newItem = {
      _id: item.discussion?._id,
      name: item.discussion.content,
      type: 'questionDiscussions',
      teamId: item.team?._id,
      questionId: item.question?._id,
      commentId: item.comment?._id,
      companyId,
      routes,
    };
  }

  const url = getUrlItem({ companyId, item: newItem });
  newItem.url = url;

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  return (
    <LinkNoDecor to={url}>
      <div
        onClick={onClick}
        aria-pressed={isSelected}
        className={styles.container}
      >
        <div
          className={styles.content}
        >
          <CommentItem
            disabledOutsideClickHandlerMenu
            hideReply
            hideCheers
            hideCommentOption
            comment={item.discussion}
            limitContent={isMobile ? 80 : limitTitleChar}
            withHighlighter
            keywordArray={keywordArray}
          />
        </div>
        <div
          className={styles.breadcrumb}
        >
          <Breadcrumb routes={newItem?.routes} />
        </div>
      </div>
    </LinkNoDecor>
  );
};

ResultItemDiscussion.propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  keywordArray: PropTypes.array,
  limitTitleChar: PropTypes.number,
  params: PropTypes.object,
};

ResultItemDiscussion.defaultProps = {
  keywordArray: [],
  limitTitleChar: 170,
  params: { companyId: null, teamId: null },
};

export default memo(ResultItemDiscussion);
